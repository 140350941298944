import React, { useMemo } from "react"
import { useLocation, Link } from "react-router-dom"
import { useSelector } from "react-redux"
import {
  DashboardOutlined,
  DashboardFilled,
  DatabaseOutlined,
  DatabaseFilled,
  TeamOutlined,
  InsertRowAboveOutlined,
} from "@ant-design/icons"
import FlavorLogo from '@/assets/FlavorWiki_Full_Logo.png'
import { SideBarContainer, SideBarItem, Wrapper } from "./styles"

const SIDEBAR_ITEMS = {
  dashboard: {
    label: "Dashboard",
    icon: <DashboardOutlined />,
    filledIcon: <DashboardFilled />,
    url: "/dashboard",
  },
  job: {
    label: "Job Posts",
    icon: <DatabaseOutlined />,
    filledIcon: <DatabaseFilled />,
    url: "/jobs",
  },
  manager: {
    label: "Managers List",
    icon: <TeamOutlined />,
    filledIcon: <TeamOutlined />,
    url: "/managers",
  },
  hiringPipeline: {
    label: "Applicants Database",
    icon: <InsertRowAboveOutlined />,
    filledIcon: <InsertRowAboveOutlined />,
    url: "/applicant-database",
  },
}

const SideBar = () => {
  const location = useLocation()
  const { role } = useSelector((state) => state.authenUserReducer)
  const isAdmin = role === "admin"

  const items = useMemo(() => {
    if (!isAdmin) {
      return Object.values(SIDEBAR_ITEMS).filter((item) => {
        return item.url !== "/managers" && item.url !== "/jobs"
      })
    }

    return Object.values(SIDEBAR_ITEMS)
  }, [isAdmin])


  return (
    <Wrapper>
      <SideBarContainer>
        <img className="logo" src={FlavorLogo} alt="FlavorWiki" />

        {items.map((item, index) => {
          const isActive = location.pathname === item.url

          return (
            <Link to={item.url} key={index}>
              <SideBarItem isActive={isActive} href={item.url} key={index}>
                {isActive ? item.filledIcon : item.icon}

                <span className="text">{item.label}</span>
              </SideBarItem>
            </Link>
            )
        })}
      </SideBarContainer>
    </Wrapper>
  )
}

export default SideBar
