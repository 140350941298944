import styled from 'styled-components'

export const Section = styled.section`
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  padding: 0 1rem;
`

export const Cell = styled.div``

export const CellTitle = styled.h6`
  color: #272d4e;
  font-size: 0.875rem;
  margin-bottom: 0;
`


export const CellText = styled.div`
  color: #272d4e;
  font-size: 0.875rem;

  span {
    display: inline-block;
    font-size: 0.375rem;
    vertical-align: middle;
    margin: auto 0.25rem;
  }
`

export const BreakLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #8c8c8c33;
  margin: 0.25rem auto;
`

export const DivTotal = styled.div`
  color: #183b57;
  font-weight: 700;
  font-size: 1.25rem;
  padding: 1rem 1.25rem;

  .ant-tag {
    font-size: 1rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
  }
`

export const DivFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.25rem;

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: end;
    gap: 1.25rem;
  }
`

export const DivStatusLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ant-btn {
    min-width: 6rem;
    color: #5695a6;
    border-color: #5695a6;

    &: hover {
      color: white;
      background: #5695a6;
      opacity: 0.75;
    }
  }
`
export const DivStatus = styled.div`
  text-align: center;

  div {
    color: #f0f1fa;
    background-color: ${(props) => props.color};
    font-size: 0.625rem;
    font-weight: 500;
    text-align: center;
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
  }

  span {
    color: #5d7589;
    font-size: 0.75rem;
    line-height: 0.75rem;
    margin-top: 0.5rem;
  }
`

export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 520px) {
    flex-direction: column;
  }
`

export const Actions = styled.div`
  button {
    margin-right: 0.5rem;
  }
`

