import { useEffect } from 'react'
import moment from 'moment'
import { Button, Col, Row } from 'antd'
import { Form, TextArea, Input, Select } from '@/components/UIKits'
import { EventFormWrapper, DatePicker } from './styles'
import { EVENT_STATUSES } from '@/utils/constants'

const statusOptions = Object.values(EVENT_STATUSES).map((option) => (
  <Select.Option key={option.value} value={option.value}>
    {option.label}
  </Select.Option>
))

const EventForm = ({ data, isEdit, onSubmit }) => {
  const [form] = Form.useForm()

  const formTitle = isEdit && data ? `Edit event ${data.name}` : 'Create Event'

  const handleSubmit = () => {
    form.validateFields().then(() => {
      const values = form.getFieldsValue()
      const { startDate, endDate, registrationUrl, detailUrl } = values

      const payload = {
        ...values,
        startDate: moment(startDate).valueOf(),
        endDate: moment(endDate).valueOf(),
        registration_url: registrationUrl,
        detail_url: detailUrl
      }

      onSubmit(payload)
    })
  }

  useEffect(() => {
    if (data && isEdit) {
      form.setFieldsValue({
        name: data.name,
        date: data.date,
        startDate: moment(data.startDate),
        endDate: moment(data.endDate),
        location: data.location,
        imageSrc: data.imageSrc,
        registrationUrl: data.registration_url,
        detailUrl: data.detail_url,
        description: data.description,
        status: data.status
      })
    }

    if (!isEdit) {
      form.setFieldsValue({
        name: null,
        date: null,
        startDate: null,
        endDate: null,
        location: null,
        imageSrc: null,
        registrationUrl: null,
        detailUrl: null,
        description: null,
        status: null
      })
    }
  }, [data, isEdit, form])

  return (
    <EventFormWrapper>
      <h2>{formTitle}</h2>

      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col sm={24} md={12}>
            <Form.Item
              name="name"
              label='Event Name'
              rules={[
                {
                  required: true,
                  message: 'This is a required field'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item
              name="date"
              label='Event Date'
              rules={[
                {
                  required: true,
                  message: 'This is a required field'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item
              name="startDate"
              label='Start Date'
              rules={[
                {
                  required: true,
                  message: 'This is a required field'
                }
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item
              name="endDate"
              label='End Date'
              rules={[
                {
                  required: true,
                  message: 'This is a required field'
                }
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item
              name="location"
              label='Event Location'
              rules={[
                {
                  required: true,
                  message: 'This is a required field'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item
              name="imageSrc"
              label='Image path'
              rules={[
                {
                  required: true,
                  message: 'This is a required field'
                }
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item
              name="registrationUrl"
              label='Registration Url'
              rules={[
                {
                  required: true,
                  message: 'This is a required field'
                }
              ]}
            >
              <Input type="url" />
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item
              name="detailUrl"
              label='Detail Url'
              rules={[
                {
                  required: true,
                  message: 'This is a required field'
                }
              ]}
            >
              <Input type="url" />
            </Form.Item>
          </Col>

          <Col sm={24} md={12}>
            <Form.Item
              name="status"
              label='Status'
              rules={[
                {
                  required: true,
                  message: 'This is a required field'
                }
              ]}
            >
              <Select>{statusOptions}</Select>
            </Form.Item>
          </Col>

          <Col sm={24}>
            <Form.Item
              name="description"
              label='Description'
              rules={[
                {
                  required: true,
                  message: 'This is a required field'
                }
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Button type="primary" onClick={handleSubmit}>
        Submit
      </Button>
    </EventFormWrapper>
  )
}

export default EventForm
