import styled from 'styled-components'

export const Section = styled.section`
  background-color: #ffffff;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  padding: 1.5rem;

  > img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }
`

export const Header = styled.div`
  margin-bottom: 2rem;
`

export const Title = styled.h3`
  color: #272d4e;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
`

export const ProfileInfo = styled.div`
  > strong {
    display: block;

    color: #272d4e;
    font-weight: 500;

    margin-top: 1rem;
  }
  > span {
    color: #252525;
    opacity: 0.7;
  }
`
