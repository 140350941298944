import styled from 'styled-components'

export const CardWrapper = styled.div`
  padding: 1rem 1.5rem;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  position: relative;
`

export const DivCopyLinkBG = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: #bfbfbf;
  opacity: 0.5;
  border-radius: 0.5rem;
`

export const DivCopyLinkNoti = styled.div`
  height: 15%;
  width: 60%;
  position: absolute;
  top: 40%;
  left: 20%;
  z-index: 2;
  background: #252525;
  color: white;
  font-weight: 700;
  font-size: 0.875rem;

  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  border-radius: 1rem;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;

  .department {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #bfbfbf;
    margin-right: 0.5rem;
  }

  .ant-tag {
    border-radius: 8px;
    border: none;
    padding: 0.125rem 0.5rem;
  }

  .anticon {
    cursor: pointer;
    color: #bac5dc;
    font-size: 1.875rem;
  }
`

export const StatusText = styled.span`
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  color: ${(props) => props.color};
`

export const Title = styled.h3`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #272d4e;
  margin-bottom: 0.25rem;
`

export const SubTitle = styled.span`
  .country-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }

  .country-name {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #878981;
  }
`

export const TotalApplication = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  background: #f0f1fa;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin: 1rem 0;

  .total-number {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #5a5a58;
  }

  .text-content {
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: capitalize;
    color: #a3a3a3;
  }
`

export const ExtraInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .icon {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
  }

  .text-content {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #a3a3a3;
    margin-left: 0.5rem;
  }
`

export const CardFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(39, 45, 78, 0.08);
  padding-top: 1rem;

  .anticon {
    svg {
      width: 1rem;
      height: 1rem;
      color: #878981;
    }
  }

  .published-date-section {
    .text-content {
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 150%;
      color: #878981;
      margin-left: 0.75rem;
    }
  }

  .see-details-section {
    cursor: pointer;

    .text-content {
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 150%;
      color: #878981;
      margin-right: 0.75rem;
    }
  }
`
