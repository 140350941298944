import styled from 'styled-components'

export const Wrapper = styled.div`
  height: fit-content;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  padding: 2rem;

  .ant-btn {
    margin-bottom: 1rem;
    font-weight: 600;
    color: #183b57;
  }

  h3 {
    color: #272d4e;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  h4 {
    color: #272d4e;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    margin: 1rem 0;
  }

  .ant-checkbox-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;

    .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
`
