import { GET } from '@/services/axios'

const BASE_URL = `${process.env.REACT_APP_BACKEND_API_URL}/api/v2`

export const find = async (params) => {
  const url = `${BASE_URL}/applications/group`
  const { data } = await GET(url, params)
  return data
}

export const findOne = async (params) => {
  const url = `${BASE_URL}/applications/count`
  const { data } = await GET(url, params)
  return data
}
