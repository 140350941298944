import React, { useState, createRef } from 'react'
import { Modal } from 'antd'
import { ExpandAltOutlined } from '@ant-design/icons'
import { Button } from '../styles'

const LinkVideo = ({ link }) => {
  const ref = createRef()
  const [visible, setVisible] = useState(false)

  const onShow = () => {
    setVisible(true)
  }

  const onCancel = () => {
    setVisible(false)

    if(ref && ref.current) {
      ref.current.pause()
    }
  }

  return (
    <React.Fragment>
      <Modal visible={visible} width={400} footer={null} onCancel={onCancel}>
        <video ref={ref} width="320" height="240" src={link} controls />
      </Modal>

      <Button size="small" icon={<ExpandAltOutlined />} onClick={onShow}>
        Presentation Video
      </Button>
    </React.Fragment>
  )
}

export default LinkVideo
