import styled from 'styled-components'

export const NewJobsContainer = styled.section`
  position: relative;
`

export const JobCardsRow = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  overflow-x: auto;
  padding: 2rem 1rem 1.5rem 1rem;
`

export const JobCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  width: 100%;
  background-image: url('${(props) => props.bgUrl}');
  background-size: cover;
  border-radius: 0.5rem;
  padding: 1rem;

  .job-title {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 150%;
    color: #ffffff;
    margin: 0;
  }

  .job-info {
    display: flex;
    align-items: center;

    .job-info-item {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .icon {
        width: 1rem;
        height: 1rem;
      }

      .text-content {
        white-space: nowrap;
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 150%;
        color: #ffffff;
      }

      &:not(:last-child) {
        border-right: 1.5px solid #ffffff;
        padding-right: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }
`

export const NewJobLabel = styled.header`
  position: absolute;
  top: -1rem;
  left: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #04c239;
  border-radius: 4px;
  font-weight: 700;
  font-size: 0.75rem;
  color: #ffffff;
  padding: 0.375rem 0.75rem;
`
