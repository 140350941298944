import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  strong {
    color: #272d4e;
    font-size: 0.875rem;
  }

  .ant-modal-content,
  .ant-modal-header {
    border-radius: 1rem;
  }
`
