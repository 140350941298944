import InputSearch from '@/components/Filters/InputSearch'
import NameSort from '@/components/Filters/Selects/NameSort'

import { Container, AlphabeticalSort } from './styles'

const FilterBar = () => {
  return (
    <Container>
      <InputSearch paramKey="key" placeholder="Search by name" />

      <div>
        <AlphabeticalSort>
          <span className="title-text">Sort:</span>

          <NameSort paramKey="sortDir" />
        </AlphabeticalSort>
      </div>
    </Container>
  )
}

export default FilterBar
