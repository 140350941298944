import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'
import useTableLogic from '../../../hooks/HiringPipelines/Table/index'
import Metadata from '@/components/Metadata'
import { Header } from './styles'

const HeaderHPDynamic = () => {
  const { total } = useTableLogic()

  const displayTotal = total >= 0 ? total : 'loading...'

  return (
    <Header>
      <Metadata
        title="Manage Hiring Pipelines"
        breadcrumbItems={[
          { link: '/dashboard', title: 'DASHBOARD' },
          { link: '#', title: 'MANAGE PIPELINES' }
        ]}
        infoItems={[`Total Hiring Pipelines: ${displayTotal} `]}
      />

      <Link to={`/manage-pipelines/create`}>
        <Button type="primary" icon={<PlusCircleOutlined />}>
          Create new Pipeline
        </Button>
      </Link>
    </Header>
  )
}

export default HeaderHPDynamic
