import styled from 'styled-components'

export const FormWrapper = styled.div`
  width: 24.2rem;
  margin: 5rem auto;
  background-color: #fff;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
`

export const Title = styled.h2`
  color: #002766;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
`

export const FormFooter = styled.div`
  font-size: 0.875rem;
  color: gray;
  text-align: center;
  margin-top: 4rem;

  a {
    font-weight: bold;
  }
`
