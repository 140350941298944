import React, { useMemo } from 'react'
import { Container } from './styles'

const Component = ({ url, type }) => {
  //check if file is mp4 video
  const isVideo = useMemo(() => {
    if (!url) return false
    if (typeof url != 'string') return false
    if (type !== 'video') return false

    return true
  }, [url, type])

  return (
    <Container isVideo={isVideo}>
      {isVideo ? (
        <video src={url} width="500" height="350" controls />
      ) : (
        <iframe src={url} title="view file" />
      )}
    </Container>
  )
}

export default Component
