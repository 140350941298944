import styled from 'styled-components'
import { Card as AntCard, Input as AntInput } from 'antd'

export const Section = styled.section`
  height: 32rem;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  padding: 1rem;
  max-width: calc(100vw - 19rem);
  color: #183b57;
`

export const InputTitle = styled(AntInput)`
  font-weight: 700;
`

export const CardSection = styled.div`
  max-width: 100%;
  width: fit-content;

  display: grid;
  gap: 1.25rem;
  grid-auto-flow: column;
  overflow: auto;

  padding: 0.25rem;
`

export const Card = styled(AntCard)`
  width: 15rem;
  background: rgba(246, 248, 250, 0.6);

  strong {
    margin-left: -0.25rem;
  }
  input {
    margin: 0.25rem 0 0.75rem 0;
    border-radius: 0.5rem;
  }

  &:hover {
    z-index: 1;
    background: #ffffff;
  }
`

export const CardAdd = styled(AntCard)`
  width: 15rem;
  text-align: center;
  padding: 3rem 0;
  h4 {
    font-weight: 700;
  }
  outline: 0.125rem #f0f0f0 dashed;

  &:hover {
    outline: 0;
    z-index: 1;
  }
`

export const CardLoading = styled(AntCard)`
  width: 15rem;
  text-align: center;
  padding: 3rem 0;
  h4 {
    font-weight: 700;
  }
`

export const SectionTitle = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  input {
    width: 10rem;
  }
`

export const SectionCardHeader = styled.header`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`
