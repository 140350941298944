import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button } from 'antd'
import Select from '@/components/UIKits/Select'
import {
  ReadOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined
} from '@ant-design/icons'

const { Option } = Select
/**
 * @param {String} paramKey name of the query field for URL SearchParams
 * @returns sort button by alphabet order
 */
const Element = ({ paramKey = 'sortName' }) => {
  const [params, setParams] = useSearchParams()

  const handlOnChange = (value) => {
    params.set(paramKey, value)

    setParams(params)
  }

  return (
    <Select
      onChange={handlOnChange}
      placeholder={
        <Button icon={<ReadOutlined />} type="text">
          Alphabetical
        </Button>
      }
    >
      <Option key="ascending" value={1}>
        <Button icon={<SortAscendingOutlined />} type="text">
          Alphabetical (A-Z)
        </Button>
      </Option>
      <Option key="descending" value={-1}>
        <Button icon={<SortDescendingOutlined />} type="text">
          Alphabetical (Z-A)
        </Button>
      </Option>
    </Select>
  )
}

export default Element
