import { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Radio } from 'antd'
import { Form, Input, Select } from '@/components/UIKits'
import {
  SelectManager,
  SelectDepartment,
  SelectCountry
} from '@/components/Selects'
import FormItemDateRange from '@/components/FormItems/DateRange'
import SelectJobStatus from '@/components/Selects/JobStatus'
import {
  JOB_TYPES,
  FILES_NEEDED,
  DEFAULT_FILES_NEEDED,
  EXPERIENCE_YEARS,
  CURRENCIES,
  SALARY_OPTIONS
} from '@/utils/constants'
import { DetailsContainer, CheckboxGroup, SalaryContainer } from './styles'

const JobDetails = ({ form, data, isEdit }) => {
  useEffect(() => {
    if (data && isEdit) {
      form.setFieldsValue({
        status: data.status,
        startDate: data.startDate ? moment(data.startDate) : null,
        endDate: data.endDate ? moment(data.endDate) : null,
        category: data.category || null,
        pic: data.pic?.map((person) => person._id) || [],
        type: data.type || null,
        ableToWorkRemotely: data.ableToWorkRemotely || null,
        fileNeeded: data.fileNeeded || [],
        yearsOfExperience: data.yearsOfExperience || null,
        country: data.country || null,
        code: data.code || null,
        currency: data.currency || null,
        monthlySalary: data.monthlySalary || null
      })
    }

    if (!isEdit) {
      form.setFieldsValue({
        status: 'active',
        startDate: null,
        endDate: null,
        category: null,
        pic: [],
        type: null,
        ableToWorkRemotely: null,
        fileNeeded: DEFAULT_FILES_NEEDED,
        yearsOfExperience: null,
        country: null,
        code: null,
        currency: null,
        monthlySalary: null
      })
    }
  }, [form, data, isEdit])

  return (
    <DetailsContainer>
      <h2>Job Details</h2>

      <Form form={form} layout="vertical" requiredMark={false}>
        <Form.Item name="status" label="Status">
          <SelectJobStatus />
        </Form.Item>

        <FormItemDateRange label="Recruitment Period" />

        <Form.Item
          name="category"
          label="Select Job Category"
          rules={[
            {
              required: true,
              message: 'This is a required field'
            }
          ]}
        >
          <SelectDepartment />
        </Form.Item>

        <Form.Item name="pic" label="Select PIC">
          <SelectManager mode="multiple" allowClear />
        </Form.Item>

        <Form.Item
          name="type"
          label="Job type"
          rules={[
            {
              required: true,
              message: 'This is a required field'
            }
          ]}
        >
          <Select allowClear>
            {Object.values(JOB_TYPES).map((type) => (
              <Select.Option key={type.value} value={type.value}>
                {type.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="ableToWorkRemotely" label="Work Remotely">
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="fileNeeded" label="File Needed">
          <CheckboxGroup options={FILES_NEEDED} />
        </Form.Item>

        <Form.Item name="yearsOfExperience" label="Experiences">
          <Select allowClear>
            {Object.values(EXPERIENCE_YEARS).map((year) => (
              <Select.Option key={year.value} value={year.value}>
                {year.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="country"
          label="Country"
          rules={[
            {
              required: true,
              message: 'This is a required field'
            }
          ]}
        >
          <SelectCountry />
        </Form.Item>

        <Form.Item
          name="code"
          label="Job Code"
          rules={[
            {
              required: true,
              message: 'This is a required field'
            }
          ]}
        >
          <Input />
        </Form.Item>

        <SalaryContainer>
          <Form.Item name="currency" label="Currency of Salary">
            <Select>
              {Object.values(CURRENCIES).map((currency) => (
                <Select.Option key={currency.value} value={currency.value}>
                  {currency.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="monthlySalary" label="Monthly Salary">
            <Select>
              {SALARY_OPTIONS.map((salary) => (
                <Select.Option key={salary.value} value={salary.value}>
                  {salary.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </SalaryContainer>
      </Form>
    </DetailsContainer>
  )
}

JobDetails.propTypes = {
  form: PropTypes.any.isRequired,
  data: PropTypes.object,
  isEdit: PropTypes.bool
}

JobDetails.defaultProps = {
  data: {},
  isEdit: false
}

export default JobDetails
