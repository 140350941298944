import { useEffect } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import usersnap from '@/services/usersnap'

/**
 * This check if user is loggedIn or not and restrict access to specific routes
 * @returns navigate to signin page if user is NOT logged in
 */
const PrivateRoutes = () => {
  const { usertoken } = useSelector((state) => state.authenUserReducer)

  useEffect(() => {
    const token = Cookies.get('userToken')

    if (token) {
      const decodedUser = jwt_decode(token)

      if (decodedUser) {
        usersnap.integrate(decodedUser)
      }
    }
  }, [])

  return usertoken ? <Outlet /> : <Navigate to="/" />
}

export default PrivateRoutes
