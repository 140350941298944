import React, { useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import { useSearchParams } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import Input from '../../../components/UIKits/Input'

/**
 * Input component acts as search bar, it will update the search param query when user type in keywords
 * @param {String} paramKey key (name) for updating url searchParam {paramKey: value} when user types in keywords
 * @param {String} placeholder placeholder displayed on the input
 * @returns Input component for searching keywords
 */
const InputSearch = ({ paramKey, placeholder }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (searchParams.has(paramKey)) {
      setSearchValue(searchParams.get(paramKey))
    } else {
      setSearchValue('')
    }
  }, [searchValue, searchParams, setSearchParams, paramKey])

  const handleSearch = debounce(() => {
    setSearchParams(searchParams)
  }, 800)

  const handleOnchange = (e) => {
    const value = e.target.value

    setSearchValue(value)

    searchParams.set(paramKey, value)
    //detect clear event or empty input
    if (!value || e.type === 'click') {
      searchParams.delete(paramKey)
    }

    searchParams.delete('pageIndex')
    searchParams.delete('pageSize')

    handleSearch()
  }

  return (
    <Input
      allowClear={true}
      value={searchValue}
      prefix={<SearchOutlined />}
      placeholder={placeholder}
      onChange={handleOnchange}
    />
  )
}

export default InputSearch
