import styled from 'styled-components'

export const FilterSectionContainer = styled.main`
  height: fit-content;
  border: 1px solid rgba(186, 197, 220, 0.2);
  border-radius: 0.5rem;
  background: #ffffff;
  padding: 2rem;
`

export const FilterTilte = styled.span`
  h2 {
    display: inline-block;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #272d4e;
    text-transform: uppercase;
    margin-left: 0.5rem;
    margin-bottom: 0;
  }
`

export const StatusFilter = styled.section`
  margin: 2rem 0;

  h3 {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #272d4e;
    margin-bottom: 1rem;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
  }
`

export const DepartmentFilter = styled.section`
  h3 {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #272d4e;
    margin-bottom: 1rem;
  }

  .ant-checkbox-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.5rem;

    .ant-checkbox-wrapper {
      margin-left: 0;
    }
  }
`
