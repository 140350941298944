import { MinusOutlined } from '@ant-design/icons'
import { Form } from '@/components/UIKits'
import { Content, DatePicker } from './styles'

const FormItem = ({ label = '' }) => {
  return (
    <Content>
      <Form.Item name="startDate" label={label}>
        <DatePicker />
      </Form.Item>
      <MinusOutlined />
      <Form.Item name="endDate" label="">
        <DatePicker />
      </Form.Item>
    </Content>
  )
}

export default FormItem
