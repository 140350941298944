import React from 'react'
import Layout from '@/layouts/Default'
// import Pipeline from '@/containers/ApplicantsDatabase/SectionPipeline'
import Filters from '@/components/ApplicantsDatabase/Filters'
import Table from '@/containers/ApplicantsDatabase/Table'
import Header from '@/containers/ApplicantsDatabase/Header'

const HiringPipeline = () => {
  return (
    <Layout>
      <Header />

      <Filters />

      {/* <Pipeline /> */}

      <Table />
    </Layout>
  )
}

export default HiringPipeline
