import styled from 'styled-components'

export const ContentContainer = styled.section`
  background: #ffffff;
  border-radius: 8px;
  padding: 1.25rem;

  .tox-tinymce {
    .tox-editor-container {
      .tox-sidebar-wrap {
        .tox-edit-area {
          iframe {
            background: #f6f8fd;
          }
        }
      }
    }
  }
`
