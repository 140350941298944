import styled from 'styled-components'
import { Form as AntForm } from 'antd'

export const Form = styled(AntForm)`

  .ant-form-item-label > label {
    color: #183b57;
    font-weight: 700;    
  }
`
