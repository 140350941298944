import React from 'react'
import moment from 'moment/moment'
import { exportApplications } from '@/apis/applications'
import SearchBar from '@/components/Filters/InputSearch'
import SelectJobTitle from '@/components/Filters/Selects/JobTitles'
import SelectCountry from '@/components/Filters/Selects/Country'
import SelectStatus from '@/containers/ApplicantsDatabase/FilterSelectStatus'
import ButtonDownloadBase64 from '@/components/DownloadBase64Button'
import { Wrapper, DivFilter } from './styles'

const Element = () => {
  return (
    <Wrapper>
      <DivFilter>
        <SearchBar paramKey="key" placeholder="Search for applicant" />
        <SelectJobTitle />
        <SelectCountry />
        <SelectStatus />
      </DivFilter>

      <ButtonDownloadBase64
        type="primary"
        ghost
        fileName={`Applications export ${moment().toISOString()}.xlsx`}
        fetchAPI={exportApplications}
      >
        Export
      </ButtonDownloadBase64>
    </Wrapper>
  )
}

export default Element
