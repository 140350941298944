import React, { useState } from 'react'
import { Modal } from 'antd'
import { ExpandAltOutlined } from '@ant-design/icons'
import { Button } from '../styles'

const LinkFile = ({ name, link }) => {
  const [visible, setVisible] = useState(false)

  const onShow = () => {
    setVisible(true)
  }

  const onCancel = () => {
    setVisible(false)
  }

  return (
    <React.Fragment>
      <Modal visible={visible} width={1000} footer={null} onCancel={onCancel}>
        <iframe src={link} title={name} width={925} height={500} />
      </Modal>

      <Button size="small" icon={<ExpandAltOutlined />} onClick={onShow}>
        {name}
      </Button>
    </React.Fragment>
  )
}

export default LinkFile
