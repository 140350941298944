import { useState } from "react"
import { Link, createSearchParams } from "react-router-dom"
import PropTypes from "prop-types"
import { Button } from "antd"
import { LeftOutlined } from "@ant-design/icons"

import { COUNTRIES, JOB_STATUS_COLOR, JOB_STATUSES } from "@/utils/constants"
import Country from "@/components/Texts/Country"
import TextRemoteWork from "@/components/Texts/RemoteWork"
import ExtraInformation from "@/components/Metadata/ExtraInformation"
import ModalConfirm from "@/components/ModalConfirm"

import { HeaderContainer, Title, EditHeader } from "./styles"

const Header = ({
  form,
  onSubmit,
  data,
  isEdit,
  totalApplicants,
  isLoading,
  isSubmitting,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const currentStatus = data?.status || JOB_STATUSES.active.value

  const allowView = isEdit && currentStatus !== JOB_STATUSES.inactive.value

  const handleSubmit = async () => {
    form
      .validateFields()
      .then(() => {
        const values = form.getFieldsValue()
        const { country, startDate, endDate } = values
        const regionId = COUNTRIES.find(
          (name) => name.value === country
        ).regionId

        onSubmit({
          ...values,
          regionId,
          ...(startDate && {
            startDate: new Date(startDate).getTime(),
          }),
          ...(endDate && {
            endDate: new Date(endDate).getTime(),
          })
        })
      })
      .catch(() => {})
  }

  const handleSaveChanges = async () => {
    form.validateFields().then(() => setIsOpen(true))
  }

  const renderEditHeader = (job) => {
    const { title, ableToWorkRemotely, status, country } = job

    const jobStatus = status || JOB_STATUSES.active.value

    const params = createSearchParams({
      jobTitle: title,
    }).toString()

    const url = `/applicant-database?${params}`

    return (
      <EditHeader
        bgColor={JOB_STATUS_COLOR[jobStatus].background}
        txtColor={JOB_STATUS_COLOR[jobStatus].text}
      >
        <div className="status-row">
          <span className="title">job post</span>
          <span className="status">{jobStatus}</span>
        </div>

        <Title>{title}</Title>

        <ExtraInformation
          items={[
            <Country name={country} showCode />,
            <TextRemoteWork ableToWorkRemotely={ableToWorkRemotely} />,
            `Total Applicants: ${totalApplicants || 0}`,
            <Link to={url}>
              <Button type="primary" size="small" shape="round">
                View Hiring Pipeline
              </Button>
            </Link>,
          ]}
        />
      </EditHeader>
    )
  }

  return (
    <>
      <HeaderContainer isEdit={isEdit}>
        <div className="left-section">
          <Link to="/jobs">
            <Button shape="circle" icon={<LeftOutlined />} />
          </Link>

          {isEdit && data ? renderEditHeader(data) : <h1>Create Job Post</h1>}
        </div>

        <div className="right-section">
          {allowView && (
            <Button
              href={`${process.env.REACT_APP_LANDING_URL}/career/${data?._id}`}
              target="_blank"
            >
              {data?.status === JOB_STATUSES.draft.value ? "Preview" : "View"}
            </Button>
          )}

          <Button
            type="primary"
            disabled={isLoading}
            loading={isSubmitting}
            onClick={handleSaveChanges}
          >
            {isEdit ? "Save Changes" : "Create"}
          </Button>
        </div>

        <ModalConfirm
          openModal={isOpen}
          loading={isSubmitting}
          title={isEdit ? "Save Changes" : "Create Job"}
          handleCancel={() => setIsOpen(false)}
          handleOk={handleSubmit}
          message={
            isEdit
              ? "Are you sure you want to update this job?"
              : "Are you sure you want to create this draft job?"
          }
        />
      </HeaderContainer>
    </>
  )
}

Header.propTypes = {
  form: PropTypes.any.isRequired,
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  isEdit: PropTypes.bool,
  totalApplicants: PropTypes.number,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
}

Header.defaultProps = {
  onSubmit: () => {},
  data: null,
  isEdit: false,
  totalApplicants: 0,
  isLoading: false,
  isSubmitting: false,
}

export default Header
