import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import Select from '@/components/Selects/JobTitles'

const Element = ({ paramKey = 'jobTitle' }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const defaultValue = useMemo(() => {
    if (searchParams.has(paramKey)) {
      return searchParams.get(paramKey)
    }

    return null
  }, [searchParams, paramKey])

  const onChange = (value) => {
    searchParams.delete('pageIndex')
    searchParams.delete('pageSize')

    if (value) {
      searchParams.set(paramKey, value)
      setSearchParams(searchParams)
    } else {
      searchParams.delete(paramKey)
      setSearchParams(searchParams)
    }
  }

  return (
    <Select
      value={defaultValue}
      allowClear={true}
      placeholder="Job Position"
      onChange={onChange}
    />
  )
}

export default Element
