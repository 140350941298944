import React from 'react'
import { StyledHeader, HeaderImg, Content } from './styles'
import Logo from '@/assets/FlavorWiki_Full_Logo.png'

const PublicLayout = ({ children }) => {
  return (
    <>
      <StyledHeader>
        <a href="/">
          <HeaderImg src={Logo} alt="defaultLogo" />
        </a>
      </StyledHeader>
      <Content>{children}</Content>
    </>
  )
}

export default PublicLayout
