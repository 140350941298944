import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

import PageLayout from '@/layouts/Default'
import { HeaderContainer, JobCardsContainer } from '@/containers/Jobs'
import { FilterBar, FilterSection } from '@/components/Jobs'
import { actions } from '@/redux/jobs/slice'
import { JobPostsMainSection } from './styles'

const Job = () => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    return () => {
      dispatch(actions.resetState())
    }
  }, [dispatch])

  return (
    <PageLayout>
      <HeaderContainer />

      <JobPostsMainSection>
        <div>
          <FilterBar />

          <JobCardsContainer />
        </div>

        <FilterSection />
      </JobPostsMainSection>
    </PageLayout>
  )
}

export default Job
