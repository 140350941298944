import React, { useMemo } from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import {
  ClockCircleOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons"
import { Item, ItemMain, Icon } from "./styles"

const ItemUserLog = ({ data }) => {
  const link = useMemo(() => {
    const values = data.newData || data.oldData

    if (data.action === "delete") {
      return ""
    }

    if (data.tableName === "jobs") {
      return `/job/${data.rowId}`
    }

    if (data.tableName === "managers") {
      return `/managers?fullName=${values.fillName}`
    }

    return `/candidate/${data.rowId}`
  }, [data])

  const name = useMemo(() => {
    const values = data.newData || data.oldData

    if (data.tableName === "jobs") {
      return values.title
    }

    if (data.tableName === "applications") {
      return values.freelancer && values.freelancer.fullName
    }

    return values.fullName
  }, [data])

  return (
    <Link to={link}>
      <Item>
        {data.action === "created" && (
          <Icon className="green">
            <PlusOutlined />
          </Icon>
        )}

        {data.action === "updated" && (
          <Icon className="yellow">
            <EditOutlined />
          </Icon>
        )}

        {data.action === "deleted" && (
          <Icon className="red">
            <DeleteOutlined />
          </Icon>
        )}

        <ItemMain>
          <div>
            You {data.action} record <strong>{name}</strong> in table{" "}
            {data.tableName}
          </div>
          <div>
            <ClockCircleOutlined /> {moment(data.createdAt).fromNow()}
          </div>
        </ItemMain>
      </Item>
    </Link>
  )
}

export default ItemUserLog
