import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from '@/components/JobView'
import { getJob } from '@/redux/jobs/thunk'
import useJobCounter from '@/hooks/useJobCounter'

const HeaderContainer = () => {
  const dispatch = useDispatch()
  const { selectedItem, setJobCounter, onFindOne } = useJobCounter()
  const { job } = useSelector((state) => state.jobsReducer)
  const { jobId } = useParams()

  useEffect(() => {
    if (jobId) {
      dispatch(getJob(jobId))
    }
  }, [jobId, dispatch])

  useEffect(() => {
    if (job.title && job.country) {
      onFindOne({
        jobTitle: job.title,
        jobCountry: job.country
      }).then((data) => {
        setJobCounter(data.data)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job])

  return <Header data={job} totalApplicants={selectedItem} />
}

export default HeaderContainer
