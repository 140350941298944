import styled from 'styled-components'

export const Section = styled.section`
  padding: 1rem 0;

  h4 {
    font-weight: 700;
    font-size: 1.5rem;
  }
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`
export const DivDisplayStatus = styled.div`
  display: flex;
  gap: 3rem;
`
export const DivRight = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;

  svg {
    font-size: 2rem;
  }
`

export const DivStatus = styled.div`
  padding-top: 40%;
  margin-left: -1.5rem;
  z-index: ${(props) => props.zindex};
  background-image: url(${(props) => props.picture}); /* The image used */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
  cursor: pointer;
`

export const DivStages = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${(props) => props.totalStatus}, 1fr);
`

export const DivContainer = styled.div`
  max-width: calc(100vw - 20rem);
  overflow: auto;
`
