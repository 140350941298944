import styled from 'styled-components'
import { Breadcrumb as AntBreadcrumb } from 'antd'

export const Breadcrumb = styled(AntBreadcrumb)`
  
  a {
    color: #878981;
    font-weight: 700;
  }

  p {
    color: #272d4e;
    font-weight: 700;
  }
`
