import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NewJobSection } from '@/components/Dashboard'
import { getJobsList } from '@/containers/Dashboard/NewJobSection/thunk'

const JobSummaryContainer = () => {
  const dispatch = useDispatch()
  const { jobsList, isJobsListLoading } = useSelector(
    (state) => state.dashboardReducer.newJobSectionReducer
  )

  useEffect(() => {
    dispatch(getJobsList({}))
  }, [dispatch])

  return <NewJobSection jobs={jobsList} isLoading={isJobsListLoading} />
}

export default JobSummaryContainer
