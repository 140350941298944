export const groupData = (data, category) => {
  return data.reduce((prev, curr) => {
    const keys = Object.keys(prev)
    const value = curr[category] || '[missing info]'

    if (keys.includes(value))
      return {
        ...prev,
        [value]: [...prev[value], curr]
      }
    else
      return {
        ...prev,
        [value]: [curr]
      }
  }, {})
}

export const groupByCountry = (data) => {
  const groupedData = groupData(data, 'country')
  const returnedData = {}

  Object.keys(groupedData).forEach((country) => {
    returnedData[country] = groupedData[country]?.length
  })

  return returnedData
}
