import styled from 'styled-components'
import { Tabs as AntdTabs } from 'antd'

export const ContentWrapper = styled.main`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1.5rem;
`

export const Tabs = styled(AntdTabs)`
  margin-top: 2rem;
`

export const TabText = styled.span`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  color: #272d4e;
  opacity: 0.5;
`
