import React from 'react'
import Select from '@/components/UIKits/Select'

const { Option } = Select

const Element = ({ onChange, ...params }) => {
  return (
    <Select onChange={onChange} {...params}>
      <Option value="inactive">Inactive</Option>
      <Option value="draft">Draft</Option>
      <Option value="active">Active</Option>
    </Select>
  )
}

export default Element
