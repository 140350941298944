import styled from 'styled-components'
import { Tabs as AntdTabs } from 'antd'

export const Main = styled.main`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 1.25rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
`

export const Tabs = styled(AntdTabs)``

export const TabText = styled.span`
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-transform: uppercase;
  color: #272d4e;
  opacity: 0.5;
`

export const Logs = styled.main`
  max-height: calc(100vh - 20rem);
  overflow-y: auto;
`
