import React, { useMemo } from 'react'
import Select from '@/components/UIKits/Select'
import { COUNTRIES } from '@/utils/constants'

const { Option } = Select

const Element = ({ onChange, ...params }) => {
  const sortedCountries = useMemo(() => {
    return COUNTRIES.sort(function (a, b) {
      if (a.label < b.label) {
        return -1
      }
      if (a.label > b.label) {
        return 1
      }
      return 0
    })
  }, [])

  return (
    <Select showSearch={true} onChange={onChange} {...params}>
      {sortedCountries.map((country) => (
        <Option key={country.value} value={country.value}>
          {country.label}
        </Option>
      ))}
    </Select>
  )
}

export default Element
