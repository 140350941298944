import { Link } from 'react-router-dom'
import { useMemo } from 'react'
import Proptypes from 'prop-types'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

import {
  JobSummaryContainer,
  JobSummaryHeader,
  ChartSectionContainer
} from './styles'

ChartJS.register(ArcElement, Tooltip, Legend)

const JobSummary = ({ summaryDetails }) => {
  const [chartData, plugins] = useMemo(() => {
    const chartData = {
      labels: ['Active', 'Inactive', 'Draft'],
      datasets: [
        {
          data: [
            summaryDetails.active,
            summaryDetails.inactive,
            summaryDetails.draft
          ],
          backgroundColor: ['#04C239', '#D62E1F', '#BFBFBF'],
          borderColor: ['#04C239', '#D62E1F', '#BFBFBF'],
          borderWidth: 0.5
        }
      ]
    }

    const pluginTotal = {
      id: 'total',
      afterDatasetsDraw: (chart) => {
        const {
          ctx,
          chartArea: { top, width, height }
        } = chart
        ctx.save()

        ctx.font = '700 1.5rem Arial'
        ctx.textAlign = 'center'
        ctx.fillStyle = '#183B57'
        ctx.fillText(summaryDetails.total, width / 2, top + height / 2)

        ctx.font = '500 0.75rem Arial'
        ctx.textAlign = 'center'
        ctx.fillStyle = 'rgba(24, 59, 87, 0.5)'
        ctx.fillText('Total Jobs', width / 2, top + height / 2 + 15)
      }
    }

    return [chartData, [pluginTotal]]
  }, [summaryDetails])

  return (
    <JobSummaryContainer>
      <JobSummaryHeader>
        <h3 className="title">Jobs Post Summary</h3>
        <Link to="/jobs">
          <span className="details">See Details</span>
        </Link>
      </JobSummaryHeader>

      <ChartSectionContainer>
        <Doughnut
          redraw={true}
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'right',
                align: 'start',
                labels: {
                  color: 'rgba(39, 45, 78, 0.38)',
                  font: 12
                }
              }
            }
          }}
          plugins={plugins}
        />
      </ChartSectionContainer>
    </JobSummaryContainer>
  )
}

JobSummary.propTypes = {
  summaryDetails: Proptypes.objectOf({
    active: Proptypes.number,
    inactive: Proptypes.number,
    draft: Proptypes.number,
    total: Proptypes.number
  })
}

JobSummary.defaultProps = {
  summaryDetails: {
    active: 0,
    inactive: 0,
    draft: 0,
    total: 0
  }
}

export default JobSummary
