import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import SectionInfo from '@/components/Candidate/SectionInfo'
import useApplication from '@/hooks/useApplication'

const Container = () => {
  const { id } = useParams()
  const { onFindOne } = useApplication()
  const [loading, setLoading] = useState(false)
  const [profileInfo, setProfileInfo] = useState({
    job: {
      title: ''
    }
  })

  useEffect(() => {
    setLoading(true)

    onFindOne(id).then((data) => {
      setProfileInfo(data)
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return <SectionInfo data={profileInfo} loading={loading} />
}

export default Container
