import styled from 'styled-components'
import { Checkbox as AntdCheckbox } from 'antd'

export const DetailsContainer = styled.section`
  background: #ffffff;
  border-radius: 8px;
  padding: 1.25rem;

  h2 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #272d4e;
    margin-bottom: 1.5rem;
  }
`

export const SalaryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`

export const CheckboxGroup = styled(AntdCheckbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  .ant-checkbox-wrapper {
    .ant-checkbox {
      .ant-checkbox-inner {
        border-radius: 4px;
      }
    }
  }
`
