import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Header from '@/components/ApplicantsDatabase/Header'
import { getApplicationsTotal } from '@/apis'

const Container = () => {
  const [params] = useSearchParams()
  const [data, setData] = useState({
    numberOfJobs: '...',
    numberOfCountries: '...',
    numberOfProfiles: '...'
  })

  useEffect(() => {
    const searchParams = Object.fromEntries(params)
    delete searchParams.status

    getApplicationsTotal(searchParams).then((data) => {
      setData(data)
    })
  }, [params])

  return <Header data={data} />
}

export default Container
