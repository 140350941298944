import { useSelector } from 'react-redux'

import {
  CountriesSummaryContainer,
  JobSummaryContainer,
  HiringPipelineContainer,
  ManagerListContainer,
  NewJobSectionContainer
} from '@/containers/Dashboard'
import PageLayout from '@/layouts/Default'

import {
  DashboardContainerWrapper,
  DashboardContent,
  DashboardManagerContent
} from './styles'

const Dashboard = () => {
  const { role } = useSelector((state) => state.authenUserReducer)
  const isAdmin = role === 'admin'

  return (
    <PageLayout>
      <DashboardContainerWrapper>
        <h2 className="dashboard-title">Dashboard</h2>

        {!isAdmin ? (
          <DashboardManagerContent>
            <HiringPipelineContainer />

            <CountriesSummaryContainer />
          </DashboardManagerContent>
        ) : (
          <DashboardContent>
            <div className="left-sections">
              <NewJobSectionContainer />

              <HiringPipelineContainer />

              <CountriesSummaryContainer />
            </div>

            <div className="right-sections">
              <JobSummaryContainer />

              <ManagerListContainer />
            </div>
          </DashboardContent>
        )}
      </DashboardContainerWrapper>
    </PageLayout>
  )
}

export default Dashboard
