import styled from 'styled-components'
import { SelectCountry } from '@/components/Selects'

export const CountriesSummaryContainer = styled.section`
  padding: 1.25rem;
  background-color: #ffffff;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
`

export const CustomSelectCountry = styled(SelectCountry)`
  cursor: pointer;
  width: 8.75rem;
  background-color: #f6f8fa;
  border: 1px solid rgba(186, 197, 220, 0.1);
  border-radius: 0.5rem;

  .ant-select-selector {
    background-color: #f6f8fa !important;
    border: none !important;
    border-radius: none !important;
  }

  .ant-select-selection-placeholder {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: ;
    color: #183b57;
  }
`

export const SummaryHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;

  .headerLeft {
    display: flex;
    align-items: center;
    gap: 1.875rem;

    .headerText {
      font-weight: 700;
      font-size: ;
      line-height: 1.25rem;
      color: #183b57;
    }
  }

  .viewAll {
    cursor: pointer;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: ;
    text-decoration-line: underline;
    color: rgba(24, 59, 87, 0.5);
  }
`

export const CountryBoxContainer = styled.main`
  white-space: nowrap;
  overflow: auto;
`

export const CountryBox = styled.a`
  display: inline-block;
  white-space: nowrap;
  background: rgba(186, 197, 220, 0.1);
  color: #272d4e;
  width: 8.75rem;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.75rem 0.5rem;
  margin-right: 0.5rem;

  .secondRow {
    color: rgba(39, 45, 78, 0.7);
    font-size: 0.625rem;
    border-top: 1px solid rgba(186, 197, 220, 0.2);
    padding-top: 0.25rem;
    margin-top: 0.25rem;
  }

  &:hover {
    color: #272d4e;
  }
`
