import React from 'react'
import { Information, Item } from './styles'

const Element = ({ color = '#272d4e', items = [] }) => (
  <Information color={color}>
    {items.map((item, index) => (
      <Item key={index}>{item}</Item>
    ))}
  </Information>
)

export default Element
