import styled from 'styled-components'

export const StyledHeader = styled.header`
  background: white;
  display: flex;
  align-item: center;
  justify-content: center;
`

export const HeaderImg = styled.img`
  width: auto;
  height: 2rem;
  margin: 1.5rem;
`

export const Content = styled.section`
  background-color: #f6f8fa;
  height: calc(100vh - 5rem);
  padding: 2rem 0;
`
