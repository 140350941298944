import React from 'react'
import PublicLayout from '@/layouts/Public'
import ButtonGoogle from '@/containers/ButtonGoogle'
import { FormWrapper, FormFooter, Title } from './styles'

const SignIn = () => {
  return (
    <PublicLayout>
      <FormWrapper>
        <Title>Hiring Dashboard</Title>

        <ButtonGoogle />

        <FormFooter>
          Need help? Contact{' '}
          <a href="mailto:support@flavorwiki.info">Technical Support</a>
        </FormFooter>
      </FormWrapper>
    </PublicLayout>
  )
}

export default SignIn
