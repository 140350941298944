import styled from 'styled-components'
import { Button as AntButton } from "antd"

export const Button = styled(AntButton)`
  margin-right: 1rem;
`

export const DropdownContainer = styled.div`
  width: 20rem;
  max-height: 20rem;
  background-color: #ffffff;
  box-shadow: 0 0.25rem 0.5rem -0.25rem rgba(0, 0, 0, 0.12), 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.08), 0 0.5rem 1.75rem 0.5rem rgba(0, 0, 0, 0.05);
  overflow: auto;
`

export const NoDataFound = styled.div`
  padding: 1rem;
`

export const Loading = styled.div`
  text-align: center;
  padding: 2rem;
`