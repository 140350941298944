import { createAsyncThunk } from '@reduxjs/toolkit'

import { getApplicationLogs as getApplicationLogsApi } from '@/apis'

export const getApplicationLogs = createAsyncThunk(
  'applicationLogs/getApplicationLogs',
  async (id) => {
    try {
      const response = await getApplicationLogsApi(id)
      return response?.data
    } catch {
      throw new Error()
    }
  }
)
