import styled from "styled-components"

export const Header = styled.header`
  display: flex;
  justify-content: space-between;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`

export const Left = styled.div`
  display: flex;
  gap: 1.5rem;

  div > h3 {
    font-weight: 700;
    color: #272d4e;
    font-size: 1.5rem;
  }
`

export const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 0.125rem;
  margin-bottom: 0.25rem;
`

export const Right = styled.div`
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 1rem;

    @media (max-width: 700px) {
      margin-top: 1rem;
      justify-content: center;
    }

    > span {
      color: #878981;
      font-weight: 500;
    }
  }
`
