import IconARG from '@/assets/CountryFlags/ARG.png'
import IconAUS from '@/assets/CountryFlags/AUS.png'
import IconCAN from '@/assets/CountryFlags/CAN.png'
import IconCHL from '@/assets/CountryFlags/CHL.png'
import IconCZE from '@/assets/CountryFlags/CZE.png'
import IconEGY from '@/assets/CountryFlags/EGY.png'
import IconFRA from '@/assets/CountryFlags/FRA.png'
import IconDEU from '@/assets/CountryFlags/DEU.png'
import IconGHA from '@/assets/CountryFlags/GHA.png'
import IconGIN from '@/assets/CountryFlags/GIN.png'
import IconHUN from '@/assets/CountryFlags/HUN.png'
import IconIND from '@/assets/CountryFlags/IND.png'
import IconIDN from '@/assets/CountryFlags/IDN.png'
import IconIRL from '@/assets/CountryFlags/IRL.png'
import IconITA from '@/assets/CountryFlags/ITA.png'
import IconCIV from '@/assets/CountryFlags/CIV.png'
import IconKEN from '@/assets/CountryFlags/KEN.png'
import IconMEX from '@/assets/CountryFlags/MEX.png'
import IconMAR from '@/assets/CountryFlags/MAR.png'
import IconNGA from '@/assets/CountryFlags/NGA.png'
import IconPAK from '@/assets/CountryFlags/PAK.png'
import IconPER from '@/assets/CountryFlags/PER.png'
import IconPHL from '@/assets/CountryFlags/PHL.png'
import IconPOL from '@/assets/CountryFlags/POL.png'
import IconPRT from '@/assets/CountryFlags/PRT.png'
import IconROU from '@/assets/CountryFlags/ROU.png'
import IconRUS from '@/assets/CountryFlags/RUS.png'
import IconSEN from '@/assets/CountryFlags/SEN.png'
import IconSRB from '@/assets/CountryFlags/SRB.png'
import IconSVK from '@/assets/CountryFlags/SVK.png'
import IconSVN from '@/assets/CountryFlags/SVN.png'
import IconZAF from '@/assets/CountryFlags/ZAF.png'
import IconESP from '@/assets/CountryFlags/ESP.png'
import IconTHA from '@/assets/CountryFlags/THA.png'
import IconGBR from '@/assets/CountryFlags/GBR.png'
import IconUSA from '@/assets/CountryFlags/USA.png'
import IconVEN from '@/assets/CountryFlags/VEN.png'
import IconVNM from '@/assets/CountryFlags/VNM.png'
import IconUKR from '@/assets/CountryFlags/UKR.png'
import IconSGP from '@/assets/CountryFlags/SGP.png'
import IconKOR from '@/assets/CountryFlags/KOR.png'
import IconJPN from '@/assets/CountryFlags/jpn.png'
import IconMYS from '@/assets/CountryFlags/MYS.png'
import IconCHN from '@/assets/CountryFlags/CHN.png'
import IconKHM from '@/assets/CountryFlags/KHM.png'
import IconCHE from '@/assets/CountryFlags/CHE.png'
import IconGRC from '@/assets/CountryFlags/GRC.png'
import IconTUR from '@/assets/CountryFlags/TUR.png'
import IconGEO from '@/assets/CountryFlags/GEO.png'
import IconBRA from '@/assets/CountryFlags/BRA.png'
import IconCOL from '@/assets/CountryFlags/COL.png'
import IconNLD from '@/assets/CountryFlags/NLD.png'
import IconGTM from '@/assets/CountryFlags/GTM.png'

export const STATUSES = {
  '01. In review': 'In Review',
  '02. Shortlisted': 'Shortlisted',
  '03. Send the Test 1': 'Send Test 1',
  '04. Test 1 Received': 'Test 1 Received',
  '05. Request a Portfolio': 'Request portfolio',
  '06. Set First Interview': 'Interview',
  '07. First Invitation Set': 'First interview set',
  '08. First Interview Completed': 'First Interview Completed',
  '09. Send test 2': 'Send test 2',
  '10. Test 2 Received': 'Test 2 Received',
  '11. Advance to Second Interview': 'Advance to Second Interview',
  '12. Second Interview Set': 'Second interview set',
  '13. Second Interview Completed': 'Second Interview Completed',
  '14. Offer': 'Offer',
  '15. On Hold': 'On hold',
  Rejected: 'Reject'
}

export const REGIONS = {
  europe: {
    id: 'ec8daca1-f4fb-4285-8bec-6993b7df4f49',
    value: 'Europe',
    label: 'Europe'
  },
  southAsia: {
    id: 'ae160c05-c8b4-42d7-9c70-3fac91b77ce4',
    value: 'South Asia',
    label: 'South Asia'
  },
  eastAsia: {
    id: 'a3456cde-7db8-482a-850f-9f4c95205f8b',
    value: 'East Asia',
    label: 'East Asia'
  },
  southEastAsia: {
    id: '123f6bf7-da92-41f4-bf6f-16799fccd794',
    value: 'South East Asia',
    label: 'South East Asia'
  },
  australia: {
    id: '260bbcbd-5835-4b7c-86d2-b7f72aaa0220',
    value: 'Australia',
    label: 'Australia'
  },
  africa: {
    id: 'b09a7e70-dd6f-46a7-9618-e3806cb9e22e',
    value: 'Africa',
    label: 'Africa'
  },
  northLatinAmerica: {
    id: '1c3c1fde-d2a5-45f7-9048-d36274642a29',
    value: 'North & Latin America',
    label: 'North & Latin America'
  }
}

export const COUNTRIES = [
  {
    label: 'Argentina',
    value: 'Argentina',
    code: 'ARG',
    regionId: REGIONS.northLatinAmerica.id,
    icon: IconARG
  },
  {
    label: 'Australia',
    value: 'Australia',
    code: 'AUS',
    regionId: REGIONS.australia.id,
    icon: IconAUS
  },
  {
    label: 'Canada',
    value: 'Canada',
    code: 'CAN',
    regionId: REGIONS.northLatinAmerica.id,
    icon: IconCAN
  },
  {
    label: 'Chile',
    value: 'Chile',
    code: 'CHL',
    regionId: REGIONS.northLatinAmerica.id,
    icon: IconCHL
  },
  {
    label: 'Czech Republic',
    value: 'Czech Republic',
    code: 'CZE',
    regionId: REGIONS.europe.id,
    icon: IconCZE
  },
  {
    label: 'Egypt',
    value: 'Egypt',
    code: 'EGY',
    regionId: REGIONS.africa.id,
    icon: IconEGY
  },
  {
    label: 'East Asia',
    value: 'East Asia',
    code: 'EAA',
    regionId: REGIONS.eastAsia.id,
    icon: ''
  },
  {
    label: 'Eastern countries',
    value: 'Eastern countries',
    code: 'EAC',
    regionId: '',
    icon: ''
  },
  {
    label: 'Europe',
    value: 'Europe',
    code: 'ERU',
    regionId: REGIONS.europe.id,
    icon: ''
  },
  {
    label: 'France',
    value: 'France',
    code: 'FRA',
    regionId: REGIONS.europe.id,
    icon: IconFRA
  },
  {
    label: 'Germany',
    value: 'Germany',
    code: 'DEU',
    regionId: REGIONS.europe.id,
    icon: IconDEU
  },
  {
    label: 'Ghana',
    value: 'Ghana',
    code: 'GHA',
    regionId: REGIONS.africa.id,
    icon: IconGHA
  },
  {
    label: 'Guinea',
    value: 'Guinea',
    code: 'GIN',
    regionId: REGIONS.africa.id,
    icon: IconGIN
  },
  {
    label: 'Hungary',
    value: 'Hungary',
    code: 'HUN',
    regionId: REGIONS.europe.id,
    icon: IconHUN
  },
  {
    label: 'India',
    value: 'India',
    code: 'IND',
    regionId: REGIONS.southAsia.id,
    icon: IconIND
  },
  {
    label: 'Indonesia',
    value: 'Indonesia',
    code: 'IDN',
    regionId: REGIONS.southEastAsia.id,
    icon: IconIDN
  },
  {
    label: 'Ireland',
    value: 'Ireland',
    code: 'IRL',
    regionId: REGIONS.europe.id,
    icon: IconIRL
  },
  {
    label: 'Italy',
    value: 'Italy',
    code: 'ITA',
    regionId: REGIONS.europe.id,
    icon: IconITA
  },
  {
    label: 'Ivory Coast',
    value: 'Ivory Coast',
    code: 'CIV',
    regionId: REGIONS.africa.id,
    icon: IconCIV
  },
  {
    label: 'Kenya',
    value: 'Kenya',
    code: 'KEN',
    regionId: REGIONS.africa.id,
    icon: IconKEN
  },
  {
    label: 'Latin America',
    value: 'Latin America',
    code: 'LTA',
    regionId: REGIONS.northLatinAmerica.id,
    icon: ''
  },
  {
    label: 'Mexico',
    value: 'Mexico',
    code: 'MEX',
    regionId: REGIONS.northLatinAmerica.id,
    icon: IconMEX
  },
  {
    label: 'Morocco',
    value: 'Morocco',
    code: 'MAR',
    regionId: REGIONS.africa.id,
    icon: IconMAR
  },
  {
    label: 'Nigeria',
    value: 'Nigeria',
    code: 'NGA',
    regionId: REGIONS.africa.id,
    icon: IconNGA
  },
  {
    label: 'North America',
    value: 'North America',
    code: 'NTA',
    regionId: '',
    icon: ''
  },
  {
    label: 'Pakistan',
    value: 'Pakistan',
    code: 'PAK',
    regionId: REGIONS.southAsia.id,
    icon: IconPAK
  },
  {
    label: 'Peru',
    value: 'Peru',
    code: 'PER',
    regionId: REGIONS.northLatinAmerica.id,
    icon: IconPER
  },
  {
    label: 'Philippines',
    value: 'Philippines',
    code: 'PHL',
    regionId: REGIONS.southEastAsia.id,
    icon: IconPHL
  },
  {
    label: 'Poland',
    value: 'Poland',
    code: 'POL',
    regionId: REGIONS.europe.id,
    icon: IconPOL
  },
  {
    label: 'Portugal',
    value: 'Portugal',
    code: 'PRT',
    regionId: REGIONS.europe.id,
    icon: IconPRT
  },
  {
    label: 'Romania',
    value: 'Romania',
    code: 'ROU',
    regionId: REGIONS.europe.id,
    icon: IconROU
  },
  {
    label: 'Russia',
    value: 'Russia',
    code: 'RUS',
    regionId: REGIONS.europe.id,
    icon: IconRUS
  },
  {
    label: 'Senegal',
    value: 'Senegal',
    code: 'SEN',
    regionId: REGIONS.africa.id,
    icon: IconSEN
  },
  {
    label: 'Serbia',
    value: 'Serbia',
    code: 'SRB',
    regionId: REGIONS.europe.id,
    icon: IconSRB
  },
  {
    label: 'Slovakia',
    value: 'Slovakia',
    code: 'SVK',
    regionId: REGIONS.europe.id,
    icon: IconSVK
  },
  {
    label: 'Slovenia',
    value: 'Slovenia',
    code: 'SVN',
    regionId: REGIONS.europe.id,
    icon: IconSVN
  },
  {
    label: 'South Africa',
    value: 'South Africa',
    code: 'ZAF',
    regionId: REGIONS.africa.id,
    icon: IconZAF
  },
  {
    label: 'South East Asia',
    value: 'South East Asia',
    code: 'SEA',
    regionId: REGIONS.southEastAsia.id,
    icon: ''
  },
  {
    label: 'Spain',
    value: 'Spain',
    code: 'ESP',
    regionId: REGIONS.europe.id,
    icon: IconESP
  },
  {
    label: 'Thailand',
    value: 'Thailand',
    code: 'THA',
    regionId: REGIONS.southEastAsia.id,
    icon: IconTHA
  },
  {
    label: 'UK',
    value: 'UK',
    code: 'GBR',
    regionId: REGIONS.europe.id,
    icon: IconGBR
  },
  {
    label: 'USA',
    value: 'USA',
    code: 'USA',
    regionId: REGIONS.northLatinAmerica.id,
    icon: IconUSA
  },
  {
    label: 'Venezuela',
    value: 'Venezuela',
    code: 'VEN',
    regionId: REGIONS.northLatinAmerica.id,
    icon: IconVEN
  },
  {
    label: 'Vietnam',
    value: 'Vietnam',
    code: 'VNM',
    regionId: REGIONS.southEastAsia.id,
    icon: IconVNM
  },
  {
    label: 'Western Ukraine',
    value: 'Western Ukraine',
    code: 'UKR',
    regionId: REGIONS.europe.id,
    icon: IconUKR
  },
  {
    label: 'Singapore',
    value: 'Singapore',
    code: 'SGP',
    regionId: REGIONS.southEastAsia.id,
    icon: IconSGP
  },
  {
    label: 'Cambodia',
    value: 'Cambodia',
    code: 'KHM',
    regionId: REGIONS.southEastAsia.id,
    icon: IconKHM
  },
  {
    label: 'Malaysia',
    value: 'Malaysia',
    code: 'MYS',
    regionId: REGIONS.southEastAsia.id,
    icon: IconMYS
  },
  {
    label: 'China',
    value: 'China',
    code: 'CHN',
    regionId: REGIONS.eastAsia.id,
    icon: IconCHN
  },
  {
    label: 'South Korea',
    value: 'South Korea',
    code: 'KOR',
    regionId: REGIONS.eastAsia.id,
    icon: IconKOR
  },
  {
    label: 'Japan',
    value: 'Japan',
    code: 'JPN',
    regionId: REGIONS.eastAsia.id,
    icon: IconJPN
  },
  {
    label: 'Switzerland',
    value: 'Switzerland',
    code: 'CHE',
    regionId: REGIONS.europe.id,
    icon: IconCHE
  },
  {
    label: 'Greece',
    value: 'Greece',
    code: 'GRC',
    regionId: REGIONS.europe.id,
    icon: IconGRC
  },
  {
    label: 'Georgia',
    value: 'Georgia',
    code: 'GEO',
    regionId: REGIONS.europe.id,
    icon: IconGEO
  },
  {
    label: 'Turkey',
    value: 'Turkey',
    code: 'TUR',
    regionId: REGIONS.europe.id,
    icon: IconTUR
  },
  {
    label: 'Brazil',
    value: 'Brazil',
    code: 'BRA',
    regionId: REGIONS.northLatinAmerica.id,
    icon: IconBRA
  },
  {
    label: 'Colombia',
    value: 'Colombia',
    code: 'COL',
    regionId: REGIONS.northLatinAmerica.id,
    icon: IconCOL
  }, {
    label: 'Netherlands',
    value: 'Netherlands',
    code: 'NLD',
    regionId: REGIONS.europe.id,
    icon: IconNLD
  }, {
    label: 'Guatemala',
    value: 'Guatemala',
    code: 'GTM',
    regionId: REGIONS.northLatinAmerica.id,
    icon: IconGTM
  }
]

export const COUNTRY_CODES = {
  AFG: 'Afghanistan',
  ALB: 'Albania',
  DZA: 'Algeria',
  ASM: 'American Samoa',
  AND: 'Andorra',
  AGO: 'Angola',
  AIA: 'Anguilla',
  ATA: 'Antarctica',
  ATG: 'Antigua and Barbuda',
  ARG: 'Argentina',
  ARM: 'Armenia',
  ABW: 'Aruba',
  AUS: 'Australia',
  AUT: 'Austria',
  AZE: 'Azerbaijan',
  BHS: 'Bahamas (the)',
  BHR: 'Bahrain',
  BGD: 'Bangladesh',
  BRB: 'Barbados',
  BLR: 'Belarus',
  BEL: 'Belgium',
  BLZ: 'Belize',
  BEN: 'Benin',
  BMU: 'Bermuda',
  BTN: 'Bhutan',
  BOL: 'Bolivia (Plurinational State of)',
  BES: 'Bonaire, Sint Eustatius and Saba',
  BIH: 'Bosnia and Herzegovina',
  BWA: 'Botswana',
  BVT: 'Bouvet Island',
  BRA: 'Brazil',
  IOT: 'British Indian Ocean Territory (the)',
  BRN: 'Brunei Darussalam',
  BGR: 'Bulgaria',
  BFA: 'Burkina Faso',
  BDI: 'Burundi',
  CPV: 'Cabo Verde',
  KHM: 'Cambodia',
  CMR: 'Cameroon',
  CAN: 'Canada',
  CYM: 'Cayman Islands (the)',
  CAF: 'Central African Republic (the)',
  TCD: 'Chad',
  CHL: 'Chile',
  CHN: 'China',
  CXR: 'Christmas Island',
  CCK: 'Cocos (Keeling) Islands (the)',
  COL: 'Colombia',
  COM: 'Comoros (the)',
  COD: 'Congo (the Democratic Republic of the)',
  COG: 'Congo (the)',
  COK: 'Cook Islands (the)',
  CRI: 'Costa Rica',
  HRV: 'Croatia',
  CUB: 'Cuba',
  CUW: 'Curaçao',
  CYP: 'Cyprus',
  CZE: 'Czechia',
  CIV: "Côte d'Ivoire",
  DNK: 'Denmark',
  DJI: 'Djibouti',
  DMA: 'Dominica',
  DOM: 'Dominican Republic (the)',
  ECU: 'Ecuador',
  EGY: 'Egypt',
  SLV: 'El Salvador',
  GNQ: 'Equatorial Guinea',
  ERI: 'Eritrea',
  EST: 'Estonia',
  SWZ: 'Eswatini',
  ETH: 'Ethiopia',
  FLK: 'Falkland Islands (the) [Malvinas]',
  FRO: 'Faroe Islands (the)',
  FJI: 'Fiji',
  FIN: 'Finland',
  FRA: 'France',
  GUF: 'French Guiana',
  PYF: 'French Polynesia',
  ATF: 'French Southern Territories (the)',
  GAB: 'Gabon',
  GMB: 'Gambia (the)',
  GEO: 'Georgia',
  DEU: 'Germany',
  GHA: 'Ghana',
  GIB: 'Gibraltar',
  GRC: 'Greece',
  GRL: 'Greenland',
  GRD: 'Grenada',
  GLP: 'Guadeloupe',
  GUM: 'Guam',
  GTM: 'Guatemala',
  GGY: 'Guernsey',
  GIN: 'Guinea',
  GNB: 'Guinea-Bissau',
  GUY: 'Guyana',
  HTI: 'Haiti',
  HMD: 'Heard Island and McDonald Islands',
  VAT: 'Holy See (the)',
  HND: 'Honduras',
  HKG: 'Hong Kong',
  HUN: 'Hungary',
  ISL: 'Iceland',
  IND: 'India',
  IDN: 'Indonesia',
  IRN: 'Iran (Islamic Republic of)',
  IRQ: 'Iraq',
  IRL: 'Ireland',
  IMN: 'Isle of Man',
  ISR: 'Israel',
  ITA: 'Italy',
  JAM: 'Jamaica',
  JPN: 'Japan',
  JEY: 'Jersey',
  JOR: 'Jordan',
  KAZ: 'Kazakhstan',
  KEN: 'Kenya',
  KIR: 'Kiribati',
  PRK: "Korea (the Democratic People's Republic of)",
  KOR: 'Korea (the Republic of)',
  KWT: 'Kuwait',
  KGZ: 'Kyrgyzstan',
  LAO: "Lao People's Democratic Republic (the)",
  LVA: 'Latvia',
  LBN: 'Lebanon',
  LSO: 'Lesotho',
  LBR: 'Liberia',
  LBY: 'Libya',
  LIE: 'Liechtenstein',
  LTU: 'Lithuania',
  LUX: 'Luxembourg',
  MAC: 'Macao',
  MDG: 'Madagascar',
  MWI: 'Malawi',
  MYS: 'Malaysia',
  MDV: 'Maldives',
  MLI: 'Mali',
  MLT: 'Malta',
  MHL: 'Marshall Islands (the)',
  MTQ: 'Martinique',
  MRT: 'Mauritania',
  MUS: 'Mauritius',
  MYT: 'Mayotte',
  MEX: 'Mexico',
  FSM: 'Micronesia (Federated States of)',
  MDA: 'Moldova (the Republic of)',
  MCO: 'Monaco',
  MNG: 'Mongolia',
  MNE: 'Montenegro',
  MSR: 'Montserrat',
  MAR: 'Morocco',
  MOZ: 'Mozambique',
  MMR: 'Myanmar',
  NAM: 'Namibia',
  NRU: 'Nauru',
  NPL: 'Nepal',
  NLD: 'Netherlands (the)',
  NCL: 'New Caledonia',
  NZL: 'New Zealand',
  NIC: 'Nicaragua',
  NER: 'Niger (the)',
  NGA: 'Nigeria',
  NIU: 'Niue',
  NFK: 'Norfolk Island',
  MNP: 'Northern Mariana Islands (the)',
  NOR: 'Norway',
  OMN: 'Oman',
  PAK: 'Pakistan',
  PLW: 'Palau',
  PSE: 'Palestine, State of',
  PAN: 'Panama',
  PNG: 'Papua New Guinea',
  PRY: 'Paraguay',
  PER: 'Peru',
  PHL: 'Philippines',
  PCN: 'Pitcairn',
  POL: 'Poland',
  PRT: 'Portugal',
  PRI: 'Puerto Rico',
  QAT: 'Qatar',
  MKD: 'Republic of North Macedonia',
  ROU: 'Romania',
  RUS: 'Russian Federation',
  RWA: 'Rwanda',
  REU: 'Réunion',
  BLM: 'Saint Barthélemy',
  SHN: 'Saint Helena, Ascension and Tristan da Cunha',
  KNA: 'Saint Kitts and Nevis',
  LCA: 'Saint Lucia',
  MAF: 'Saint Martin',
  SPM: 'Saint Pierre and Miquelon',
  VCT: 'Saint Vincent and the Grenadines',
  WSM: 'Samoa',
  SMR: 'San Marino',
  STP: 'Sao Tome and Principe',
  SAU: 'Saudi Arabia',
  SEN: 'Senegal',
  SRB: 'Serbia',
  SYC: 'Seychelles',
  SLE: 'Sierra Leone',
  SGP: 'Singapore',
  SXM: 'Sint Maarten',
  SVK: 'Slovakia',
  SVN: 'Slovenia',
  SLB: 'Solomon Islands',
  SOM: 'Somalia',
  ZAF: 'South Africa',
  SGS: 'South Georgia and the South Sandwich Islands',
  SSD: 'South Sudan',
  ESP: 'Spain',
  LKA: 'Sri Lanka',
  SDN: 'Sudan',
  SUR: 'Suriname',
  SJM: 'Svalbard and Jan Mayen',
  SWE: 'Sweden',
  CHE: 'Switzerland',
  SYR: 'Syrian Arab Republic',
  TWN: 'Taiwan',
  TJK: 'Tajikistan',
  TZA: 'Tanzania, United Republic of',
  THA: 'Thailand',
  TLS: 'Timor-Leste',
  TGO: 'Togo',
  TKL: 'Tokelau',
  TON: 'Tonga',
  TTO: 'Trinidad and Tobago',
  TUN: 'Tunisia',
  TUR: 'Turkey',
  TKM: 'Turkmenistan',
  TCA: 'Turks and Caicos Islands',
  TUV: 'Tuvalu',
  UGA: 'Uganda',
  UKR: 'Ukraine',
  ARE: 'United Arab Emirates',
  GBR: 'United Kingdom of Great Britain and Northern Ireland',
  UMI: 'United States Minor Outlying Islands',
  USA: 'United States of America',
  URY: 'Uruguay',
  UZB: 'Uzbekistan',
  VUT: 'Vanuatu',
  VEN: 'Venezuela',
  VNM: 'Viet Nam',
  VGB: 'Virgin Islands (British)',
  VIR: 'Virgin Islands (U.S.)',
  WLF: 'Wallis and Futuna',
  ESH: 'Western Sahara',
  YEM: 'Yemen',
  ZMB: 'Zambia',
  ZWE: 'Zimbabwe',
  ALA: 'Åland Islands',
  ERU: 'Europe',
  NTA: 'North America',
  LTA: 'Latin America',
  EAA: 'East Asia',
  EAC: 'Eastern countries',
  SEA: 'South East Asia'
}

export const JOB_STATUSES = {
  active: {
    value: 'active',
    label: 'Active'
  },
  inactive: {
    value: 'inactive',
    label: 'Inactive'
  },
  draft: {
    value: 'draft',
    label: 'Draft'
  }
}

export const JOB_TYPES = {
  permanent: {
    value: 'permanent',
    label: 'Permanent'
  },
  partTime: {
    value: 'partTime',
    label: 'Part Time'
  }
}

export const FILE_NEEDED_TYPES = {
  cv: 'CV',
  linkedInProfile: 'LinkedIn Profile',
  englishCertificate: 'English Certificate',
  presentationVideo: 'Presentation Video'
}

export const FILES_NEEDED = Object.values(FILE_NEEDED_TYPES)

export const DEFAULT_FILES_NEEDED = [
  FILE_NEEDED_TYPES.cv,
  FILE_NEEDED_TYPES.linkedInProfile,
  FILE_NEEDED_TYPES.englishCertificate,
  FILE_NEEDED_TYPES.presentationVideo
]

export const EXPERIENCE_YEARS = {
  noExp: {
    value: 0,
    label: 'No experience needed'
  },
  oneYear: {
    value: 1,
    label: '1+ years of experience'
  },
  twoYears: {
    value: 2,
    label: '2+ years of experience'
  },
  threeYears: {
    value: 3,
    label: '3+ years of experience'
  },
  fourYears: {
    value: 4,
    label: '4+ years of experience'
  },
  fiveYears: {
    value: 5,
    label: '5+ years of experience'
  }
}

export const CURRENCIES = {
  sgd: {
    value: 'SGD',
    label: 'Singapore Dollar (SGD)'
  },
  eur: {
    value: 'EUR',
    label: 'Euro (EUR)'
  },
  usd: {
    value: 'USD',
    label: 'United States Dollar (USD)'
  }
}

export const SALARY_OPTIONS = [
  { value: '500-1000', label: '500-1000' },
  { value: '1000-1500', label: '1000-1500' },
  { value: '1500-2000', label: '1500-2000' },
  { value: '2000-2500', label: '2000-2500' },
  { value: '2500-3000', label: '2500-3000' },
  { value: '3000-3500', label: '3000-3500' },
  { value: '3500-4000', label: '3500-4000' }
]

export const JOB_STATUS_COLOR = {
  active: {
    text: '#009933',
    background: '#D9F3E0'
  },
  inactive: {
    text: '#D52B1E',
    background: '#FFE2E0'
  },
  draft: {
    text: '#878981',
    background: '#E8E8E9'
  }
}

export const EVENT_STATUSES = {
  past: {
    value: 'past',
    label: 'Past'
  },
  upcoming: {
    value: 'upcoming',
    label: 'Upcoming'
  },
  inactive: {
    value: 'inactive',
    label: 'Inactive'
  }
}

export const MANAGER_STATUSES = {
  deactive: {
    value: 'deactive',
    label: 'deactive',
    txtColor: '#D52B1E',
    bgColor: '#FFE2E0'
  },
  active: {
    value: 'active',
    label: 'active',
    txtColor: '#009933',
    bgColor: '#D9F3E0'
  },
  invited: {
    value: 'invited',
    label: 'invited',
    txtColor: '#878981',
    bgColor: '#E8E8E9'
  }
}

export const LOG_TYPES = {
  edited: {
    value: 'edited',
    label: 'Edited',
    txtColor: '#E3B900',
    bgColor: 'rgba(252, 209, 22, 0.08)'
  },
  added: {
    value: 'added',
    label: 'Added',
    txtColor: '#009933',
    bgColor: 'rgba(0, 153, 51, 0.08)'
  }
}

export const MANAGER_ROLES = {
  admin: {
    value: 'admin',
    label: 'Admin'
  },
  manager: {
    value: 'normal',
    label: 'Normal'
  }
}
