import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { JobCards } from '@/components/Jobs'
import { updateJob, getJobs, cloneJob } from '@/redux/jobs/thunk'
import useJobCounters from '@/hooks/useJobCounters'

const JobCardsContainer = () => {
  const dispatch = useDispatch()
  const { refetch } = useJobCounters()
  const { jobs, isJobsLoading } = useSelector((state) => state.jobsReducer)
  const [params] = useSearchParams()

  useEffect(() => {
    refetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const searchParams = useMemo(() => {
    const currentParams = Object.fromEntries(params)
    return {
      ...(currentParams.key && {
        title: currentParams.key
      }),
      ...(currentParams.category && {
        categories: currentParams.category.split(',')
      }),
      ...(currentParams.status && {
        status: currentParams.status.split(',')
      })
    }
  }, [params])

  const sortedJobPosts = useMemo(() => {
    const currentParams = Object.fromEntries(params)
    const sortDir = currentParams.sortDir

    if (!jobs) return []
    const data = [...jobs]

    if (!sortDir) return data

    const alphabeticalJobs = data?.sort((a, b) =>
      a.title.localeCompare(b.title)
    )

    return parseInt(sortDir) === 1
      ? alphabeticalJobs
      : alphabeticalJobs.reverse()
  }, [params, jobs])

  const handleUpdateJob = useCallback(
    ({ payload, id }) => {
      dispatch(
        updateJob({
          data: payload,
          id,
          params: searchParams
        })
      )
    },
    [dispatch, searchParams]
  )

  const handleCloneJob = useCallback(
    (id) => {
      dispatch(cloneJob({ id, params: searchParams }))
    },
    [dispatch, searchParams]
  )

  useEffect(() => {
    dispatch(getJobs(searchParams))
  }, [dispatch, searchParams])

  return (
    <JobCards
      jobPosts={sortedJobPosts}
      isLoading={isJobsLoading}
      updateJob={handleUpdateJob}
      cloneJob={handleCloneJob}
    />
  )
}

export default JobCardsContainer
