import React, { useState } from "react"
import PropTypes from "prop-types"
import upperFirst from "lodash/upperFirst"
import moment from "moment"
import { Link } from "react-router-dom"
import { Tag, Dropdown, Menu } from "antd"
import {
  EllipsisOutlined,
  CloudUploadOutlined,
  RightOutlined,
} from "@ant-design/icons"
import ModalConfirm from "@/components/ModalConfirm"
import { COUNTRIES } from "@/utils/constants"
import UsersIcon from "@/assets/Users.svg"
import BlueCheckedIcon from "@/assets/BlueCheckIcon.svg"
import EarthIcon from "@/assets/Earth.png"
import { JOB_STATUS_COLOR } from "@/utils/constants"
import {
  CardWrapper,
  Header,
  StatusText,
  Title,
  SubTitle,
  TotalApplication,
  ExtraInfoRow,
  CardFooter,
} from "./styles"

const JobCard = ({ job, total, cloneJob }) => {
  const {
    category,
    status,
    country,
    title,
    ableToWorkRemotely,
    startDate,
    _id: id,
  } = job || {}
  const [visible, setVisible] = useState(false)
  const statusColor = JOB_STATUS_COLOR[status]
  const mappedCountry = COUNTRIES.find(
    (countryData) => countryData.value === country
  )
  const publishedDate = startDate ? moment(startDate).format("DD/MM/YY") : "N/A"

  const handleOk = (action) => () => {
    cloneJob(id)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <CardWrapper statusColor={statusColor.text}>
      <Header>
        <div>
          <span className="department">
            {upperFirst(category?.toLowerCase())}
          </span>

          <Tag color={statusColor.background}>
            <StatusText color={statusColor.text}>{status}</StatusText>
          </Tag>
        </div>

        <Dropdown
          arrow
          overlay={
            <Menu
              items={[
                {
                  label: <Link to={`/job/edit/${id}`}>Edit Job</Link>,
                  key: "edit",
                },
                {
                  label: (
                    <div
                      onClick={() => {
                        setVisible(true)
                      }}
                    >
                      Clone Job
                    </div>
                  ),
                  key: "clone",
                },
              ]}
            />
          }
        >
          <EllipsisOutlined style={{ height: "1rem" }} />
        </Dropdown>

        <ModalConfirm
          openModal={visible}
          handleCancel={handleCancel}
          handleOk={handleOk()}
          title="Clone Selected Job"
          intro="Are you sure you want to make a copy of this job?"
        />
      </Header>

      <Title>{title}</Title>

      <SubTitle>
        <img
          className="country-icon"
          src={mappedCountry?.icon || EarthIcon}
          alt={country}
        />
        <span className="country-name">{country}</span>
      </SubTitle>

      <TotalApplication>
        <img src={UsersIcon} alt="users" />
        <span className="total-number">{total || 0}</span>
        <span className="text-content">Applicants In Hiring Pipeline</span>
      </TotalApplication>

      <ExtraInfoRow>
        <div />

        {ableToWorkRemotely && (
          <div>
            <img src={BlueCheckedIcon} alt="remote-work" className="icon" />
            <span className="text-content">Remote-Work</span>
          </div>
        )}
      </ExtraInfoRow>

      <CardFooter>
        <span className="published-date-section">
          <CloudUploadOutlined />
          <span className="text-content">Published: {publishedDate}</span>
        </span>

        <span className="see-details-section">
          <Link to={`/job/${id}`}>
            <span className="text-content">See Details</span>
          </Link>
          <RightOutlined />
        </span>
      </CardFooter>
    </CardWrapper>
  )
}

JobCard.propTypes = {
  job: PropTypes.object,
  total: PropTypes.number,
  isApplicationsLoading: PropTypes.bool,
  updateJob: PropTypes.func,
}

JobCard.defaultProps = {
  job: {},
  total: 0,
  isApplicationsLoading: false,
  updateJob: () => {},
}

export default JobCard
