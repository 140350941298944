import React from 'react'
import Select from '@/components/UIKits/Select'
import useFetchJobTitles from '@/hooks/FetchJobTitles'

const { Option } = Select

const Element = ({ onChange, ...params }) => {
  const { titlesList } = useFetchJobTitles()

  return (
    <Select
      showSearch={true}
      onChange={onChange}
      {...params}
      dropdownStyle={{ minWidth: '20rem' }}
    >
      {titlesList.map((title, index) => (
        <Option key={index} value={title}>
          {title}
        </Option>
      ))}
    </Select>
  )
}

export default Element
