import React from 'react'
import Checkboxes from '@/components/Filters/Checkboxes/Department'
import { FilterOutlined } from '@ant-design/icons'
import { Wrapper } from './styles'

const SectionFilter = () => {
  return (
    <Wrapper className="filter">
      <h3>
        <FilterOutlined /> FILTER
      </h3>

      <h4>Department</h4>
      <Checkboxes paramKey="department" />
    </Wrapper>
  )
}

export default SectionFilter
