import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import CardsManagers from '@/components/Managers/Cards'
import {
  getManagers,
  deleteManager,
  updateManager,
  resendInivation
} from '@/apis'
import { message } from 'antd'

const Container = () => {
  const [params] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [managerList, setManagerList] = useState([])

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const refetch = () => {
    setLoading(true)

    getManagers(params)
      .then((data) => {
        setManagerList(data.data)
      })
      .catch(() => {
        message.error('error when getting data')
      })
      .finally(() => {
        setLoading(false)
      });
  }

  const handleUpdateManagerStatus = (id, action) => {
    if (action === 'deactivate') {
      deleteManager(id).then(() => {
        message.success('Manger deactivated!')
        refetch()
      })
    }  
    
    if (action === 'activate') {
      updateManager(id, { status: 'active' }).then(() => {
        message.success('Manger activated!')
        refetch()
      })
    }
  }

  const handleResendInvitation = (id) => {
    resendInivation(id).then(() => message.success('Invitation resent'))
  }

  return (
    <CardsManagers
      loading={loading}
      data={managerList}
      refetch={refetch}
      handleUpdateManagerStatus={handleUpdateManagerStatus}
      handleResendInvitation={handleResendInvitation}
    />
  )
}

export default Container
