import { useState } from "react"
import { find } from "@/apis/userLogs"

const useUserLogs = () => {
  const [isLoading, setIsLoading] = useState()
  const [dataSource, setDataSource] = useState([])

  const refetch = () => {
    setIsLoading(true)

    find({ limit: 10 })
      .then((res) => {
        setDataSource(res.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return { isLoading, dataSource, refetch }
}

export default useUserLogs
