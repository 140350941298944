import { createAsyncThunk } from '@reduxjs/toolkit'
import { message } from 'antd'

import {
  getJobsList as getJobsApi,
  getJob as getJobApi,
  createJob as createJobApi,
  cloneJob as cloneJobApi,
  updateJob as updateJobApi,
  getJobEmails as getJobEmailsApi
} from '@/apis'

export const getJobs = createAsyncThunk('jobs/getJobs', async (payload) => {
  try {
    const response = await getJobsApi(payload)
    return response?.data
  } catch {
    throw new Error()
  }
})

export const getJob = createAsyncThunk('jobs/getJob', async (id) => {
  try {
    const response = await getJobApi(id)
    return response?.data
  } catch {
    throw new Error()
  }
})

export const createJob = createAsyncThunk('jobs/createJob', async (payload) => {
  try {
    const response = await createJobApi(payload)
    message.success('Job is created successfully')
    return response?.data
  } catch {
    message.error('Cannot create job. Please try again')
    throw new Error()
  }
})

export const updateJob = createAsyncThunk(
  'jobs/updateJob',
  async (payload, thunkApi) => {
    try {
      const response = await updateJobApi(payload.data, payload.id)
      message.success('Job is updated successfully')
      thunkApi.dispatch(getJobs(payload.params))
      return response?.data
    } catch {
      message.error('Cannot update job. Please try again')
      throw new Error()
    }
  }
)

export const getEmails = createAsyncThunk('jobs/getEmails', async (id) => {
  try {
    const response = await getJobEmailsApi(id)
    return response?.data
  } catch {
    throw new Error()
  }
})

export const cloneJob = createAsyncThunk(
  'jobs/cloneJob',
  async (payload, thunkApi) => {
    try {
      const response = await cloneJobApi(payload.id)
      const title = response.newJob.title
      message.success(`Job cloned successfully, ${title} is created`)
      thunkApi.dispatch(getJobs(payload.params))
      return response
    } catch {
      message.error(
        'Cannot clone job. Please edit the job you just copied previously or try again'
      )
      throw new Error()
    }
  }
)
