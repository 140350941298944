import { Modal } from 'antd'
import Cookies from 'js-cookie'

export const handleExpiredToken = (error) => {
  const errorMessage = error.response.data.error

  //handle expire token, unauthorized
  if (errorMessage.includes('expire')) {
    Cookies.remove('userName')
    Cookies.remove('userToken')
    Cookies.remove('userPic')

    Modal.error({
      title: 'Accessibility Expired',
      content: 'Sorry, please login again!',
      onOk() {
        window.location.reload()
      }
    })
  }
}
