import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  .ant-select {
    width: 10rem;
    .ant-select-selector {
      background-color: white;
    }
  }

  .ant-picker {
    width: 10rem;
    background-color: white;
  }

  .ant-input-affix-wrapper {
    width: 15rem;
    background: white;
    input {
      background: white;
    }
  }
`
export const DivFilter = styled.div`
  display: flex;
  gap: 0.5rem;
`
