import React from 'react'
import { Routes, Route } from 'react-router-dom'

import PrivateRoutes from '@/components/AuthRoutes/PrivateRoutes'
import PublicRoutes from '@/components/AuthRoutes/PublicRoutes'
import Dashboard from '@/pages/Dashboard'
import SignIn from '@/pages/SignIn'
import ApplicantsDatabase from '@/pages/ApplicantsDatabase'
import Candidate from '@/pages/Candidate'
import Managers from '@/pages/Managers'
import Error404 from '@/pages/Error404'
import Jobs from '@/pages/Jobs'
import JobForm from '@/pages/JobForm'
import Event from '@/pages/Event'
import Events from '@/pages/Events'
import JobView from '@/pages/JobView'
import HiringPipelines from './pages/HiringPipelines'
import HiringPipelineStatus from './pages/HiringPipelineStatus'
import ShowAttachment from '@/pages/ShowAttachment'

import './App.less'

function App() {
  return (
    <Routes>
      <Route element={<PublicRoutes />}>
        <Route path="/" element={<SignIn />} />
        <Route path="/attachment/*" element={<ShowAttachment />} />
      </Route>

      <Route element={<PrivateRoutes />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/applicant-database" element={<ApplicantsDatabase />} />
        <Route path="/candidate/:id" element={<Candidate />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/managers" element={<Managers />} />
        <Route path="/job/create" element={<JobForm />} />
        <Route path="/job/edit/:jobId" element={<JobForm />} />
        <Route path="/job/:jobId" element={<JobView />} />
        <Route path="/event/create" element={<Event />} />
        <Route path="/event/edit/:id" element={<Event />} />
        <Route path="/events" element={<Events />} />
        <Route path="/manage-pipelines" element={<HiringPipelines />} />
        <Route
          path="/manage-pipelines/:id"
          element={<HiringPipelineStatus />}
        />
      </Route>

      <Route path="*" element={<Error404 />} />
    </Routes>
  )
}

export default App
