import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'

import PageLayout from '@/layouts/Default'
import {
  ContentContainer,
  DetailsContainer,
  HeaderContainer
} from '@/containers/JobView'
import { actions } from '@/redux/jobs/slice'
import { ContentWrapper, Tabs, TabText } from './styles'

const JobView = () => {
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    return () => {
      dispatch(actions.resetState())
    }
  }, [dispatch])

  return (
    <PageLayout>
      <HeaderContainer />

      <Tabs>
        <Tabs.TabPane tab={<TabText>Job Detail</TabText>} key={1}>
          <ContentWrapper>
            <ContentContainer />
            <DetailsContainer />
          </ContentWrapper>
        </Tabs.TabPane>
      </Tabs>
    </PageLayout>
  )
}

export default JobView
