import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  gap: 1.25rem;
`

export const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 0.125rem;
  margin-bottom: 0.25rem;
`