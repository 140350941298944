import { createAsyncThunk } from '@reduxjs/toolkit'

import { getDashboardPipelineApplications } from '@/apis'

export const getDataForPipeline = createAsyncThunk(
  'hiringPipelineSummary/getDataForPipeLine',
  async (payload) => {
    try {
      const response = await getDashboardPipelineApplications(payload)
      return response
    } catch {
      throw new Error()
    }
  }
)
