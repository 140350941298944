import styled from 'styled-components'
import { Card as AntdCard, Avatar as AntdAva } from 'antd'

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  grid-auto-flow: row;
  max-height: calc(100vh - 18.5rem);
  overflow: auto;
  
  @media (min-width: 2000px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1440px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

export const Card = styled(AntdCard)`
  strong {
    display: block;
  }

  .ant-tag {
    border-radius: 8px;
    border: none;
    padding: 0.5rem 0.75rem;
  }
`

export const Status = styled.span`
  color: ${(props) => props.statuscolor};
  position: absolute;
  top: 0.75rem;
`

export const Avatar = styled(AntdAva)`
  display: block;
  background: #87d068;
  filter: grayscale(${(props) => props.statuscolor});
  margin: auto;
  margin-top: 0.5rem;
`

export const MainInfo = styled.main`
  text-align: center;
  margin-top: 1rem;
`

export const Footer = styled.footer`
  background: #f6f8fa;
  text-align: center;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
  padding: ${(props) => props.padding};
`

export const EmptyOrLoading = styled.div`
  width: 100%;
  text-align: center;
`

export const StatusText = styled.div`
  font-weight: 700;
  font-size: 0.625rem;
  line-height: 0.75rem;
  text-transform: uppercase;
  color: ${(props) => props.color};
`
