import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import Card from '../Card'
import { JobCardsSection, NoJobText } from './styles'
import useJobCounters from '@/hooks/useJobCounters'

const JobCards = ({
  isLoading,
  jobPosts,
  updateJob,
  cloneJob,
}) => {
  const { hashTable } = useJobCounters()

  if (!isLoading && !jobPosts?.length)
    return (
      <NoJobText>
        <ExclamationCircleOutlined />
        No Job Found
      </NoJobText>
    )

  return (
    <Spin spinning={isLoading}>
      <JobCardsSection>
        {jobPosts?.map((job) => {
          const total = hashTable[job.title]?.[job.country]

          return (
            <Card
              job={job}
              key={job?._id}
              total={total}
              updateJob={updateJob}
              cloneJob={cloneJob}
            />
          )
        })}
      </JobCardsSection>
    </Spin>
  )
}

JobCards.propTypes = {
  jobPosts: PropTypes.array,
  groupedApplications: PropTypes.object,
  isLoading: PropTypes.bool,
  isApplicationsLoading: PropTypes.bool,
  updateJob: PropTypes.func
}

JobCards.defaultProps = {
  jobPosts: [],
  groupedApplications: {},
  isLoading: false,
  isApplicationsLoading: false,
  updateJob: () => {}
}

export default JobCards
