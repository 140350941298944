import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  signInUser as signInUserAPI,
  signInByGoogle as signInByGoogleAPI
} from '@/apis/auth'

export const signIn = createAsyncThunk(
  'authentication/signin',
  async (formData) => {
    try {
      const response = await signInUserAPI(formData)
      const data = response.data

      return data
    } catch (error) {
      throw new Error()
    }
  }
)

export const signInGoogle = createAsyncThunk(
  'authentication/googleSignIn',
  async (googleData) => {
    try {
      const response = await signInByGoogleAPI(googleData)
      const data = response.data

      return data
    } catch (error) {
      throw new Error(error)
    }
  }
)
