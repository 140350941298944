import React from 'react'
import Layout from '@/layouts/Default'
import Header from '@/components/Managers/Header'
import CardsManager from '@/containers/Managers/Cards'
import SectionSort from '@/components/Managers/SectionSort'
import Filters from '@/components/Managers/SectionFilter'
import { SectionMain } from './styles'

const Managers = () => {
  return (
    <Layout>
      <Header />

      <SectionMain>
        <div>
          <SectionSort />
          <CardsManager />
        </div>
        <Filters />
      </SectionMain>
    </Layout>
  )
}

export default Managers
