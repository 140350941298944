import React, { useMemo } from "react"
import moment from "moment/moment"
import { Skeleton } from "antd"
import Country from "../../Texts/Country"
import SectionFiles from "@/components/SectionAttachmentFiles"
import ExtraInformation from "@/components/Metadata/ExtraInformation"
import { Section, Header, Title, ProfileInfo } from "./styles"
// import defaultUserPic from '@/assets/defaultUserAvatar.jpeg'

const SectionGeneralInfo = ({ data, loading }) => {
  // const displayPicture = data?.profilePic ? data.profilePic : defaultUserPic

  const job = useMemo(() => {
    return data.job || {}
  }, [data.job])

  const displayInfoOrEmpty = (data) => {
    if (data) {
      return data
    }
    return "N/A"
  }

  return (
    <Section>
      <Header>
        <Skeleton loading={loading} active>
          {data.freelancer && (
            <React.Fragment>
              <Title>{data.freelancer.fullName}</Title>

              <ExtraInformation
                items={[
                  <Country name={data.freelancer.country} showCode />,
                  data.email,
                ]}
              />
            </React.Fragment>
          )}
        </Skeleton>
      </Header>

      <ProfileInfo>
        <Skeleton loading={loading} active>
          <strong>Job Position:</strong>
          <span>{displayInfoOrEmpty(job.title)}</span>
          <strong>Job Code:</strong>
          <span>{displayInfoOrEmpty(job.code)}</span>
          <strong>Salary Expectation:</strong>
          <span>{displayInfoOrEmpty(data.expectedSalary)}</span>
          <strong>Notice Period:</strong>
          <span>{displayInfoOrEmpty(data.noticePeriod)}</span>
          <strong>Submit Date:</strong>
          <span>
            {moment(displayInfoOrEmpty(data.submitDate)).format("MM/DD/YYYY")}
          </span>
        </Skeleton>
      </ProfileInfo>

      {data.freelancer && <SectionFiles data={data} />}
    </Section>
  )
}

export default SectionGeneralInfo
