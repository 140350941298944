import React, { useMemo, useState, useEffect } from 'react'
import { Button, Tooltip, Spin } from 'antd'
import ModalConfirm from '@/components/ModalConfirm'
import {
  Section,
  Header,
  DivDisplayStatus,
  DivRight,
  DivStages,
  DivStatus,
  DivContainer
} from './styles'
import { ArrowRightOutlined } from '@ant-design/icons'
import RectangleStart from '../../../assets/DynamicHP/RectangleStart.svg'
import RectangleSteps from '../../../assets/DynamicHP/RectangleSteps.svg'
import RectangleOffer from '../../../assets/DynamicHP/RectangleOffer.svg'
import RectangleUnselect from '../../../assets/DynamicHP/RectangleUnselect.svg'
import RectangleReject from '../../../assets/DynamicHP/RectangleReject.svg'
import useApplication from '@/hooks/useApplication'

const Element = ({ data, loading }) => {
  const { onUpdate } = useApplication()
  const [newStatus, setNewStatus] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [match, setMatch] = useState(0)

  const statuses = useMemo(() => {
    if (data.job && data.job.hiringPipeline) {
      return data.job.hiringPipeline.statuses
    }
    return []
  }, [data.job])

  const displayLoading = () => {
    return (
      <div>
        <Spin />
      </div>
    )
  }

  useEffect(() => {
    setNewStatus(data.status)
  }, [data.status])

  useEffect(() => {
    //find index of current status in hiring pipeline
    const activeStatusIndex = statuses.findIndex((e) => e.name === newStatus)
    setMatch(activeStatusIndex)
  }, [newStatus, statuses])

  /**
   * Display Confirmation Modal
   */
  const handleClickSave = () => {
    setOpenModal(true)
  }
  const handleOk = () => {
    onUpdate({ status: newStatus })
    setOpenModal(false)
  }

  const displayHiringStages = () => {
    if (!data.job || !data.job.hiringPipeline) {
      return []
    }

    const statuses = data.job.hiringPipeline.statuses

    let zIndex = statuses.length
    let icon = RectangleStart

    let result = statuses.map((status, index) => {
      if (index > match) {
        icon = RectangleUnselect
      }
      if (index === match) {
        icon = RectangleSteps
        if (status.name.toUpperCase() === 'OFFER') {
          icon = RectangleOffer
        }
        if (status.name.toUpperCase() === 'REJECT') {
          icon = RectangleReject
        }
      }

      return (
        <Tooltip title={status.name} key={index}>
          <DivStatus
            zindex={zIndex--}
            picture={icon}
            onClick={() => setNewStatus(status.name)}
          />
        </Tooltip>
      )
    })

    return result
  }

  const displayNewStatus = () => {
    return (
      <DivRight>
        <ArrowRightOutlined />
        <div>
          <span>New status:</span>
          <h4>{newStatus}</h4>
        </div>
      </DivRight>
    )
  }

  return (
    <Section>
      <Header>
        <DivDisplayStatus>
          <div>
            <span>Current status:</span>
            <h4>{data.status}</h4>
            {loading && displayLoading()}
          </div>
          {data.status !== newStatus && displayNewStatus()}
        </DivDisplayStatus>

        <Button type="primary" onClick={handleClickSave}>
          Save changes
        </Button>
      </Header>

      <span>Click one to change the status</span>
      <DivContainer>
        <DivStages totalStatus={statuses.length}>
          {data.status && displayHiringStages()}
          {loading && displayLoading()}
        </DivStages>
      </DivContainer>

      <ModalConfirm
        openModal={openModal}
        title="Saving changes"
        message="Are you sure you want to save the changes for this applicant?"
        handleOk={handleOk}
        handleCancel={() => setOpenModal(false)}
      />
    </Section>
  )
}

export default Element
