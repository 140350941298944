import { useSearchParams } from "react-router-dom"
import { useAppSelector, useAppDispatch } from "@/redux/store"
import { setIsLoading, setDataSource, setTotal } from "@/redux/applications"
import { find } from "@/apis/applications"

const useApplications = () => {
  const [params] = useSearchParams()
  const dispatch = useAppDispatch()
  const state = useAppSelector((state) => state.applications)

  const refetch = () => {
    dispatch(setIsLoading(true))

    find(params)
      .then((res) => {
        dispatch(setDataSource(res.data))
        dispatch(setTotal(res.total))
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

  return {
    isLoading: state.isLoading,
    dataSource: state.dataSource,
    total: state.total,
    refetch,
  }
}

export default useApplications
