import { useAppSelector, useAppDispatch } from "@/redux/store"
import { setItem } from "@/redux/jobCounters"
import { findOne } from "@/apis/jobCounters"

const useJobCounter = () => {
  const state = useAppSelector((state) => state.jobCounters)
  const dispatch = useAppDispatch()

  const setJobCounter = (data) => {
    dispatch(setItem(data))
  }

  const onFindOne = (id) => {
    return findOne(id)
  }

  return {
    id: state.item && state.item._id,
    selectedItem: state.item,
    setJobCounter,
    onFindOne,
  }
}

export default useJobCounter
