import { Link } from 'react-router-dom'
import { Button } from 'antd'
import Metadata from '@/components/Metadata'
import { HeaderWrapper } from './styles'

const Header = ({ total }) => {
  return (
    <HeaderWrapper>
      <Metadata
        title="Events List"
        breadcrumbItems={[
          { link: '/dashboard', title: 'DASHBOARD' },
          { link: '#', title: 'Events List' }
        ]}
        infoItems={[`Total Events: ${total}`]}
      />

      <Button type="primary">
        <Link to="/event/create">Create Event</Link>
      </Button>
    </HeaderWrapper>
  )
}

export default Header
