import React from 'react'
import { Link } from 'react-router-dom'
import useCardsLogic from '../../../hooks/HiringPipelines/Cards/index'
import ModalConfirm from '../../../components/ModalConfirm'
import { Button, Space, Input, Skeleton, Spin } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Section,
  InputTitle,
  CardSection,
  Card,
  CardAdd,
  CardLoading,
  SectionTitle,
  SectionCardHeader
} from './styles'

const Element = () => {
  const {
    loading,
    pipelineName,
    pipelineError,
    arrayStatus,
    handleOnChangeTitle,
    handleAddStatus,
    handleOnChangeStatus,
    handleClickSave,
    openModal,
    messageModal,
    handleClickDelete,
    handleOk,
    handleCancel
  } = useCardsLogic()

  const displayAddStatusCard = () => {
    return (
      <CardAdd>
        <h4>Add New Status</h4>
        <p>Status represent the steps in your hiring process</p>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={handleAddStatus}
        >
          New Status
        </Button>
      </CardAdd>
    )
  }

  /**
   * @returns Confirm Modal when Delete status is clicked
   */
  const displayConfirmModal = () => {
    return (
      <ModalConfirm
        icon="warning"
        openModal={openModal}
        message={messageModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    )
  }

  return (
    <Section>
      <SectionCardHeader>
        <SectionTitle>
          <strong>Pipeline Title:</strong>

          <InputTitle
            value={pipelineName}
            status={pipelineError}
            onChange={handleOnChangeTitle}
          />
        </SectionTitle>

        <Space>
          <Link to="/manage-pipelines">
            <Button>Cancel</Button>
          </Link>

          <Button type="primary" onClick={handleClickSave}>
            Save
          </Button>
        </Space>
      </SectionCardHeader>

      <div>
        <CardSection>
          {loading && (
            <CardLoading>
              <h4>loading cards...</h4>
              <br />
              <Spin size="large" />
            </CardLoading>
          )}
          {arrayStatus &&
            arrayStatus.map((status, index) => (
              <Card
                key={index}
                actions={[
                  <DeleteOutlined
                    style={{ color: 'red' }}
                    key="delete"
                    onClick={() => handleClickDelete(status._id, status.name)}
                  />
                ]}
              >
                <Skeleton loading={loading}>
                  <strong>Status:</strong>
                  <Input
                    name="name"
                    value={status.name}
                    onChange={handleOnChangeStatus(index)}
                  />
                  <strong>Estimate Time:</strong>
                  <Input
                    name="estimateTime"
                    value={status.estimateTime}
                    onChange={handleOnChangeStatus(index)}
                  />
                  <strong>Email:</strong>
                  <Input
                    name="email"
                    value={status.email}
                    onChange={handleOnChangeStatus(index)}
                  />
                </Skeleton>
              </Card>
            ))}
          {/* add status card at the end */}
          {displayAddStatusCard()}
        </CardSection>
        {displayConfirmModal()}
      </div>
    </Section>
  )
}

export default Element
