import { createAsyncThunk } from '@reduxjs/toolkit'

import { getEventsCount as getEventsCountApi } from '@/apis'

export const getEventsCount = createAsyncThunk(
  'header/getEventsCount',
  async () => {
    try {
      const response = await getEventsCountApi()
      return response?.data
    } catch {
      throw new Error()
    }
  }
)
