import styled from 'styled-components'

export const EventTitle = styled.a`
  cursor: pointer;
`

export const EventsListContainer = styled.section`
  background: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
`
