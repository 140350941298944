import { POST } from '@/services/axios'
import { message } from 'antd'

const AUTHENTICATION_API_PREFIX = `${process.env.REACT_APP_BACKEND_API_URL}/api/v2/auth`

export const signInUser = async (formData) => {
  const url = `${AUTHENTICATION_API_PREFIX}/login`

  const data = await POST(url, formData).catch((error) => {
    const errorMessage = error.response.data.error

    message.error(errorMessage)
  })

  return data
}

export const signInByGoogle = async (googleData) => {
  const url = `${AUTHENTICATION_API_PREFIX}/login-by-google`

  const data = await POST(url, googleData).catch((error) => {
    const errorMessage = error.response.data.error

    message.error(errorMessage)
  })

  return data
}
