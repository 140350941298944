import { GET, PATCH } from '@/services/axios'

const BASE_URL = `${process.env.REACT_APP_BACKEND_API_URL}/api/v2`

export const find = async (params) => {
  const url = `${BASE_URL}/applications`
  const { data } = await GET(url, params)
  return data
}

export const findOne = async (id) => {
  const url = `${BASE_URL}/applications/${id}`
  const { data } = await GET(url)
  return data
}

export const update = async (id, formData) => {
  const url = `${BASE_URL}/applications/${id}`
  const { data } = await PATCH(url, formData)
  return data
}

export const getApplicationsTotal = async (params) => {
  const url = `${BASE_URL}/applications/HP-header/total`
  const { data } = await GET(url, params)
  return data
}

export const exportApplications = async (params) => {
  const url = `${BASE_URL}/applications/export`
  const { data } = await GET(url, params)
  return data
}

export const getDashboardPipelineApplications = async (params) => {
  const url = `${BASE_URL}/applications/dashboard/hiring-pipeline`
  const { data } = await GET(url, params)
  return data
}

export const getApplicationLogs = async (id) => {
  const url = `${BASE_URL}/applications/${id}/history`
  const { data } = await GET(url)
  return data
}

export const getDefaultGroupedApplicantDatabase = async (params) => {
  const url = `${BASE_URL}/applicant-database/default-grouped-pipelines`
  const { data } = await GET(url, params)
  return data
}
