import { POST, PATCH, GET } from '@/services/axios'

const EVENT_API = `${process.env.REACT_APP_BACKEND_API_URL}/api/v2/event`

export const createEvent = async (payload) => {
  const { data } = await POST(EVENT_API, payload)
  return data
}

export const updateEvent = async (payload, id) => {
  const url = `${EVENT_API}/${id}`
  const { data } = await PATCH(url, payload)
  return data
}

export const getEvent = async (id) => {
  const url = `${EVENT_API}/${id}`
  const { data } = await GET(url)
  return data
}

export const getEvents = async () => {
  const { data } = await GET(EVENT_API)
  return data
}

export const getEventsCount = async () => {
  const { data } = await GET(`${EVENT_API}/count`)
  return data
}
