import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import Metadata from '@/components/Metadata'
import Country from '@/components/Texts/Country'
import { Header } from './styles'
import { COUNTRIES } from '@/utils/constants'

const Element = ({ data }) => {
  const [params] = useSearchParams()

  const title = useMemo(() => {
    if (params.has('jobTitle')) {
      return params.get('jobTitle')
    }

    return `${data.numberOfJobs} Jobs Post(s)`
  }, [params, data])

  const availablePlaceText = useMemo(() => {
    if (params.has('jobCountry')) {
      const name = params.get('jobCountry')
      const country = COUNTRIES.find((country) => country.value === name)
      return (
        <>
          {country ? <Country name={country.value} showCode={false} /> : name}
        </>
      )
    }

    return `Open in ${data.numberOfCountries} Countries`
  }, [params, data])

  const backTo = useMemo(() => {
    if (params.has('dashboardPage')) {
      return `/dashboard?dashboardPage=${params.get('dashboardPage')}`
    }

    return '/dashboard'
  }, [params])

  return (
    <Header>
      <Metadata
        title={title}
        backTo={backTo}
        breadcrumbItems={[
          { link: '/dashboard', title: 'DASHBOARD' },
          { link: '#', title: 'APPLICANTS DATABASE' }
        ]}
        infoItems={[
          availablePlaceText,
          `Total Applicants: ${data.numberOfProfiles}`
        ]}
      />
    </Header>
  )
}

Element.propTypes = {
  data: PropTypes.shape({
    numberOfJobs: PropTypes.any,
    numberOfCountries: PropTypes.any,
    numberOfProfiles: PropTypes.any
  })
}

Element.defaultProps = {
  data: { numberOfJobs: 0, numberOfCountries: 0, numberOfProfiles: 0 }
}

export default Element
