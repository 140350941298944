import styled from 'styled-components'
import { Button as AntButton } from 'antd'

export const Section = styled.section`
  > strong {
    display: block;

    color: #272d4e;
    font-weight: 500;

    margin: 1rem 0 0.5rem 0;
  }
`

export const Button = styled(AntButton)`
  font-weight: 500;
  background-color: #e5eaf4;
  border-radius: 1rem;
  margin: 0.2rem;
`
