import styled from 'styled-components'
import { Button } from 'antd'

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;

  .left-section {
    display: ${(props) => (props.isEdit ? 'flex' : 'block')};

    h1 {
      display: inline-block;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.875rem;
      color: #272d4e;
      margin-bottom: 0;
      margin-left: 2.5rem;
    }
  }


  .right-section {
    button {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }

    a {
      margin-right: 1rem;
    }
  }
`

export const EditHeader = styled.div`
  margin-left: 2.5rem;

  .status-row {
    margin-bottom: 0.5rem;

    .title {
      font-weight: 700;
      font-size: 0.75rem;
      line-height: 1rem;
      letter-spacing: 1px;
      text-decoration-line: underline;
      text-transform: uppercase;
      color: #878981;
      margin-right: 0.25rem;
    }

    .status {
      background: ${(props) => props.bgColor};
      color: ${(props) => props.txtColor};
      text-transform: uppercase;
      border-radius: 8px;
      padding: 0.125rem 0.5rem;
    }
  }
`

export const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 0.125rem;
  margin-bottom: 0.25rem;
`

export const CloseButton = styled(Button)`
  margin-right: 1rem;
`
