import styled from 'styled-components'
import { Button } from 'antd'

export const AntButtonGoogle = styled(Button)`
  width: 100%;
  height: auto;
  border-radius: 0.5rem;
  padding: 0.75rem 1rem;

  img {
    width: auto;
    height: 1.375rem;
    margin-right: 2rem;
  }
`
