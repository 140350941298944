import styled from 'styled-components'

export const DetailsContainer = styled.section`
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  padding: 1.5rem;

  h4 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #272d4e;
    margin-bottom: 1rem;
  }

  h6 {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #878981;
    margin-bottom: 0.5rem;

    &:not(:first-child) {
      margin-top: 1.5rem;
    }
  }
`

export const Value = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #23262f;
`

export const Flexline = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  img {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
  }
`

export const WorkRemote = styled.div`
  .icon {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
  }

  .text-content {
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #a3a3a3;
    margin-left: 0.5rem;
  }
`
