import { useEffect, useState } from 'react'
import { getDepartments } from '../../apis'

const useFetchDepartment = () => {
  const [loading, setLoading] = useState(false)
  const [departmentList, setDepartmentList] = useState([])

  /**
   * call API to get all job data
   * then filter them for all existing deparments
   */
  useEffect(() => {
    setLoading(true)
    getDepartments().then((data) => {
      setDepartmentList(data.categories)
      setLoading(false)
    })
  }, [])

  return {
    loading,
    departmentList
  }
}

export default useFetchDepartment
