import styled, { css } from 'styled-components'
import { Button } from 'antd'

export const Wrapper = styled.div`
  align-self: start;
  position: sticky;
  top: 0;
`

export const SideBarContainer = styled.nav`
  width: ${(props) => (props.isClose ? '4.5rem' : '15rem')};
  background: #ffffff;
  box-shadow: 0.125rem 0 0.125rem #d9d9d9;
  z-index: 1;
  transition: width 0.5s;
  height: 100vh;

  img {
    width: auto;
    height: 1.5rem;
    margin: 1.25rem 1.5rem 1.5rem;
  }

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const ItemSelected = css`
  border-right: 0.315rem solid #5695a6;
  font-weight: 700;
`

export const SideBarItem = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition-timing-function: ease;

  ${(props) => (props.isActive ? `${ItemSelected}` : '')};

  .anticon {
    font-size: 1.25rem;
  }

  &:hover {
    background: ${(props) => (props.isSidebarClose ? '#f2f2f2' : '#ffffff')};
    .text {
      color: #5695a6;
    }
  }

  .text {
    white-space: nowrap;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${(props) => (props.isActive ? '#5695a6' : '#183b57')};

    opacity: ${(props) => (props.isSidebarClose ? 0 : 1)};
    visibility: ${(props) => (props.isSidebarClose ? 'hidden' : '')};
    transition: opacity 1s ease-in-out;
  }
`

export const SideBarSubItem = styled.div`
  display: block;
  padding: 1rem 0 1rem 2rem;
  max-width: 8.5rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${(props) => (props.isActive ? '#5695a6' : '#183b57')};
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${(props) => (props.isClose ? 'center' : 'end')};

  .anticon {
    width: fit-content;
    border: 1px solid rgb(138, 186, 92);
    border-radius: 3px;
    padding: 0.5rem;
    display: flex;
    color: rgb(138, 186, 92);
    cursor: pointer;
  }
`

export const ButtonWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid #f5f5f5;
  padding: 1rem 1.75rem;
`
export const DivLogo = styled.div`
  height: 2rem;
  transition: opacity 1s ease-in-out;
  background-image: url(${(props) => props.picture});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`
export const ButtonSideBar = styled(Button)`
  position: absolute;
  top: 1.25rem;
  right: -0.8rem;

  svg {
    font-size: 0.625rem;
    stroke: #595959;
    stroke-width: 10rem;
  }

  &:focus {
    border-color: #f0f0f0;
    color: #434343;

    svg {
      font-size: 0.625rem;
      stroke: #5695a6;
      stroke-width: 10rem;
    }
  }

  &:hover {
    border-color: #5695a6;
    color: #5695a6;

    svg {
      font-size: 0.625rem;
      stroke: #5695a6;
      stroke-width: 10rem;
    }
  }
`
