import PageLayout from '@/layouts/Default'
import { EventsListContainer, HeaderContainer } from '@/containers/Events'

const EventsPage = () => {
  return (
    <PageLayout>
      <HeaderContainer />
      <EventsListContainer />
    </PageLayout>
  )
}

export default EventsPage
