import { useMemo } from 'react'
import moment from 'moment'
import { Spin } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { LOG_TYPES } from '@/utils/constants'
import { removeHTMLTags } from '@/utils/helpers/functions'
import { groupLogsByDay } from '@/utils/helpers/logs'
import {
  Title,
  Section,
  Line,
  Tag,
  ActionText,
  SecondaryText,
  OldValue,
  NewValue,
  Description
} from './styles'

const ApplicationLogs = ({ data, isLoading }) => {
  const logsData = useMemo(() => {
    if (data) {
      return groupLogsByDay(data)
    }
    return {}
  }, [data])

  const getDescription = ({ fieldName, oldValue, newValue }) => {
    if (!oldValue) {
      return (
        <Description>
          {`Set ${fieldName} to`}
          <NewValue>{removeHTMLTags(newValue)}</NewValue>
        </Description>
      )
    }

    return (
      <Description>
        {`Change ${fieldName} from`}
        <OldValue>{removeHTMLTags(oldValue)}</OldValue>
        to
        <NewValue>{removeHTMLTags(newValue)}</NewValue>
      </Description>
    )
  }

  if (!isLoading && !data?.length) {
    return (
      <Title>
        <ExclamationCircleOutlined style={{ marginRight: '0.5rem' }} /> No Logs
        Data Found
      </Title>
    )
  }

  return (
    <Spin spinning={isLoading}>
      <section>
        {Object.values(logsData).map(({ values: data, label }, index) => {
          return (
            <div key={index}>
              <Title>{label}</Title>

              <Section>
                {data
                  ?.sort((a, b) => moment(b?.createdAt) - moment(a?.createdAt))
                  ?.map((log) => {
                    const { oldData, newData, manager, createdAt, _id } = log

                    const newKeys = newData ? Object.keys(newData) : []

                    return (
                      <>
                        {newKeys.map((key, index) => {
                          const logType =
                            oldData && Object.keys(oldData)?.includes(key)
                              ? LOG_TYPES.edited
                              : LOG_TYPES.added

                          return (
                            <Line key={`${_id}-${index}`}>
                              <div>
                                <Tag color={logType.bgColor}>
                                  <ActionText color={logType.txtColor}>
                                    {logType.label}
                                  </ActionText>
                                </Tag>

                                <SecondaryText>
                                  {manager?.fullName || 'Admin'}
                                </SecondaryText>

                                <SecondaryText>
                                  {moment(createdAt).format('H:mm:ss')}
                                </SecondaryText>
                              </div>

                              {getDescription({
                                fieldName: key,
                                oldValue: oldData?.[key],
                                newValue: newData[key]
                              })}
                            </Line>
                          )
                        })}
                      </>
                    )
                  })}
              </Section>
            </div>
          )
        })}
      </section>
    </Spin>
  )
}

export default ApplicationLogs
