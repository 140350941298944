import React, { useMemo } from 'react'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'
import { Button, Table, Space, Dropdown, Menu, Tooltip } from 'antd'
import InputSearch from '../../Filters/InputSearch'
import ModalConfirm from '../../../components/ModalConfirm'
import NameSort from '../../Filters/Selects/NameSort'
import useTableLogic from '../../../hooks/HiringPipelines/Table/index'
import useParamsPagination from '../../../utils/hooks/useParamsPagination'
import {
  Section,
  HeaderSection,
  DivStatus,
  DivStatusSection,
  DivJobsSection,
  ButtonJob,
  DivEdit,
  TextDelete
} from './styles'
import { COUNTRIES } from '../../../utils/constants'
import IconSetting from '../../../assets/DynamicHP/DynamicHPSetting.svg'
import RectangleStart from '../../../assets/DynamicHP/RectangleStart.svg'
import RectangleSteps from '../../../assets/DynamicHP/RectangleSteps.svg'
import RectangleOffer from '../../../assets/DynamicHP/RectangleOffer.svg'
import RectangleReject from '../../../assets/DynamicHP/RectangleReject.svg'

const Element = ({ selectPipeline = false }) => {
  const {
    loading,
    total,
    tableData,
    openModal,
    messageModal,
    handleClickDelete,
    handleOk,
    handleCancel
  } = useTableLogic()
  const [paginationParams, setPaginationParams] = useParamsPagination()

  const pagination = useMemo(() => {
    return {
      defaultPageSize: 10,
      defaultCurrent: 1,
      total: total,
      pageSize: paginationParams.pageSize || 10,
      current: paginationParams.pageIndex || 1,
      onChange: setPaginationParams,
      showSizeChanger: total > 10
    }
  }, [
    setPaginationParams,
    paginationParams.pageIndex,
    paginationParams.pageSize,
    total
  ])

  const dropdownMenu = (record) => {
    return (
      <Menu
        items={[
          {
            key: 'edit',
            label: (
              <Link to={`/manage-pipelines/${record.key}`}>
                <strong>Edit</strong>{' '}
              </Link>
            )
          },
          {
            key: 'delete',
            label: <TextDelete>Delete</TextDelete>,
            onClick() {
              handleClickDelete(record.key, record.name)
            }
          }
        ]}
      />
    )
  }

  const displayPipelines = (statuses) => {
    let zIndex = statuses.length

    const result = statuses.map((status, index) => {
      let icon = RectangleSteps
      let firstStep = '-1.5rem'

      switch (status.name.toUpperCase()) {
        case 'OFFER':
          icon = RectangleOffer
          break
        case 'REJECT':
          icon = RectangleReject
          break
        default:
          break
      }

      if (index === 0) {
        icon = RectangleStart
        firstStep = '0'
      }

      return (
        <DivStatus
          key={index}
          picture={icon}
          first={firstStep}
          zindex={zIndex--}
        >
          <span>{status.name}</span>
        </DivStatus>
      )
    })

    return result
  }

  const columns = [
    {
      title: 'Hiring Pipeline Details',
      dataIndex: 'pipelineDetails',
      key: 'pipelineDetails',
      render: (_, record) => (
        <Space direction="vertical">
          <strong>{record.name}</strong>
          <span>Pipeline contains: {record.statuses.length} statuses</span>
          <span>created on: {moment(record.date).format('MM-DD-YYYY')}</span>
          <DivStatusSection>
            {displayPipelines(record.statuses)}
          </DivStatusSection>
        </Space>
      )
    },
    {
      title: 'jobsUsed',
      width: '35%',
      key: 'jobsUsed',
      align: 'center',
      render: (_, record) => (
        <Space direction="vertical">
          <strong>Pipeline Applied in Jobs:</strong>
          <span>
            {record.jobsUse &&
              `Total jobs using this Pipeline: ${record.jobsUse.length} jobs`}
          </span>
          <DivJobsSection>
            {record.jobsUse?.map((job) => {
              const icon = COUNTRIES.find(
                (country) => country.label === job.country
              ) ? (
                <img
                  src={
                    COUNTRIES.find((country) => country.label === job.country)
                      .icon
                  }
                  alt="country flag"
                />
              ) : (
                <></>
              )

              return (
                <Tooltip title={`${job.title} - ${job.country}`}>
                  <Link to={`/job/${job._id}`}>
                    <ButtonJob icon={icon}>{job.title}</ButtonJob>
                  </Link>
                </Tooltip>
              )
            })}
          </DivJobsSection>
        </Space>
      )
    },
    {
      title: 'edit',
      width: selectPipeline ? '10%' : '5%',
      key: 'edit',
      align: 'center',
      render: (_, record) => (
        <DivEdit>
          {selectPipeline && <Button type="ghost">Use This</Button>}
          {!selectPipeline && (
            <Dropdown overlay={dropdownMenu(record)} trigger={['click']}>
              <img src={IconSetting} alt="setting icon" />
            </Dropdown>
          )}
        </DivEdit>
      )
    }
  ]

  return (
    <Section>
      {!selectPipeline && (
        <HeaderSection>
          <InputSearch paramKey="name" placeholder="Search by name" />
          <NameSort />
        </HeaderSection>
      )}

      <Table
        loading={loading}
        columns={columns}
        dataSource={tableData}
        showHeader={false}
        scroll={{ y: 600 }}
        pagination={pagination}
      />

      <ModalConfirm
        icon="warning"
        openModal={openModal}
        message={messageModal}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </Section>
  )
}

export default Element
