import { createAsyncThunk } from '@reduxjs/toolkit'

import { getManagers as getManagersApi } from '@/apis'

export const getManagers = createAsyncThunk(
  'managerList/getManagers',
  async (payload) => {
    try {
      const response = await getManagersApi(payload)
      return response?.data
    } catch {
      throw new Error()
    }
  }
)
