import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { message } from 'antd'
import SectionMain from '@/components/Candidate/SectionMain'
import useApplication from '@/hooks/useApplication'

const Container = () => {
  const { id } = useParams()
  const [params] = useSearchParams()
  const { onFindOne, onUpdate } = useApplication()
  const [loading, setLoading] = useState(false)
  const [profileInfo, setProfileInfo] = useState({})

  useEffect(() => {
    setLoading(true)

    onFindOne(id).then((data) => {
      setProfileInfo(data)
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, params])

  const updateNotes = (value) => {
    if (id === profileInfo._id && profileInfo.notes !== value) {
      onUpdate({ id, notes: value })
        .then(() => {
          message.success('Notes updated')
        })
        .catch((err) => {
          message.error(`Error when updating notes ${err}`)
        })
    }
  }

  return (
    <SectionMain
      loading={loading}
      data={profileInfo}
      updateNotes={updateNotes}
    />
  )
}

export default Container
