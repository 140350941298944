import React from 'react'
import InputSearch from '../../Filters/InputSearch'
import { Wrapper, DivSort } from './styles'
import NameSort from '../../Filters/Selects/NameSort'

const Element = () => {
  return (
    <Wrapper>
      <InputSearch paramKey="fullName" placeholder="Search by manager name" />

      <DivSort>
        <span>Sort: </span>
        <NameSort />
      </DivSort>
    </Wrapper>
  )
}

export default Element
