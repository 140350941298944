window.userSnapLoaded = false

const data = {
  integrate: (user) => {
    const { _id, email } = user

    if (window.userSnapLoaded) {
      return false
    }

    window.userSnapLoaded = true

    let script = document.createElement('script')
    script.defer = 1
    script.src = `https://widget.usersnap.com/global/load/06273871-d6ed-4fd3-9498-31f0ef4952ab?onload=onUsersnapCXLoad`
    document.getElementsByTagName('head')[0].appendChild(script)
    

    window.onUsersnapCXLoad = (api) => {
      api.init({
        user: {
          userId: _id,
          email: email
        }
      })
    }
  }
}

export default data
