import React from 'react'
import { message } from 'antd'
import { updateManager, createManager } from '@/apis'
import ModalManager from '@/components/Managers/Modal'

const Container = ({ refetch, visible, data, handleClose }) => {

  const title = data ? 'Edit Manager Info' : 'Invite New Manager'

  const handleOk = (formData, id) => {
    if (id) {
      updateManager(id, formData).then(() => {
        message.success('Manager updated successfully!')
        refetch()
      })
    } else {
      createManager(formData).then((res) => {
        message.success('New Manager created successfully!')
        refetch()
      })
    }
  }

  return (
    <ModalManager
      visible={visible}
      title={title}
      data={data}
      handleClose={handleClose}
      handleOk={handleOk}
    />
  )
}

export default Container
