import styled from 'styled-components'

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;

  .right-section {
    a {
      &:not(:last-child) {
        margin-right: 1.5rem;
      }
    }
  }
`
