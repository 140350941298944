import React, { useState, useEffect } from "react"
import moment from "moment/moment"
import { message } from "antd"
import Country from "../../Texts/Country"
import ExtraInformation from "@/components/Metadata/ExtraInformation"
import ModalConfirm from "@/components/ModalConfirm"
import TextEditor from "@/components/RichTextEditor"
import SelectStatus from "./SelectStatus"
import Footer from "./Footer"
import SectionFiles from "@/components/SectionAttachmentFiles"
import useApplication from "@/hooks/useApplication"
import useApplications from "@/hooks/useApplications"
import {
  Modal,
  Content,
  SectionName,
  SectionInfo,
  SectionMessage,
  Title,
  ProfileInfo,
} from "./styles"

const Element = ({ open, handleClose }) => {
  const { id, selectedItem, onUpdate } = useApplication()
  const { refetch } = useApplications()
  const [formData, setFormData] = useState({ status: "", notes: "" })
  const [openConfimation, setOpenConfimation] = useState(false)

  useEffect(() => {
    if (id) {
      setFormData({
        status: selectedItem.status,
        notes: selectedItem.notes,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const onOk = () => {
    setOpenConfimation(true)
  }

  const handleConfirmOk = () => {
    setOpenConfimation(false)

    onUpdate(id, formData)
      .then(() => {
        message.success(`All changes are saved!`)
        refetch()
        handleClose()
      })
      .catch((err) => {
        message.error(`Error when updating notes ${err}`)
      })
  }

  return (
    <>
      <Modal
        visible={open}
        title="Applicant Profile"
        centered={true}
        width={1000}
        footer={<Footer onOk={onOk} />}
        onCancel={handleClose}
      >
        {selectedItem && (
          <Content>
            <SectionName>
              <div>
                <Title>{selectedItem.freelancer.fullName}</Title>
                <ExtraInformation
                  items={[
                    <Country name={selectedItem.freelancer.country} showCode />,
                    selectedItem.freelancer.email,
                  ]}
                />
              </div>

              <div>
                <span>Current status:</span> <br />
                {selectedItem && (
                  <SelectStatus
                    value={formData.status}
                    pipelineID={selectedItem.job.hiringPipeline}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        status: value,
                      })
                    }}
                  />
                )}
              </div>
            </SectionName>

            <SectionInfo>
              <ProfileInfo>
                <strong>Job Position:</strong>
                <span>{selectedItem.job.title}</span>
                <strong>Salary Expectation:</strong>
                <span>{selectedItem.expectedSalary}</span>
              </ProfileInfo>
              <ProfileInfo>
                <strong>Notice Period:</strong>
                <span>{selectedItem.noticePeriod}</span>
                <strong>Submit Date:</strong>
                <span>
                  {moment(selectedItem.submitDate).format("MM/DD/YYYY")}
                </span>
              </ProfileInfo>

              <SectionFiles data={selectedItem} />
            </SectionInfo>

            <SectionMessage>
              <strong>Messages</strong>
              <p>{selectedItem.message}</p>
            </SectionMessage>

            <SectionMessage>
              <strong>Notes</strong>
              <TextEditor
                value={formData.notes}
                onChange={(value) => {
                  setFormData({
                    ...formData,
                    notes: value,
                  })
                }}
              />
            </SectionMessage>
          </Content>
        )}
      </Modal>

      <ModalConfirm
        openModal={openConfimation}
        title="Saving changes"
        message="Are you sure you want to save the changes for this applicant?"
        handleOk={handleConfirmOk}
        handleCancel={() => setOpenConfimation(false)}
      />
    </>
  )
}

export default Element
