import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { JobContent } from '@/components/JobForm'

import { getJob } from '@/redux/jobs/thunk'

const JobContentContainer = ({ form }) => {
  const dispatch = useDispatch()
  const { job } = useSelector((state) => state.jobsReducer)
  const { jobId } = useParams()

  useEffect(() => {
    if (jobId) {
      dispatch(getJob(jobId))
    }
  }, [jobId, dispatch])

  return <JobContent form={form} data={job} isEdit={!!jobId} />
}

export default JobContentContainer
