import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, TextArea } from '@/components/UIKits'
import { TextEditor } from '@/components/JobForm/styles'
import { ContentContainer } from './styles'

const JobContent = ({ form, data, isEdit }) => {
  useEffect(() => {
    if (data && isEdit) {
      form.setFieldsValue({
        title: data.title || '',
        description: data.description || '',
        content: data.content || ''
      })
    }

    if (!isEdit) {
      form.setFieldsValue({
        title: null,
        description: null,
        content: ''
      })
    }
  }, [data, isEdit, form])

  return (
    <ContentContainer>
      <Form form={form} layout="vertical" requiredMark={false}>
        <Form.Item
          name="title"
          label="Job Title"
          rules={[
            {
              required: true,
              message: 'This is a required field'
            }
          ]}
        >
          <Input placeholder="Insert job title here" />
        </Form.Item>

        <Form.Item
          name="description"
          label="Job Description"
          rules={[
            {
              required: true,
              message: 'This is a required field'
            }
          ]}
        >
          <TextArea rows={4} placeholder="Insert a short description here" />
        </Form.Item>

        <Form.Item
          name="content"
          label="Job Content"
          rules={[
            {
              required: true,
              message: 'This is a required field'
            }
          ]}
        >
          <TextEditor placeholder="Insert job content here" />
        </Form.Item>
      </Form>
    </ContentContainer>
  )
}

JobContent.propTypes = {
  form: PropTypes.any.isRequired,
  data: PropTypes.object,
  isEdit: PropTypes.bool
}

JobContent.propTypes = {
  data: null,
  isEdit: false
}

export default JobContent
