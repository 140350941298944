import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import Table from "@/components/ApplicantsDatabase/Table"
import useApplications from "@/hooks/useApplications"

const Container = () => {
  const location = useLocation()
  const { dataSource, total, refetch } = useApplications()

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return <Table data={dataSource} total={total} />
}

export default Container
