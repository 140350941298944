import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .ant-modal-content,
  .ant-modal-footer,
  .ant-modal-body {
    border-radius: 1rem;
  }

  .ant-modal-body {
    background: #f6f8fa;
    overflow: auto;
  }
  .ant-modal-footer {
    background: #f6f8fa;

    .ant-btn.ant-btn-default {
      margin-right: 1rem;
    }
  }
`
export const Content = styled.div`
  height: 75vh;
`

export const Header = styled.header`
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  margin: 0.5rem 0;
  padding-bottom: 1.5rem;
  border-bottom: 1px dotted #d9d9d9;

  strong {
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 2rem;
    color: #183b57;
  }

  .ant-btn {
    border-color: #367e98;
    color: #367e98;

    &:hover {
      color: white;
      background: #367e98;
    }
  }
`

export const BodyModal = styled.div`
  height: 65vh;
  overflow: auto;
`

export const SectionName = styled.section`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  div.ant-select {
    width: 12rem;
  }
`

export const SectionInfo = styled.section`
  display: flex;
  justify-content: space-between;

  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  padding: 1rem;
  z-index: 1;
`
export const SectionMessage = styled.section`
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  margin-top: 1rem;
  padding: 1rem;
  z-index: 1;

  strong {
    display: block;
    color: #272d4e;
    font-weight: 700;
    font-size: 1.2rem;
    margin: 1rem 0;
  }
`

export const Title = styled.h3`
  color: #272d4e;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
`

export const ProfileInfo = styled.div`
  > strong {
    display: block;

    color: #272d4e;
    font-weight: 500;

    margin-top: 1rem;
  }
  > span {
    color: #252525;
    opacity: 0.7;
  }
`
