import React, { useEffect } from 'react'
import { Form, Skeleton } from 'antd'
import TextEditor from '@/components/RichTextEditor'
import { Wrapper, TextValue } from './styles'
import useApplication from '@/hooks/useApplication'

const SectionMain = ({ loading, data, updateNotes }) => {
  const { onUpdate } = useApplication()
  const [form] = Form.useForm()

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        notes: data.notes || ''
      })
    }
  }, [data, form])

  const onChange = (value) => {
    onUpdate(value)
  }

  return (
    <Wrapper>
      <Form
        form={form}
        onFinish={(formValues) => {
          updateNotes(formValues?.notes)
        }}
      >
        <strong>Message:</strong>
        <Skeleton loading={loading} active>
          <TextValue>{data.message || 'N/A'}</TextValue>
        </Skeleton>

        <strong>Notes:</strong>
        <Form.Item name="notes">
          <TextEditor onChange={onChange} />
        </Form.Item>
      </Form>
    </Wrapper>
  )
}

export default SectionMain
