import { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  createSearchParams,
  useNavigate,
  Link,
  useSearchParams
} from 'react-router-dom'
import { Tooltip, Pagination, Spin } from 'antd'

import {
  SortDescendingOutlined,
  SortAscendingOutlined
} from '@ant-design/icons'
import StepArrow from '@/assets/DynamicHP/RectangleSteps.svg'

import {
  HiringPipelineContainer,
  Content,
  Status,
  Scrollbar,
  CustomSelectJob as SelectJobTitle
} from './styles'

const paginate = ({ data, pageSize, pageNumber }) => {
  return data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

const HiringPipeline = ({ pipelineData, totalJobs, isLoading }) => {
  const [params, setParams] = useSearchParams()
  const [isAlphabeticalOrder, setIsAlphabeticalOrder] = useState(true)
  const [selectedTitles, setSelectedTitles] = useState([])
  const [page, setPage] = useState(1)
  const navigate = useNavigate()

  useEffect(() => {
    if (params.has('dashboardPage')) {
      setPage(parseInt(params.get('dashboardPage')))
    }
  }, [params])

  const sortedData = useMemo(() => {
    if (!pipelineData) return []

    const mappedData = Object.keys(pipelineData)
      .filter((key) => {
        if (selectedTitles.length) {
          return selectedTitles.includes(key)
        }

        return true
      })
      .reduce((prev, curr) => {
        return [
          ...prev,
          {
            ...pipelineData[curr],
            name: curr
          }
        ]
      }, [])

    const sortedByAlphabet = mappedData.sort((a, b) =>
      a.name.localeCompare(b.name)
    )

    const returnedData = isAlphabeticalOrder
      ? sortedByAlphabet
      : sortedByAlphabet.reverse()

    return paginate({ data: returnedData, pageSize: 6, pageNumber: page })
  }, [isAlphabeticalOrder, pipelineData, page, selectedTitles])

  const totalItems = Object.keys(pipelineData)?.filter((key) => {
    if (selectedTitles.length) {
      return selectedTitles.includes(key)
    }

    return true
  })?.length

  const handleUpdatePagination = (pageValue) => {
    setPage(pageValue)
    params.set('dashboardPage', pageValue)
    setParams(params)
  }

  return (
    <HiringPipelineContainer>
      <header>
        <h3>Hiring Pipeline</h3>

        <SelectJobTitle
          placeholder="Select Job Title"
          maxTagCount="responsive"
          mode="multiple"
          allowClear
          showSearch
          showArrow
          disabled={isLoading}
          onChange={(value) => setSelectedTitles(value)}
        />
      </header>

      <Spin spinning={isLoading} />

      {!isLoading && sortedData.length === 0 && (
        <div>There are no data</div>
      )}

      {!isLoading && sortedData.length > 0 && (
        <>
          <main>
            <div className="menu">
              <div
                className="sort"
                onClick={() => setIsAlphabeticalOrder(!isAlphabeticalOrder)}
              >
                {isAlphabeticalOrder ? (
                  <SortAscendingOutlined
                    style={{ color: '#183B57', opacity: 0.7 }}
                  />
                ) : (
                  <SortDescendingOutlined
                    style={{ color: '#183B57', opacity: 0.7 }}
                  />
                )}

                <div className="job-positions">Job Positions</div>
              </div>

              <div className="application">Applicants in Hiring Pipeline</div>
            </div>

            <Content>
              {sortedData.map((item, index) => {
                const { data, name } = item
                let zIndex = data.length

                const params = createSearchParams({
                  jobTitle: name
                }).toString()

                const url = `/applicant-database?${params}`

                return (
                  <div className="row" key={name}>
                    <div className="title">
                      <Tooltip title={name}>
                        <Link to={url}>
                          <div className="main-title">{name}</div>
                        </Link>
                      </Tooltip>
                    </div>

                    <Scrollbar>
                      <div className="pipeline">
                        {data
                          .filter(
                            (status) =>
                              status.name !== 'Offer' &&
                              status.name !== 'Reject'
                          )
                          .map((status, index) => {
                            const { total, name: statusName } = status

                            const params = createSearchParams({
                              jobTitle: name,
                              status: statusName,
                              dashboardPage: page
                            }).toString()

                            const url = `/applicant-database?${params}`

                            return (
                              <Tooltip key={index} title={statusName}>
                                <Status
                                  onClick={() => {
                                    navigate(url)
                                  }}
                                  bgImage={StepArrow}
                                  zIndex={zIndex--}
                                >
                                  <div>{total}</div>
                                </Status>
                              </Tooltip>
                            )
                          })}
                      </div>
                    </Scrollbar>
                  </div>
                )
              })}
            </Content>
          </main>

          <footer>
            <div className="total-job">Total {totalJobs} Job Positions</div>

            <Pagination
              size="small"
              defaultCurrent={1}
              current={page}
              pageSize={6}
              total={totalItems}
              onChange={handleUpdatePagination}
            />
          </footer>
        </>
      )}
    </HiringPipelineContainer>
  )
}

HiringPipeline.propTypes = {
  pipelineData: PropTypes.object,
  totalJobs: PropTypes.number,
  isLoading: PropTypes.bool
}

HiringPipeline.defaultProps = {
  pipelineData: {},
  totalJobs: 0,
  isLoading: false
}

export default HiringPipeline
