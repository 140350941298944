import { createSlice } from '@reduxjs/toolkit'

import { getJobsSummary } from '@/containers/Dashboard/JobSummary/thunk'

const initialState = {
  jobsSummary: {
    active: 0,
    inactive: 0,
    draft: 0,
    total: 0
  },
  isJobsSummaryLoading: false,
  isJobsSummaryError: false
}

const jobSummarySlice = createSlice({
  name: 'jobSummary',
  initialState,
  extraReducers: {
    [getJobsSummary.pending]: (state) => {
      state.isJobsSummaryError = false
      state.isJobsSummaryLoading = true
    },
    [getJobsSummary.fulfilled]: (state, action) => {
      state.isJobsSummaryError = false
      state.isJobsSummaryLoading = false
      state.jobsSummary = action.payload
    },
    [getJobsSummary.rejected]: (state) => {
      state.isJobsSummaryError = true
      state.isJobsSummaryLoading = false
    }
  }
})

const { reducer } = jobSummarySlice

export default reducer
