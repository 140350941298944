import React from 'react'
import { Modal } from 'antd'
import { ApplicationLogsContainer } from '@/containers/ChangeLogs'

const Elemennt = ({ visible, setVisible, profileId }) => {
  const onCancel = () => {
    setVisible(false)
  }

  return (
    <Modal
      title="Change Logs"
      visible={visible}
      width="75vw"
      footer={null}
      onCancel={onCancel}
    >
      {profileId && <ApplicationLogsContainer id={profileId} />}
    </Modal>
  )
}

export default Elemennt
