import NavBar from "./NavBar"
import SideBar from "./SideBar"

import { Content, Container } from "./styles"

const PageLayout = ({ children }) => {
  return (
    <Container>
      <SideBar />

      <div>
        <NavBar />
        
        <Content>{children}</Content>
      </div>
    </Container>
  )
}

export default PageLayout
