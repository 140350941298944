import { createAsyncThunk } from '@reduxjs/toolkit'

import { getJobsSummary as getJobsSummaryApi } from '@/apis'

export const getJobsSummary = createAsyncThunk(
  'jobSummary/getJobsSummary',
  async () => {
    try {
      const response = await getJobsSummaryApi()
      return response?.data
    } catch {
      throw new Error()
    }
  }
)
