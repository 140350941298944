import { combineReducers } from '@reduxjs/toolkit'

import authenUserReducer from './authenUser/slice'
import dashboardReducer from '@/containers/Dashboard/slice'
import eventReducer from '@/containers/Event/slice'
import eventsReducer from '@/containers/Events/slice'
import logsReducer from '@/containers/ChangeLogs/slice'
import jobsReducer from './jobs/slice'
import jobCountersReducer from './jobCounters'
import applicationsReducer from './applications'

const rootReducer = combineReducers({
  authenUserReducer,
  dashboardReducer,
  eventReducer,
  eventsReducer,
  logsReducer,
  jobsReducer,
  jobCounters: jobCountersReducer,
  applications: applicationsReducer
})

export default rootReducer
