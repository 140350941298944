import styled from 'styled-components'
import { SelectJobTitle } from '@/components/Selects'

export const HiringPipelineContainer = styled.section`
  overflow: auto;
  background-color: #ffffff;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  padding: 1.25rem;

  header {
    display: flex;
    align-items: center;
    gap: 1.875rem;
    border-bottom: 1.5px solid #f0f3f7;
    padding-bottom: 0.5rem;
    margin-bottom: 1.25rem;

    h3 {
      font-weight: 700;
      font-size: 1.25rem;
      color: #183b57;
      margin-bottom: 0;
    }
  }

  .menu {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 0.5rem;
    border-bottom: 1.5px solid #f0f3f7;
    padding: 1.25rem 0;

    .sort {
      display: flex;
      gap: 0.25rem;
      cursor: pointer;

      .job-positions {
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1rem;
        color: #183b57;
        opacity: 0.7;
      }
    }

    .application {
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1rem;
      color: #5d7689;
    }
  }

  footer {
    display: flex;
    align-items: center;
    float: right;

    .total-job {
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 0.875rem;
      color: #183b57;
      opacity: 0.7;
      margin-right: 1rem;
    }

    .ant-pagination {
      .ant-pagination-item,
      .ant-pagination-item-link,
      .ant-pagination-item-active {
        border: none;
      }

      .ant-pagination-item-active {
        background-color: #367f98;

        a {
          color: #ffffff;
        }
      }
    }
  }
`

export const Content = styled.main`
  margin: 1.25rem 0;

  .row {
    display: grid;
    grid-template-columns: 25% calc(75% - 0.5rem);
    grid-gap: 0.5rem;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    .title {
      .main-title {
        color: #272d4e;
        font-weight: 700;
        font-size: 0.875rem;
      }

      .sub-title {
        color: #272d4e;
        font-size: 0.75rem;
      }
    }

    .pipeline {
      display: grid;
      grid-template-columns: repeat(14, 5rem);
      padding-left: 1rem;
    }
  }
`

export const Status = styled.div`
  position: relative;
  background-image: url(${(props) => props.bgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  padding-top: 40%;
  margin-left: -1rem;
  cursor: pointer;
  z-index: ${(props) => props.zIndex};

  div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1rem;
    font-weight: 500;
  }
`

export const Scrollbar = styled.div`
  overflow-x: auto;
`

export const CustomSelectJob = styled(SelectJobTitle)`
  width: 8.75rem;

  .ant-select-selector {
    background-color: #f6f8fa !important;
    border: none !important;
    border-radius: none !important;
  }

  .ant-select-selection-placeholder {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: ;
    color: #183b57;
  }
`
