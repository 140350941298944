import React from 'react'
import Select from '../../../components/UIKits/Select/index'

const { Option } = Select

const Element = ({
  placeholder = 'Hiring Status',
  statusList,
  defaultStatus,
  handleOnChange
}) => {
  return (
    <Select
      value={defaultStatus}
      placeholder={placeholder}
      mode="multiple"
      maxTagCount="responsive"
      allowClear={true}
      onChange={handleOnChange}
      dropdownStyle={{ minWidth: '14rem' }}
    >
      {statusList &&
        statusList.map((status) => (
          <Option key={status} value={status}>
            {status}
          </Option>
        ))}
    </Select>
  )
}

export default Element
