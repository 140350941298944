import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumb } from './styles'

/**
 * @param {array} items object array [{link: <string>, title: <string>}]
 * @returns {ReactNode} Element
 */
const Element = ({ items = [] }) => {
  const mapData = () => {
    return items.map((item, index) => {
      if (index + 1 === items.length) {
        return <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
      }
      return (
        <Breadcrumb.Item key={index}>
          <Link to={item.link}>{item.title}</Link>
        </Breadcrumb.Item>
      )
    })
  }
  return <Breadcrumb>{mapData()}</Breadcrumb>
}

export default Element
