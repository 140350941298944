import { combineReducers } from '@reduxjs/toolkit'

import eventsListReducer from './EventsList/slice'
import headerReducer from './Header/slice'

const eventsReducer = combineReducers({
  eventsListReducer,
  headerReducer
})

export default eventsReducer
