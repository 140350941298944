import moment from 'moment'

export const groupLogsByTime = (logs) => {
  const data = logs.reduce(
    (prev, curr) => {
      const { createdAt } = curr
      const newValue = { ...prev }

      const isToday = moment(createdAt).isSame(moment().startOf('day'), 'day')
      const isYesterday = moment(createdAt).isSame(
        moment().subtract(1, 'days').startOf('day'),
        'day'
      )

      const isThisWeek = moment(createdAt).isSame(
        moment().startOf('day'),
        'week'
      )

      const isThisMonth = moment(createdAt).isSame(
        moment().startOf('day'),
        'month'
      )

      if (isToday) {
        newValue.today = [...prev.today, curr]
        return newValue
      }

      if (isYesterday) {
        newValue.yesterday = [...prev.yesterday, curr]
        return newValue
      }

      if (isThisWeek) {
        newValue.thisWeek = [...prev.thisWeek, curr]
        return newValue
      }

      if (isThisMonth) {
        newValue.thisMonth = [...prev.thisMonth, curr]
        return newValue
      }

      newValue.olderThanThisMonth = [...prev.olderThanThisMonth, curr]
      return newValue
    },
    {
      today: [],
      yesterday: [],
      thisWeek: [],
      thisMonth: [],
      olderThanThisMonth: []
    }
  )

  return {
    ...(data.today.length && {
      today: {
        values: data.today,
        label: 'Today'
      }
    }),
    ...(data.yesterday.length && {
      yesterday: {
        values: data.yesterday,
        label: 'Yesterday'
      }
    }),
    ...(data.thisWeek.length && {
      thisWeek: {
        values: data.thisWeek,
        label: 'This week'
      }
    }),
    ...(data.thisMonth.length && {
      thisMonth: {
        values: data.thisMonth,
        label: 'This month'
      }
    }),
    ...(data.olderThanThisMonth.length && {
      olderThanThisMonth: {
        values: data.olderThanThisMonth,
        label: 'Older than 1 month'
      }
    })
  }
}

export const groupLogsByDay = (logs) => {
  const data = logs.map((log) => {
    const { createdAt } = log

    const isToday = moment(createdAt).isSame(moment().startOf('day'), 'day')
    const isYesterday = moment(createdAt).isSame(
      moment().subtract(1, 'days').startOf('day'),
      'day'
    )

    let formatCreatedAt = () => {
      if (isToday) return 'Today'
      if (isYesterday) return 'Yesterday'

      return moment(createdAt).format('MM/DD/YY')
    }

    return {
      ...log,
      formattedCreatedAt: formatCreatedAt()
    }
  })

  return data
    .sort((a, b) => moment(b?.createdAt) - moment(a?.createdAt))
    .reduce((prev, curr) => {
      const { formattedCreatedAt } = curr

      const newData = { ...prev }

      if (Object.keys(prev).includes(formattedCreatedAt)) {
        newData[formattedCreatedAt] = {
          values: [...newData[formattedCreatedAt].values, curr],
          label: formattedCreatedAt
        }
      } else {
        newData[formattedCreatedAt] = {
          values: [curr],
          label: formattedCreatedAt
        }
      }

      return newData
    }, {})
}
