import { List } from 'antd'

import { EventTitle, EventsListContainer } from './styles'

const Events = ({ data }) => {
  return (
    <EventsListContainer>
      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <EventTitle href={`/event/edit/${item._id}`}>
              {item.name}
            </EventTitle>
          </List.Item>
        )}
      />
    </EventsListContainer>
  )
}

export default Events
