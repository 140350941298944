import styled from 'styled-components'
import { DatePicker as AntdDatePicker } from 'antd'

export const EventFormWrapper = styled.section`
  background: #ffffff;
  border-radius: 8px;
  padding: 1.5rem;
`

export const DatePicker = styled(AntdDatePicker)`
  width: 100%;
`
