import styled from 'styled-components'
import { Dropdown as AntDropdown } from 'antd'
import { EllipsisOutlined as Icon } from '@ant-design/icons'

export const Dropdown = styled(AntDropdown)`
  position: absolute;
  right: 1rem;
  top: 0.75rem;
`

export const EllipsisOutlined = styled(Icon)`
  cursor: pointer;
  color: #bac5dc;
  height: 1rem;
  font-size: 1.875rem;
`

export const TextDelete = styled.span`
  color: red;
`
export const TextActivate = styled.span`
  color: green;
`
