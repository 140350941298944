import PropTypes from 'prop-types'
import moment from 'moment'

import { JOB_TYPES, COUNTRIES, EXPERIENCE_YEARS } from '@/utils/constants'
import EarthIcon from '@/assets/Earth.png'
import UserAvatar from '@/assets/defaultUserAvatar.jpeg'
import BlueCheckedIcon from '@/assets/BlueCheckIcon.svg'

import { DetailsContainer, Flexline, Value, WorkRemote } from './styles'

const Details = ({ data }) => {
  const {
    createdAt,
    updatedAt,
    yearsOfExperience,
    manager,
    type,
    country,
    monthlySalary,
    currency,
    startDate,
    endDate,
    category,
    code,
    fileNeeded,
    ableToWorkRemotely,
    pic
  } = data

  const mappedCountry = COUNTRIES.find((item) => item.value === country)
  const countryDisplay = mappedCountry
    ? `${mappedCountry?.label} (${mappedCountry.code})`
    : country

  return (
    <DetailsContainer>
      <h4>Job Details</h4>

      {ableToWorkRemotely && (
        <WorkRemote>
          <img src={BlueCheckedIcon} alt="remote-work" className="icon" />
          <span className="text-content">Remote-Work</span>
        </WorkRemote>
      )}

      <h6>Job Creation Date</h6>
      <Value>
        {createdAt ? moment(createdAt).format('MMMM Do YYYY') : 'N/A'}
      </Value>

      <h6>Recruitment Period</h6>
      <Value>
        {startDate ? `${moment(startDate).format('MMMM Do YYYY')} - ` : 'N/A'}
        {endDate ? moment(endDate).format('MMMM Do YYYY') : ''}
      </Value>

      <h6>Job Category</h6>
      <Value>{category ? category?.toUpperCase() : 'N/A'}</Value>

      <h6>Manager</h6>
      {manager ? (
        <Flexline>
          <img src={manager.picture || UserAvatar} alt="manager" />
          <Value>{manager.fullName || 'Admin'}</Value>
        </Flexline>
      ) : (
        'N/A'
      )}

      <h6>PIC</h6>
      <Value>
        {pic && pic.length > 0
          ? pic.map((person) => person.fullName).join(', ')
          : 'N/A'}
      </Value>

      <h6>Job Type</h6>
      <Value>
        {type
          ? Object.values(JOB_TYPES).find((jobType) => jobType.value === type)
              ?.label
          : 'N/A'}
      </Value>

      <h6>Experiences</h6>
      <Value>
        {yearsOfExperience
          ? Object.values(EXPERIENCE_YEARS).find(
              (exp) => exp.value === Number(yearsOfExperience)
            )?.label
          : 'N/A'}
      </Value>

      <h6>Country</h6>
      {country ? (
        <Flexline>
          <img src={mappedCountry?.icon || EarthIcon} alt={country} />
          <Value>{countryDisplay}</Value>
        </Flexline>
      ) : (
        <Value>N/A</Value>
      )}

      <h6>File Needed</h6>
      <Value>
        {fileNeeded && fileNeeded.length > 0 ? fileNeeded?.join(', ') : 'N/A'}
      </Value>

      <h6>Job Code</h6>
      <Value>{code}</Value>

      <h6>Salary</h6>
      <Value>
        {monthlySalary && currency ? `${monthlySalary} ${currency}` : 'N/A'}
      </Value>

      <h6>Last Update</h6>
      <Value>
        {updatedAt ? moment(updatedAt).format('MMMM Do YYYY') : 'N/A'}
      </Value>
    </DetailsContainer>
  )
}

Details.propTypes = {
  data: PropTypes.object
}

Details.defaultProps = {
  data: {}
}

export default Details
