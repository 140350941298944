import { createAsyncThunk } from '@reduxjs/toolkit'

import { getJobsList as getJobsListApi } from '@/apis'

export const getJobsList = createAsyncThunk(
  'countriesSummary/getJobsList',
  async (payload) => {
    try {
      const response = await getJobsListApi(payload)
      return response?.data
    } catch {
      throw new Error()
    }
  }
)
