import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { EventForm } from '@/components/Event'

import { getEvent, createEvent, updateEvent } from './thunk'

const EventFormContainer = () => {
  const dispatch = useDispatch()
  const { event } = useSelector((state) => state.eventReducer.eventFormReducer)

  const { id } = useParams()

  const handleSubmitEvent = useCallback(
    (payload) => {
      if (!!id) {
        dispatch(
          updateEvent({
            data: payload,
            id
          })
        )
      } else {
        dispatch(createEvent(payload))
      }
    },
    [id, dispatch]
  )

  useEffect(() => {
    if (id) {
      dispatch(getEvent(id))
    }
  }, [id, dispatch])

  return <EventForm isEdit={!!id} data={event} onSubmit={handleSubmitEvent} />
}

export default EventFormContainer
