import React from 'react'
import { Modal, DivImg } from './styles'
import IconQuestion from '../../assets/ModalConfirm/blueQuestionMark.png'

const ModalConfirm = ({
  icon = IconQuestion,
  title,
  message,
  openModal,
  handleOk,
  handleCancel,
  loading
}) => {
  return (
    <Modal
      visible={openModal}
      confirmLoading={loading}
      onOk={handleOk}
      onCancel={loading || handleCancel}
    >
      <DivImg>
        <img src={icon} alt="modal icon" />
      </DivImg>

      <h3>{title}</h3>
      <div>{message}</div>
    </Modal>
  )
}

export default ModalConfirm
