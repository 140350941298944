import styled from 'styled-components'

export const SectionMain = styled.main`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    .filter {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
`
