import { createAsyncThunk } from '@reduxjs/toolkit'

import { getEvents as getEventsApi } from '@/apis'

export const getEvents = createAsyncThunk('eventsList/getEvent', async () => {
  try {
    const response = await getEventsApi()
    return response?.data
  } catch {
    throw new Error()
  }
})
