import styled from 'styled-components'

export const NavbarContainer = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
  padding: 1rem 3rem;

  .logo {
    height: 1.25rem;
  }
`

export const LoginContainer = styled.span`
  cursor: pointer;

  .avatar {
    width: 2rem;
    height: 2rem;
    border-radius: 50px;
    margin-right: 0.5rem;
  }

  .username {
    font-size: 0.875rem;
    color: #183b57;
    margin-right: 0.5rem;
  }
`
