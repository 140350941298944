import { useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getJobsList } from '@/containers/Dashboard/CountriesSummary/thunk'
import { CountriesSummary } from '@/components/Dashboard'
import { groupByCountry } from '@/utils/helpers/dashboard'

const CountriesSummaryContainer = () => {
  const dispatch = useDispatch()
  const { jobsList, isJobsListLoading } = useSelector(
    (state) => state.dashboardReducer.countriesSummaryReducer
  )

  const handleFilterByCountries = useCallback(
    (countries) => {
      if (countries && countries.length) {
        dispatch(getJobsList({ countries }))
      } else {
        dispatch(getJobsList({}))
      }
    },
    [dispatch]
  )

  const groupedByCountriesData = useMemo(() => {
    if (!jobsList) return {}
    return groupByCountry(jobsList)
  }, [jobsList])

  useEffect(() => {
    dispatch(getJobsList({}))
  }, [dispatch])

  return (
    <CountriesSummary
      summaryData={groupedByCountriesData}
      onFilter={handleFilterByCountries}
      isLoading={isJobsListLoading}
    />
  )
}

export default CountriesSummaryContainer
