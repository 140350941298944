import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Header } from '@/components/Events'
import { getEventsCount } from './thunk'

const EventsListContainer = () => {
  const dispatch = useDispatch()
  const { events } = useSelector((state) => state.eventsReducer.headerReducer)

  useEffect(() => {
    dispatch(getEventsCount())
  }, [dispatch])

  return <Header total={events} />
}

export default EventsListContainer
