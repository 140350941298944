import styled from 'styled-components'

export const ContentContainer = styled.section`
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  padding: 1.5rem;

  .field-title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #272d4e;
    margin-bottom: 1rem;

    &:not(:first-child) {
      margin-top: 1.5rem;
    }
  }
`

export const TitleValue = styled.div`
  background: #f8f9fc;
  width: 100%;
  border-radius: 1rem;
  padding: 1rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #272d4e;
  font-weight: 500;
  margin-top: 1rem;
`
