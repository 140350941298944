import styled, { css } from 'styled-components'

const CSSVideo = css`
  text-align: center;
  padding: 20vh 0;
`

export const Container = styled.div`
  ${(props) => (props.isVideo ? `${CSSVideo}` : ``)}

  iframe {
    width: 100%;
    height: 100vh;
  }
`
