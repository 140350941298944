import { combineReducers } from '@reduxjs/toolkit'

import countriesSummaryReducer from '@/containers/Dashboard/CountriesSummary/slice'
import hiringPipelineSummaryReducer from '@/containers/Dashboard/HiringPipeline/slice'
import jobSummaryReducer from '@/containers/Dashboard/JobSummary/slice'
import managerListReducer from '@/containers/Dashboard/ManagerList/slice'
import newJobSectionReducer from '@/containers/Dashboard/NewJobSection/slice'

const dashboardReducer = combineReducers({
  countriesSummaryReducer,
  hiringPipelineSummaryReducer,
  jobSummaryReducer,
  managerListReducer,
  newJobSectionReducer
})

export default dashboardReducer
