import React, { useState, useMemo } from "react"
import moment from "moment/moment"
import { Table, Button, message } from "antd"
import { HistoryOutlined, UserOutlined } from "@ant-design/icons"
import ExtraInformation from "@/components/Metadata/ExtraInformation"
import DropdownChangeStatus from "./DropdownChangeStatus"
import ModalChangeLogs from "./ModalChangeLogs"
import ModalProfile from "@/components/ApplicantsDatabase/ModalProfile"
import SectionFiles from "@/components/SectionAttachmentFiles"
import {
  Cell,
  CellTitle,
  CellText,
  DivStatus,
  DivTotal,
  DivFilter,
  HeaderSection,
  Section,
  BreakLine,
  Actions,
} from "./styles"
import useApplication from "@/hooks/useApplication"
import useApplications from "@/hooks/useApplications"
import useParamsPagination from "@/utils/hooks/useParamsPagination"

const Elemennt = ({ data, total }) => {
  const [paginationParams, setPaginationParams] = useParamsPagination()
  const { setApplication } = useApplication()
  const { isLoading } = useApplications()
  const [selectedRowIds, setSelectedRowIds] = useState([])
  const [samePipeline, setSamePipeline] = useState(false)
  const [selectedPipeline, setSelectedPipeline] = useState("")
  const [candidateLogId, setCandidateLogId] = useState(null)
  const [visible, setVisible] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const pagination = useMemo(() => {
    return {
      defaultPageSize: 10,
      defaultCurrent: 1,
      total: total,
      pageSize: paginationParams.pageSize || 10,
      current: paginationParams.pageIndex || 1,
      onChange: setPaginationParams,
      showSizeChanger: total > 10,
    }
  }, [
    setPaginationParams,
    paginationParams.pageIndex,
    paginationParams.pageSize,
    total,
  ])

  const getDivColor = (status) => {
    switch (status) {
      case "In Review":
        return "#367E98"
      case "On hold":
        return "#272D4E"
      case "Reject":
        return "#f5222d"
      case "Offer":
        return "#009933"
      default:
        return "#4F5AED"
    }
  }

  const Columns = [
    {
      title: "Applicants Detail",
      dataIndex: "applicantDetail",
      render: (_, row) => (
        <Cell>
          <CellTitle>{row.freelancer.fullName}</CellTitle>
          <CellText>{row.freelancer.email}</CellText>

          <BreakLine />

          {row.job && <CellText>{row.job.title}</CellText>}
          <ExtraInformation
            items={[row.freelancer.country, moment(row.submitDate).format("MM-DD-YYYY")]}
          />
        </Cell>
      ),
    },
    {
      title: "Expected Salary",
      dataIndex: "expectedSalary",
      width: "15%",
      render: (_, row) => (
        <Cell>
          <CellTitle>{row.currency}</CellTitle>
          <CellText>{row.expectedSalary}</CellText>
        </Cell>
      ),
    },
    {
      title: "Attachment File",
      dataIndex: "attachmentFile",
      width: "20%",
      render: (_, row) => <SectionFiles showTitle={false} data={row} />,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "17.5%",
      render: (_, row) => (
        <DivStatus color={getDivColor(row.status)}>
          <div>{row.status}</div>
        </DivStatus>
      ),
    },
    {
      title: "Action",
      dataIndex: "Action",
      width: "15%",
      align: "center",
      render: (_, row) => {
        return (
          <Actions>
            <Button
              type="primary"
              icon={<HistoryOutlined />}
              shape="round"
              size="small"
              ghost
              onClick={() => {
                setCandidateLogId(row._id)
                setVisible(true)
              }}
            />

            <Button
              type="primary"
              icon={<UserOutlined />}
              size="small"
              shape="round"
              ghost
              onClick={() => {
                setOpenModal(true)
                setApplication(row)
              }}
            >
              Profile
            </Button>
          </Actions>
        )
      },
    },
  ]

  const rowSelection = {
    selectedRowKeys: selectedRowIds,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowIds(selectedRowKeys)
      //get hiringPipeline of the first selected job
      let originPipeline = ""
      if (selectedRows.length > 0) {
        originPipeline = selectedRows[0].job.hiringPipeline
      }
      //check to see if all selected applications have same pipeline
      const checkSame = selectedRows.every((app) => {
        if (app.job.hiringPipeline !== originPipeline) {
          message.error(
            "You have selected applications with different hiring pipelines"
          )
          return false
        }
        return true
      })

      setSelectedPipeline(originPipeline)
      setSamePipeline(checkSame)
    },
  }

  return (
    <Section>
      <HeaderSection>
        <DivTotal>
          <span>{total} applicant(s) in table</span>
        </DivTotal>
        <DivFilter>
          <DropdownChangeStatus
            selectedRowIds={selectedRowIds}
            samePipeline={samePipeline}
            selectedPipeline={selectedPipeline}
            setSelectedRowIds={setSelectedRowIds}
          />
        </DivFilter>
      </HeaderSection>

      <Table
        loading={isLoading}
        columns={Columns}
        dataSource={data}
        rowKey="_id"
        size="small"
        pagination={pagination}
        rowSelection={rowSelection}
      />

      <ModalChangeLogs visible={visible} setVisible={setVisible} profileId={candidateLogId} />

      <ModalProfile
        open={openModal}
        handleClose={() => {
          setOpenModal(false)
        }}
      />
    </Section>
  )
}

export default Elemennt
