import { GET, POST, PATCH, DELETE } from '@/services/axios'
import { message } from 'antd'

const MANAGER_API_PREFIX = `${process.env.REACT_APP_BACKEND_API_URL}/api/v2/manager`

export const getManagers = async (params) => {
  const url = `${MANAGER_API_PREFIX}`
  const { data } = await GET(url, params)
  return data
}

export const createManager = async (formData) => {
  const url = `${MANAGER_API_PREFIX}`
  const { data } = await POST(url, formData).catch((error) => {
    const errorMessage = error.response.data.error
    message.error(errorMessage)
  })

  return data
}

export const resendInivation = async (id) => {
  const url = `${MANAGER_API_PREFIX}/resend-invitation/${id}`
  const { success } = await POST(url)

  return success
}

export const updateManager = async (id, formData) => {
  const url = `${MANAGER_API_PREFIX}/${id}`
  const { data } = await PATCH(url, formData).catch((error) => {
    const errorMessage = error.response.data.error
    message.error(errorMessage)
  })

  return data
}

export const deleteManager = async (id) => {
  const url = `${MANAGER_API_PREFIX}/${id}`
  const { success } = await DELETE(url)

  return success
}
