import React from 'react'
import Layout from '@/layouts/Default'
import Header from '@/components/HiringPipelineStatus/Header'
import Cards from '../../components/HiringPipelineStatus/Cards'

const HiringPipelineStatusPage = () => {
  return (
    <Layout>
      <Header />
      <Cards />
    </Layout>
  )
}

export default HiringPipelineStatusPage
