import React from 'react'
import moment from 'moment/moment'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import Breadcrumb from '@/components/Metadata/Breadcrumb'
import ExtraInformation from '@/components/Metadata/ExtraInformation'
import { Header, Left, Right, Title } from './styles'

const Element = ({ data }) => {
  return (
    <Header>
      <Left>
        <Link to="/applicant-database">
          <Button shape="circle" icon={<LeftOutlined />} />
        </Link>

        <div>
          <Breadcrumb
            items={[
              { link: '/dashboard', title: 'DASHBOARD' },
              { link: '/applicant-database', title: 'APPLICANTS DATABASE' },
              { link: '#', title: data.status?.toUpperCase() }
            ]}
          />

          <Title>Applicant Profile</Title>

          <ExtraInformation
            items={[
              data.job && data.job.title,
              <span>
                Latest Update:{' '}
                {data.updatedAt
                  ? moment(data.updatedAt).format('MM/DD/YYYY')
                  : '-'}
              </span>
            ]}
          />
        </div>
      </Left>

      {data.job && data.job._id && (
        <Right>
          <Link to={`/job/${data.job._id}`}>
            <Button>Job Details</Button>
          </Link>
        </Right>
      )}
    </Header>
  )
}

export default Element
