import { createSlice } from '@reduxjs/toolkit'

import { getJobsList } from '@/containers/Dashboard/NewJobSection/thunk'

const initialState = {
  jobsList: [],
  isJobsListLoading: false,
  isJobsListError: false
}

const NewJobSectionSlice = createSlice({
  name: 'newJobSection',
  initialState,
  extraReducers: {
    [getJobsList.pending]: (state) => {
      state.isJobsListError = false
      state.isJobsListLoading = true
    },
    [getJobsList.fulfilled]: (state, action) => {
      state.isJobsListError = false
      state.isJobsListLoading = false
      state.jobsList = action.payload
    },
    [getJobsList.rejected]: (state) => {
      state.isJobsListError = true
      state.isJobsListLoading = false
    }
  }
})

const { reducer } = NewJobSectionSlice

export default reducer
