import { GET, POST, DELETE } from '@/services/axios'

const BASE_URL = `${process.env.REACT_APP_BACKEND_API_URL}/api/v2/hp-dynamic`

export const getHiringPipelines = async (params) => {
  try {
    const url = BASE_URL
    return await GET(url, params)
  } catch (error) {
    throw new Error(error)
  }
}

export const getHiringPipelineByID = async (id) => {
  try {
    const url = `${BASE_URL}/${id}`
    return await GET(url)
  } catch (error) {
    throw new Error(error)
  }
}

export const createHiringPipeline = async (formData) => {
  try {
    const url = `${BASE_URL}`
    return await POST(url, formData)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteHiringPipeline = async (id) => {
  try {
    const url = `${BASE_URL}/${id}`
    return await DELETE(url)
  } catch (error) {
    throw new Error(error)
  }
}

export const getAllStatus = async () => {
  try {
    const url = `${BASE_URL}/status/get-all`
    return await GET(url)
  } catch (error) {
    throw new Error(error)
  }
}

export const createStatus = async (formData) => {
  try {
    const url = `${BASE_URL}/create-status`
    return await POST(url, formData)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteStatus = async (id, idHP) => {
  try {
    const url = `${BASE_URL}/delete-status/${id}?idHP=${idHP}`
    return await DELETE(url)
  } catch (error) {
    throw new Error(error)
  }
}
