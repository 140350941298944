import styled from 'styled-components'

export const DashboardContainerWrapper = styled.section`
  .dashboard-title {
    font-weight: 700;
    font-size: 1.5rem;
    color: #183b57;
    margin-bottom: 3rem;
  }
`

export const DashboardContent = styled.main`
  display: grid;
  grid-template-columns: calc(60vw - 9.5rem) calc(40vw - 11.75rem);
  gap: 1.25rem;

  .left-sections,
  .right-sections {
    > section:not(last-child) {
      margin-bottom: 1.25rem;
    }
  }
`

export const DashboardManagerContent = styled.main`
  display: grid;
  grid-template-columns: calc(100vw - 19rem);
  gap: 1.25rem;

  > section:not(last-child) {
    margin-bottom: 1.25rem;
  }
`
