import { createSlice } from '@reduxjs/toolkit'

const applicationsSlice = createSlice({
  name: 'applications',
  initialState: {
    isLoading: false,
    item: null,
    dataSource: [],
    total: 0
  },
  reducers: {
    setIsLoading: (state, action) => {
      return { ...state, isLoading: action.payload }
    },
    setItem: (state, action) => {
      return { ...state, item: action.payload }
    },
    setDataSource: (state, action) => {
      return { ...state, dataSource: action.payload }
    },
    setTotal: (state, action) => {
      return { ...state, total: action.payload }
    },
  },
})

export const { setIsLoading, setItem, setDataSource, setTotal } = applicationsSlice.actions

export default applicationsSlice.reducer
