import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Checkbox } from 'antd'
import { FilterOutlined } from '@ant-design/icons'
import {
  FilterSectionContainer,
  FilterTilte,
  StatusFilter,
  DepartmentFilter
} from './styles'
import Checkboxes from '@/components/Filters/Checkboxes/Department'
import { JOB_STATUSES } from '@/utils/constants'

const FilterSection = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { status } = useMemo(() => {
    return {
      status: searchParams.has('status')
        ? searchParams.get('status').split(',')
        : []
    }
  }, [searchParams])

  const handleChangeFilter = (key) => (value) => {
    if (!value || value.length === 0) {
      searchParams.delete(key)
    } else {
      searchParams.set(key, value)
    }

    setSearchParams(searchParams)
  }

  return (
    <FilterSectionContainer>
      <FilterTilte>
        <FilterOutlined style={{ color: '#888C8F' }} />

        <h2>Filter</h2>
      </FilterTilte>

      <StatusFilter>
        <h3>Job Status</h3>

        <Checkbox.Group
          onChange={handleChangeFilter('status')}
          options={Object.values(JOB_STATUSES)}
          value={status}
        />
      </StatusFilter>

      <DepartmentFilter>
        <h3>Department</h3>

        <Checkboxes paramKey="category" />
      </DepartmentFilter>
    </FilterSectionContainer>
  )
}

export default FilterSection
