import React from "react"
import { Section } from "./styles"
import Linkedin from "./Linkedin"
import LinkFile from "./LinkFile"
import LinkVideo from "./LinkVideo"

const SectionFiles = ({ showTitle = true, data }) => {
  return (
    <Section>
      {showTitle && <strong>Attachment Files:</strong>}

      {data.cvLink && (
        <LinkFile name="CV" link={data.cvLink} />
      )}

      {data.linkedin && (
        <Linkedin link={data.linkedin} />
      )}

      {data.presentationVideoLink && (
        <LinkVideo link={data.presentationVideoLink} />
      )}

      {data.englishCertificateLink && (
        <LinkFile
          name="English Certificate"
          link={data.englishCertificateLink}
        />
      )}
    </Section>
  )
}

export default SectionFiles
