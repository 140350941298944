import { useAppSelector, useAppDispatch } from '@/redux/store'
import { setItem } from '@/redux/applications'
import { findOne, update } from '@/apis/applications'

const useApplication = () => {
  const state = useAppSelector((state) => state.applications)
  const dispatch = useAppDispatch()

  const setApplication = (data) => {
    dispatch(setItem(data))
  }

  const onFindOne = (id) => {
    return findOne(id)
  }

  const onUpdate = (id, formData) => {
    return update(id, formData)
  }

  return {
    id: state.item && state.item._id,
    selectedItem: state.item,
    setApplication,
    onFindOne,
    onUpdate
  }
}

export default useApplication
