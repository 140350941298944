import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { HiringPipeline } from '@/components/Dashboard'
import { getDataForPipeline } from '@/containers/Dashboard/HiringPipeline/thunk'

const HiringPipelineContainer = () => {
  const dispatch = useDispatch()
  const { pipelineData, totalJobs, isPipelineDataLoading } = useSelector(
    (state) => state.dashboardReducer.hiringPipelineSummaryReducer
  )

  useEffect(() => {
    dispatch(getDataForPipeline())
  }, [dispatch])

  return (
    <HiringPipeline
      pipelineData={pipelineData}
      totalJobs={totalJobs}
      isLoading={isPipelineDataLoading}
    />
  )
}

export default HiringPipelineContainer
