import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { signIn, signInGoogle } from './thunk'

const UserName = Cookies.get('userName')
const UserToken = Cookies.get('userToken')
const UserPic = Cookies.get('userPic')
const UserRole = Cookies.get('userRole')

const initialState = {
  username: UserName || '',
  usertoken: UserToken || '',
  userpic: UserPic || '',
  role: UserRole,
  loading: false
}

const authenUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    logOut: (state) => {
      state.username = ''
      state.usertoken = ''
      state.userpic = ''
      state.loading = false

      Cookies.remove('userName')
      Cookies.remove('userToken')
      Cookies.remove('userPic')
      Cookies.remove('userRole')
    }
  },
  extraReducers: {
    [signIn.pending]: (state) => {
      state.loading = true
    },
    [signIn.fulfilled]: (state, action) => {
      const { username, token, role } = action.payload || {}

      state.username = username
      state.usertoken = token
      state.role = role
      state.loading = false

      Cookies.set('userName', username, { expires: 1 })
      Cookies.set('userToken', token, { expires: 1 })
      Cookies.set('userRole', role, { expires: 1 })
    },
    [signIn.rejected]: (state) => {
      state.loading = false
    },
    [signInGoogle.pending]: (state) => {
      state.loading = true
    },
    [signInGoogle.fulfilled]: (state, action) => {
      const { fullName, picture, token, role } = action.payload || {}

      state.username = fullName
      state.usertoken = token
      state.userpic = picture
      state.role = role
      state.loading = false

      Cookies.set('userName', fullName, { expires: 1 })
      Cookies.set('userToken', token, { expires: 1 })
      Cookies.set('userPic', picture, { expires: 1 })
      Cookies.set('userRole', role, { expires: 1 })
    },
    [signInGoogle.rejected]: (state) => {
      state.loading = false
    }
  }
})

export const { logOut } = authenUserSlice.actions

export default authenUserSlice.reducer
