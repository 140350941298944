import React, { useEffect } from "react"
import { UserOutlined } from "@ant-design/icons"

import { Select, Input } from "@/components/UIKits"
import SelectDepartment from "@/components/Selects/Department"

import { MANAGER_ROLES } from "@/utils/constants"
import { Form, Avatar } from "./styles"

const Element = ({ form, initialValues }) => {
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        ...initialValues,
      })
    }
  }, [initialValues, form])

  return (
    <Form form={form} layout="vertical">
      {initialValues && (
        <Avatar
          src={initialValues.picture || null}
          icon={<UserOutlined />}
          size={100}
        />
      )}

      <Form.Item
        name="fullName"
        label="Name"
        rules={[{ required: true, message: "Please provide manager name" }]}
      >
        <Input placeholder="Insert name here" />
      </Form.Item>

      <Form.Item name="role" label="Role">
        <Select placeholder="Select Role">
          {Object.values(MANAGER_ROLES).map((role, index) => {
            return (
              <Select.Option value={role.value} key={index}>
                {role.label}
              </Select.Option>
            )
          })}
        </Select>
      </Form.Item>

      <Form.Item name="department" label="Department in charge">
        <SelectDepartment placeholder="Select One" />
      </Form.Item>

      <Form.Item name="position" label="Position">
        <Input placeholder="Insert your position" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, message: "Please provide manager email" }]}
      >
        <Input placeholder="Insert your email" />
      </Form.Item>
    </Form>
  )
}

export default Element
