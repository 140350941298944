import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button, Menu, Dropdown } from "antd"
import { DownOutlined } from "@ant-design/icons"
import { logOut } from "@/redux/authenUser/slice"
import DefaultUserPic from "@/assets/defaultUserAvatar.jpeg"
import DropdownUserLogs from "@/components/Dropdowns/UserLogs"
import { NavbarContainer, LoginContainer } from "./styles"

const NavBar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { username, userpic } = useSelector((state) => state.authenUserReducer)

  const handleLogout = () => {
    dispatch(logOut())
    navigate({
      pathname: "/",
    })
  }

  const menu = (
    <Menu
      items={[
        {
          label: (
            <Button type="text" onClick={handleLogout}>
              Logout
            </Button>
          ),
          key: "logout",
        },
      ]}
    />
  )

  return (
    <NavbarContainer>
      <DropdownUserLogs />

      <Dropdown
        arrow
        trigger={["click"]}
        placement="bottomRight"
        overlay={menu}
      >
        <LoginContainer>
          <img
            src={userpic || DefaultUserPic}
            className="avatar"
            alt="avatar"
          />
          <span className="username">{username}</span>
          <DownOutlined />
        </LoginContainer>
      </Dropdown>
    </NavbarContainer>
  )
}

export default NavBar
