import styled from 'styled-components'

export const JobSummaryContainer = styled.section`
  background-color: #ffffff;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
`

export const JobSummaryHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  .title {
    font-weight: 700;
    font-size: 1rem;
    color: #183b57;
    margin: 0;
  }

  .details {
    color: rgba(24, 59, 87, 0.5);
    font-weight: 500;
    font-size: 0.75rem;
    text-decoration-line: underline;
    cursor: pointer;
  }
`

export const ChartSectionContainer = styled.main`
  padding: 1.5rem 2rem;
  border-top: 1.5px solid rgba(54, 126, 152, 0.1);
`
