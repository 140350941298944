import PageLayout from '@/layouts/Default'
import { EventFormContainer } from '@/containers/Event'

const EventPage = () => {
  return (
    <PageLayout>
      <EventFormContainer />
    </PageLayout>
  )
}

export default EventPage
