import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { JobDetails } from '@/components/JobForm'
import { getJob } from '@/redux/jobs/thunk'

const JobDetailsContainer = ({ form }) => {
  const dispatch = useDispatch()
  const { job } = useSelector((state) => state.jobsReducer)
  const { jobId } = useParams()

  useEffect(() => {
    if (jobId) {
      dispatch(getJob(jobId))
    }
  }, [jobId, dispatch])

  return <JobDetails form={form} data={job} isEdit={!!jobId} />
}

export default JobDetailsContainer
