import styled from 'styled-components'
import AntDatePicker from '@/components/UIKits/DatePicker'

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 3rem 1fr;
  align-items: flex-end;
  margin-bottom: 24px;

  > .anticon {
    margin-bottom: 0.5rem;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`

export const DatePicker = styled(AntDatePicker)`
  width: 100%;
`
