import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import SectionStatus from '@/components/Candidate/SectionStatus'
import useApplication from '@/hooks/useApplication'

const Container = () => {
  const { id } = useParams()
  const [params] = useSearchParams()
  const { onFindOne } = useApplication()
  const [loading, setLoading] = useState(false)
  const [receivedData, setReceivedData] = useState({
    job: {
      hiringPipeline: {
        statuses: []
      }
    }
  })

  useEffect(() => {
    setLoading(true)

    onFindOne(id).then((data) => {
      setReceivedData(data)
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, params])

  return (
    <SectionStatus
      data={receivedData}
      loading={loading}
    />
  )
}

export default Container
