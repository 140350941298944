import { useParams } from 'react-router-dom'
import Layout from '@/layouts/Default'
import Header from '@/containers/Candidate/Header'
import SectionInfo from '@/containers/Candidate/SectionInfo'
import SectionMain from '@/containers/Candidate/SectionMain'
import SectionStatus from '@/containers/Candidate/SectionStatus'
import { ApplicationLogsContainer } from '@/containers/ChangeLogs'
import { Main, TabText, Logs, Tabs } from './styles'

const Candidate = () => {
  const { id } = useParams()

  return (
    <Layout>
      <Header />

      <Tabs>
        <Tabs.TabPane tab={<TabText>Profile Details</TabText>} key="1">
          <SectionStatus />
          <Main>
            <SectionInfo />

            <SectionMain />
          </Main>
        </Tabs.TabPane>

        <Tabs.TabPane tab={<TabText>Change Logs</TabText>} key="2">
          <Logs>
            <ApplicationLogsContainer id={id} />
          </Logs>
        </Tabs.TabPane>
      </Tabs>
    </Layout>
  )
}

export default Candidate
