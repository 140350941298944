import { useEffect, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Header } from '@/components/JobForm'
import { getJob, updateJob, createJob } from '@/redux/jobs/thunk'
import useJobCounter from '@/hooks/useJobCounter'

const HeaderContainer = ({ form }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { jobId } = useParams()
  const { selectedItem, setJobCounter, onFindOne } = useJobCounter()
  const isEdit = !!jobId
  const { job, isSubmitting, isSubmittingSuccess, isJobLoading } = useSelector(
    (state) => state.jobsReducer
  )

  const handleSubmitJob = useCallback(
    (payload) => {
      if (isEdit) {
        dispatch(
          updateJob({
            data: payload,
            id: jobId
          })
        )
      } else {
        dispatch(createJob(payload))
      }
    },
    [dispatch, isEdit, jobId]
  )

  useEffect(() => {
    if (jobId) {
      dispatch(getJob(jobId))
    }
  }, [jobId, dispatch])

  useEffect(() => {
    if (job.title && job.country && isEdit) {
      onFindOne({
        jobTitle: job.title,
        jobCountry: job.country
      }).then((data) => {
        setJobCounter(data.data)
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, isEdit])

  useEffect(() => {
    if (isSubmittingSuccess) {
      navigate({
        pathname: '/jobs'
      })
    }
  }, [isSubmittingSuccess, navigate])

  const isLoading = isSubmitting || isJobLoading

  return (
    <Header
      form={form}
      onSubmit={handleSubmitJob}
      data={job}
      isEdit={isEdit}
      totalApplicants={selectedItem}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
    />
  )
}

export default HeaderContainer
