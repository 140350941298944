import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import Breadcrumb from './Breadcrumb'
import ExtraInformation from './ExtraInformation'
import { Content, Title } from './styles'

const Metadata = ({
  backTo = '/dashboard',
  title = '',
  breadcrumbItems = [],
  infoItems = []
}) => {
  return (
    <Content>
      <Link to={backTo}>
        <Button shape="circle" icon={<LeftOutlined />} />
      </Link>

      <div>
        {breadcrumbItems.length > 0 && <Breadcrumb items={breadcrumbItems} />}

        <Title>{title}</Title>

        {infoItems.length > 0 && (
          <ExtraInformation color="#878981" items={infoItems} />
        )}
      </div>
    </Content>
  )
}

export default Metadata
