import styled from 'styled-components'

export const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  align-items: center;
  margin-bottom: 2rem;

  .ant-input-affix-wrapper {
    width: 15rem;
    background: #ffffff;
    width: 16rem;

    input {
      background: #ffffff;
    }
  }

  .ant-select {
    .ant-select-selector {
      background: #ffffff;
    }
  }
`

export const AlphabeticalSort = styled.div`
  float: right;

  .title-text {
    color: #878981;
    font-size: 0.75rem;
    font-weight: 700;
    padding-right: 0.5rem;
  }
`
