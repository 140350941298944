import PropTypes from 'prop-types'
import { ContentContainer, TitleValue } from './styles'

const Content = ({ data }) => {
  const { title, content, description } = data

  return (
    <ContentContainer>
      <h6 className="field-title">Job Title</h6>
      <TitleValue>{title}</TitleValue>

      <h6 className="field-title">Job Description</h6>
      <span>{description}</span>

      <h6 className="field-title">Job Content</h6>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </ContentContainer>
  )
}

Content.propTypes = {
  data: PropTypes.object
}

Content.defaultProps = {
  data: {}
}

export default Content
