import React from 'react'
import axios from 'axios'
import { message } from 'antd'
import { useDispatch } from 'react-redux'
import { signInGoogle } from '@/redux/authenUser/thunk'
import { AntButtonGoogle } from './styles'
import IconGoogle from '../../assets/googleIconButton.png'

/* global google */
const ButtonGoogle = () => {
  const dispatch = useDispatch()

  const handleOAuth = async (res) => {
    try {
      const { data } = await axios.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        {
          headers: {
            Authorization: `Bearer ${res.access_token}`
          }
        }
      )

      message.success('verify Gmail successful')

      dispatch(
        signInGoogle({
          email: data.email,
          fullName: data.name,
          picture: data.picture
        })
      )
    } catch (error) {
      message.error('Error when signing in with Google')
    }
  }

  const handleGoogleLogin = () => {
    try {
      const client = google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope:
          'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        callback: handleOAuth
      })

      client.requestAccessToken()
    } catch (error) {
      window.location.reload()
    }
  }

  return (
    <AntButtonGoogle
      id="buttonGoogle"
      icon={<img src={IconGoogle} alt="google icon" />}
      onClick={handleGoogleLogin}
    >
      <strong>Continue With Google</strong>
    </AntButtonGoogle>
  )
}

export default ButtonGoogle
