import PropTypes from 'prop-types'
import { Button } from 'antd'
import { Modal } from './styles'

const ConfirmModal = ({ isVisible, loading, onConfirm, onCancel }) => {
  return (
    <Modal
      title="Export Data From Hiring Pipeline"
      okText="Export"
      visible={isVisible}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="filtered"
          type="primary"
          loading={loading}
          onClick={() => onConfirm('filtered')}
        >
          Export only Filtered Data
        </Button>,

        <Button
          key="all"
          type="primary"
          loading={loading}
          onClick={() => onConfirm()}
        >
          Export All Data
        </Button>
      ]}
      onCancel={onCancel}
    >
      Export all data in these selected statuses?
    </Modal>
  )
}

ConfirmModal.propTypes = {
  isVisible: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

ConfirmModal.defaultProps = {
  isVisible: false,
  onConfirm: () => {},
  onCancel: () => {}
}

export default ConfirmModal
