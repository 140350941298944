import { createSlice } from '@reduxjs/toolkit'

import { getApplicationLogs } from './thunk'

const initialState = {
  logs: [],
  isLogsLoading: false,
  isLogsError: false
}

const applicationLogs = createSlice({
  name: 'applicationLogs',
  initialState,
  extraReducers: {
    [getApplicationLogs.pending]: (state) => {
      state.isLogsError = false
      state.isLogsLoading = true
    },
    [getApplicationLogs.fulfilled]: (state, action) => {
      state.isLogsError = false
      state.isLogsLoading = false
      state.logs = action.payload
    },
    [getApplicationLogs.rejected]: (state) => {
      state.isLogsError = true
      state.isLogsLoading = false
    }
  }
})

const { reducer } = applicationLogs

export default reducer
