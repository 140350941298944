import styled from 'styled-components'

export const Section = styled.section`
  background-color: #ffffff;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  padding: 1rem;
  overflow: auto;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  .title {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 2.5rem;
    color: #183b57;
    margin: 0;
  }

  .details {
    cursor: pointer;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.5rem;
    text-decoration-line: underline;
    color: rgba(24, 59, 87, 0.5);
  }
`

export const Manager = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f8f9fc;
  border-radius: 10px;
  padding: 0.625rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  img {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 2rem;
    margin-right: 0.875rem;
  }

  .manager-info {
    .full-name {
      font-weight: 500;
      font-size: 0.875rem;
      color: #23262f;
      margin-bottom: 0.125rem;
    }

    .department {
      font-size: 0.75rem;
      color: #708099;
    }
  }
`
