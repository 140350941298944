import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '@/redux/store'
import { setHashTable } from '@/redux/jobCounters'
import { find } from '@/apis/jobCounters'

const useJobCounters = () => {
  const [params] = useSearchParams()
  const dispatch = useAppDispatch()
  const state = useAppSelector((state) => state.jobCounters)
  const [isLoading, setIsLoading] = useState()

  const refetch = () => {
    setIsLoading(true)

    find(params)
      .then((res) => {
        dispatch(setHashTable(res.data))
      }).finally(() => {
        setIsLoading(false)
      });
  }

  return { isLoading, hashTable: state.hashTable, refetch }
}

export default useJobCounters
