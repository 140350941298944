import React, { useMemo } from 'react'
import { Icon } from './styles'
import { COUNTRIES } from '../../../utils/constants'

const Element = ({ name = 'USA', showCode = false }) => {
  const country = useMemo(() => {
    return COUNTRIES.find((country) => country.value === name)
  }, [name])

  return (
    <span>
      {country.icon && <Icon src={country.icon} alt={country.code} />}
      {showCode ? country.code : country.label}
    </span>
  )
}

export default Element
