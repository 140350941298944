import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'antd'
import ModalConfirm from '../../../ModalConfirm'
import { Dropdown, TextDelete, TextActivate, EllipsisOutlined } from './styles'

const DropDownOption = ({
  data,
  handleUpdateManagerStatus,
  handleEdit,
  handleResendInvitation
}) => {
  const [actionName, setActionName] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [messageModal, setMessageModal] = useState({
    title: '',
    intro: '',
  })

  const changeStatus = (manager) => {
    setOpenModal(true)
    if (manager.status === 'active') {
      setActionName('deactivate')
      setMessageModal({
        title: `Deactivate ${manager.fullName}`,
        intro: 'Are you sure you want to deactivate this manger?',
      })
    } else {
      setActionName('activate')
      setMessageModal({
        title: `Activate ${manager.fullName}`,
        intro: 'Are you sure you want to activate this manger?',
      })
    }
  }

  const handleOk = () => {
    handleUpdateManagerStatus(data._id, actionName)
    setOpenModal(false)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  //if active manager, there is option for deactivate manager
  const menuItems = () => {
    let result = []

    //active status
    if (data.status === 'active') {
      result = [
        {
          key: '1',
          label: <strong>Edit</strong>,
          onClick() {
            handleEdit(data)
          }
        },
        {
          key: '2',
          label: <TextDelete>Deactivate</TextDelete>,
          onClick() {
            changeStatus(data)
          }
        }
      ]
    } // invited status
    else if (data.status === 'invited') {
      result = [
        {
          key: '1',
          label: <strong>Edit</strong>,
          onClick() {
            handleEdit(data)
          }
        },
        {
          key: '2',
          label: <span>Resend Invitation</span>,
          onClick() {
            handleResendInvitation(data._id)
          }
        }
      ]
    } //inactive status
    else {
      result = [
        {
          key: '1',
          label: <strong>Edit</strong>,
          onClick() {
            handleEdit(data)
          }
        },
        {
          key: '2',
          label: <TextActivate>Activate</TextActivate>,
          onClick() {
            changeStatus(data)
          }
        }
      ]
    }

    return result
  }

  const menu = <Menu items={menuItems()} />

  return (
    <>
      <Dropdown arrow overlay={menu} placement="bottomLeft" trigger={['click']}>
        <EllipsisOutlined />
      </Dropdown>
      <ModalConfirm
        openModal={openModal}
        {...messageModal}
        handleOk={handleOk}
        handleCancel={handleCloseModal}
      />
    </>
  )
}

DropDownOption.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    fullName: PropTypes.string,
    email: PropTypes.string,
    position: PropTypes.string,
    department: PropTypes.string,
    picture: PropTypes.string
  }),
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func
}

DropDownOption.defaultProps = {
  data: {
    _id: '',
    fullName: '',
    email: '',
    position: '',
    department: '',
    picture: ''
  },
  handleDelete: () => {},
  handleEdit: () => {}
}

export default DropDownOption
