import { useEffect, useState } from 'react'
import { getJobTitles } from '../../apis'

const useFetchJobTitles = () => {
  const [titlesList, setTitlesList] = useState([])

  /**
   * call API to get all job data
   * then filter them for all existing deparments
   */
  useEffect(() => {
    getJobTitles().then((data) => {
      setTitlesList(data.titles)
    })
  }, [])

  return {
    titlesList
  }
}

export default useFetchJobTitles
