import { useSearchParams } from 'react-router-dom'

export default function useParamsPagination () {
  const [searchParams, setSearchParams] = useSearchParams()

  const currentPageSize = parseInt(searchParams.get('pageSize')) 
  const currentPageIndex = parseInt(searchParams.get('pageIndex'))

  const handlePaginationChange = (pageIndex, pageSize) => {
    const currentParams = Object.fromEntries(searchParams)

    setSearchParams({
      ...currentParams,
      pageIndex:
        currentPageSize === pageSize || !currentPageSize ? pageIndex : 1,
      pageSize
    })
  }

  return [
    { pageSize: currentPageSize, pageIndex: currentPageIndex },
    handlePaginationChange
  ]
}
