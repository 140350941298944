import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { getAllStatus } from '@/apis/hiringPipelineDynamic'
import SelectStatus from '@/components/ApplicantsDatabase/FilterSelectStatus'

const Container = ({ placeholder }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [statusList, setStatusList] = useState([])
  const [defaultStatus, setdefaultStatus] = useState([])

  useEffect(() => {
    getAllStatus().then((data) => {
      setStatusList(data.data)
    })

    if (searchParams.has('status')) {
      setdefaultStatus(searchParams.get('status').split(','))
    } else {
      setdefaultStatus([])
    }
  }, [searchParams, setSearchParams])

  const onChange = (key) => {
    searchParams.delete('to')
    searchParams.delete('from')
    searchParams.delete('pageIndex')
    searchParams.delete('pageSize')
    searchParams.delete('expectedSalary')

    searchParams.set('status', key)

    if (!key || !key.length) {
      searchParams.delete('status')
      setdefaultStatus([])
    }

    setSearchParams(searchParams)
  }

  return (
    <SelectStatus
      placeholder={placeholder}
      statusList={statusList}
      defaultStatus={defaultStatus}
      handleOnChange={onChange}
    />
  )
}

export default Container
