import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ModalNew from '../../../containers/Managers/Modal'
import { Content } from './styles'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Metadata from '@/components/Metadata'

const Element = () => {
  const [openModal, setOpenModal] = useState(false)

  const handleClick = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const displayModalInviteManager = () => {
    return <ModalNew visible={openModal} handleClose={handleClose} />
  }

  return (
    <header>
      <Content>
        <Metadata
          title="Manager List"
          breadcrumbItems={[
            { link: '/dashboard', title: 'DASHBOARD' },
            { link: '#', title: 'MANAGER LIST' }
          ]}
        />

        <Button type="primary" icon={<PlusOutlined />} onClick={handleClick}>
          Invite New Manager
        </Button>
      </Content>
      {displayModalInviteManager()}
    </header>
  )
}

Element.propTypes = {
  handleCreateManager: PropTypes.func
}

Element.defaultProps = {
  handleCreateManager: () => {}
}

export default Element
