import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Metadata from '@/components/Metadata'
import { Header } from './styles'
import { groupByCountry } from '@/utils/helpers/dashboard'

const JobPostHeader = ({ jobPosts }) => {
  const totalJobCountries = useMemo(() => {
    if (jobPosts.length) {
      const groupedData = groupByCountry(jobPosts)
      return Object.keys(groupedData).length
    }
    return 0
  }, [jobPosts])

  return (
    <Header>
      <Metadata
        title="Job Post"
        breadcrumbItems={[
          { link: '/dashboard', title: 'DASHBOARD' },
          { link: '#', title: 'JOB POSTS' }
        ]}
        infoItems={[
          `Open in: ${totalJobCountries} countries`,
          `Total Job Created: ${jobPosts.length}`
        ]}
      />

      <Button type="primary" icon={<PlusOutlined />} href="/job/create">
        Create Job Post
      </Button>
    </Header>
  )
}

JobPostHeader.propTypes = {
  jobPosts: PropTypes.array
}

JobPostHeader.defaultProps = {
  jobPosts: []
}

export default JobPostHeader
