import { createAsyncThunk } from '@reduxjs/toolkit'
import { message } from 'antd'

import {
  createEvent as createEventApi,
  updateEvent as updateEventApi,
  getEvent as getEventApi
} from '@/apis'

export const createEvent = createAsyncThunk(
  'eventForm/createEvent',
  async (payload) => {
    try {
      const response = await createEventApi(payload)
      message.success('Event is created successfully')
      return response?.data
    } catch {
      message.error('Cannot create event. Please try again')
      throw new Error()
    }
  }
)

export const updateEvent = createAsyncThunk(
  'eventForm/updateEvent',
  async (payload) => {
    try {
      const response = await updateEventApi(payload.data, payload.id)
      message.success('Event is updated successfully')
      return response?.data
    } catch {
      message.error('Cannot update event. Please try again')
      throw new Error()
    }
  }
)

export const getEvent = createAsyncThunk('eventForm/getEvent', async (id) => {
  try {
    const response = await getEventApi(id)
    return response?.data
  } catch {
    throw new Error()
  }
})
