import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;

  .ant-input-affix-wrapper {
    width: 15rem;
    background: white;

    input {
      background: white;
    }
  }
`

export const DivSort = styled.div`
  > span {
    color: #878981;
    font-size: 0.75rem;
    font-weight: 700;
    padding-right: 0.5rem;
  }

  .ant-select .ant-select-selector {
    background: white;
    width: 12rem;
  }
`
