import { GET, POST, PATCH, DELETE } from '@/services/axios'

const JOB_API_PREFIX = `${process.env.REACT_APP_BACKEND_API_URL}/api/v2/jobs`

export const getJobsList = async (params) => {
  const url = `${JOB_API_PREFIX}`
  const { data } = await GET(url, params)
  return data
}

export const getJobsSummary = async () => {
  const url = `${JOB_API_PREFIX}/summary/get`
  const { data } = await GET(url)
  return data
}

export const getDepartments = async () => {
  const url = `${JOB_API_PREFIX}/departments/get`
  const { data } = await GET(url)
  return data
}

export const getJobTitles = async () => {
  const url = `${JOB_API_PREFIX}/job-titles/get`
  const { data } = await GET(url)
  return data
}

export const createJob = async (payload) => {
  const { data } = await POST(JOB_API_PREFIX, payload)
  return data
}

export const updateJob = async (payload, id) => {
  const url = `${JOB_API_PREFIX}/${id}`
  const { data } = await PATCH(url, payload)
  return data
}

export const getJob = async (id) => {
  const url = `${JOB_API_PREFIX}/${id}`
  const { data } = await GET(url)
  return data
}

export const deleteJob = async (id) => {
  const url = `${JOB_API_PREFIX}/${id}`
  await DELETE(url)
}

export const getJobEmails = async (id) => {
  const url = `${JOB_API_PREFIX}/emails/${id}`
  const { data } = await GET(url)
  return data
}

export const cloneJob = async (id) => {
  const url = `${JOB_API_PREFIX}/clone/${id}`
  const { data } = await POST(url)
  return data
}
