import { createSlice } from '@reduxjs/toolkit'
import { getJobs, getJob, createJob, updateJob, getEmails } from './thunk'

const initialState = {
  jobs: [],
  isJobsLoading: false,
  isJobsError: false,
  job: {},
  isJobLoading: false,
  isJobError: false,
  emails: [],
  isEmailsLoading: false,
  isEmailsError: false,
  isSubmitting: false,
  isSubmittingError: false,
  isSubmittingSuccess: false
}

const JobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    resetState: () => initialState
  },
  extraReducers: {
    [getJobs.pending]: (state) => {
      state.isJobsError = false
      state.isJobsLoading = true
    },
    [getJobs.fulfilled]: (state, action) => {
      state.jobs = action.payload
      state.isJobsError = false
      state.isJobsLoading = false
    },
    [getJobs.rejected]: (state) => {
      state.isJobsError = true
      state.isJobsLoading = false
    },
    [getJob.pending]: (state) => {
      state.isJobError = false
      state.isJobLoading = true
    },
    [getJob.fulfilled]: (state, action) => {
      state.job = action.payload
      state.isJobError = false
      state.isJobLoading = false
    },
    [getJob.rejected]: (state) => {
      state.isJobError = true
      state.isJobLoading = false
    },
    [createJob.pending]: (state) => {
      state.isSubmittingError = false
      state.isSubmitting = true
    },
    [createJob.fulfilled]: (state) => {
      state.isSubmittingError = false
      state.isSubmitting = false
      state.isSubmittingSuccess = true
    },
    [createJob.rejected]: (state) => {
      state.isSubmittingError = true
      state.isSubmitting = false
      state.isSubmittingSuccess = false
    },
    [updateJob.pending]: (state) => {
      state.isSubmittingError = false
      state.isSubmitting = true
    },
    [updateJob.fulfilled]: (state) => {
      state.isSubmittingError = false
      state.isSubmitting = false
      state.isSubmittingSuccess = true
    },
    [updateJob.rejected]: (state) => {
      state.isSubmittingError = true
      state.isSubmitting = false
      state.isSubmittingSuccess = false
    },
    [getEmails.pending]: (state) => {
      state.isEmailsError = false
      state.isEmailsLoading = true
    },
    [getEmails.fulfilled]: (state, action) => {
      state.isEmailsError = false
      state.isEmailsLoading = false
      state.emails = action.payload
    },
    [getEmails.rejected]: (state) => {
      state.isEmailsError = true
      state.isEmailsLoading = false
    }
  }
})

const { reducer } = JobsSlice

export const actions = JobsSlice.actions

export default reducer
