import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { EventsList } from '@/components/Events'
import { getEvents } from './thunk'

const EventsListContainer = () => {
  const dispatch = useDispatch()
  const { events } = useSelector(
    (state) => state.eventsReducer.eventsListReducer
  )

  useEffect(() => {
    dispatch(getEvents())
  }, [dispatch])

  return <EventsList data={events} />
}

export default EventsListContainer
