import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import HeaderCandidate from '@/components/Candidate/Header'
import useApplication from '@/hooks/useApplication'

const Container = () => {
  const { id } = useParams()
  const [params] = useSearchParams()
  const { onFindOne } = useApplication()
  const [receivedData, setReceivedData] = useState(null)

  useEffect(() => {
    onFindOne(id).then((data) => {
      setReceivedData(data)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, params])

  return (
    <>
      {receivedData && (
        <HeaderCandidate data={receivedData} />
      )}
    </>
  )
}

export default Container
