import React, { useState } from 'react'
import PropTypes from 'prop-types'
import lodash from 'lodash'
import { Empty, Spin, Tag } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { MANAGER_STATUSES } from '@/utils/constants'
import ModalEdit from '../../../containers/Managers/Modal'
import DropDownOption from './DropdownOption'
import {
  GridContainer,
  Card,
  Avatar,
  MainInfo,
  Footer,
  EmptyOrLoading,
  StatusText
} from './styles'

const Element = ({
  loading,
  data,
  refetch,
  handleUpdateManagerStatus,
  handleResendInvitation
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [selectedManager, setSelectedManager] = useState({})

  const handleClick = (manager) => {
    setSelectedManager(manager)
    setOpenModal(true)
  }

  const handleClose = () => {
    setSelectedManager({})
    setOpenModal(false)
  }

  const getStatusTag = (status) => {
    const managerStatus = Object.values(MANAGER_STATUSES).find(
      (item) => item.value === status
    )

    if (managerStatus) {
      return (
        <Tag color={managerStatus.bgColor}>
          <StatusText color={managerStatus.txtColor}>
            {managerStatus.value}
          </StatusText>
        </Tag>
      )
    }

    return <></>
  }

  return (
    <>
      {data && !loading && (
        <GridContainer>
          {data &&
            data.map((manager, index) => (
              <Card key={index}>
                {manager.status && getStatusTag(manager.status)}

                {!manager.username && (
                  <DropDownOption
                    data={manager}
                    handleEdit={handleClick}
                    handleUpdateManagerStatus={handleUpdateManagerStatus}
                    handleResendInvitation={handleResendInvitation}
                  />
                )}

                <Avatar
                  src={manager.picture || null}
                  icon={<UserOutlined />}
                  statuscolor={manager.status === 'deactive' ? '100%' : '0%'}
                  size={60}
                />

                <MainInfo>
                  <strong>{manager.fullName || manager.username}</strong>
                  <span>{manager.email}</span>
                </MainInfo>

                <Footer
                  padding={
                    manager.position || manager.department ? '0.5rem 0' : '0'
                  }
                >
                  {manager.position}
                  {manager.department && (
                    <strong>{`Manager of ${lodash.startCase(
                      manager.department.replace('-', ' ')
                    )}`}</strong>
                  )}
                </Footer>
              </Card>
            ))}

          <ModalEdit
            refetch={refetch}
            visible={openModal}
            data={selectedManager}
            handleClose={handleClose}
          />
        </GridContainer>
      )}

      {loading && (
        <EmptyOrLoading>
          <Spin size="large" />
        </EmptyOrLoading>
      )}
      {(!data || !data.length) && !loading && (
        <EmptyOrLoading>
          <Empty />
        </EmptyOrLoading>
      )}
    </>
  )
}

Element.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  handleUpdateManager: PropTypes.func,
  handleDeleteManager: PropTypes.func
}

Element.defaultProps = {
  data: [],
  loading: false,
  handleUpdateManager: () => {},
  handleDeleteManager: () => {}
}

export default Element
