import React from 'react'
import { Icon } from './styles'
import BlueCheckedIcon from '@/assets/BlueCheckIcon.svg'

const Element = () => (
  <span>
    <Icon src={BlueCheckedIcon} alt="Remote Work" />
    Remote-work
  </span>
)

export default Element
