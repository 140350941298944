import { createSlice } from '@reduxjs/toolkit'

import { getManagers } from '@/containers/Dashboard/ManagerList/thunk'

const initialState = {
  managersList: [],
  isManagerListLoading: false,
  isManagerListError: false
}

const managerList = createSlice({
  name: 'managerList',
  initialState,
  extraReducers: {
    [getManagers.pending]: (state) => {
      state.isManagerListError = false
      state.isManagerListLoading = true
    },
    [getManagers.fulfilled]: (state, action) => {
      state.isManagerListError = false
      state.isManagerListLoading = false
      state.managersList = action.payload
    },
    [getManagers.rejected]: (state) => {
      state.isManagerListError = true
      state.isManagerListLoading = false
    }
  }
})

const { reducer } = managerList

export default reducer
