import { useEffect, useState } from 'react'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import {
  getHiringPipelineByID,
  createStatus,
  deleteStatus
} from '../../../apis/hiringPipelineDynamic/index'
import { message } from 'antd'

const useCardsLogic = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const { id } = useParams()

  const [arrayStatus, setArrayStatus] = useState([])
  const [pipelineName, setPipelineName] = useState('')
  const [totalStatus, setTotalStatus] = useState(0)
  const [pipelineError, setPipelineError] = useState('')

  const [loading, setLoading] = useState(false)

  const [openModal, setOpenModal] = useState(false)
  const [messageModal, setMessageModal] = useState({
    title: '',
    intro: ''
  })
  const [deletedStatus, setDeletedStatus] = useState()

  useEffect(() => {
    if (id !== 'create') {
      setLoading(true)
      getHiringPipelineByID(id).then((data) => {
        const pipeline = data.data.data
        setArrayStatus(pipeline.statuses)
        setPipelineName(pipeline.name)
        setTotalStatus(pipeline.statuses.length)
        setLoading(false)
      })
    }
  }, [id, params])

  /**
   * change pipeline title
   * @param {JSEvent} e
   */
  const handleOnChangeTitle = (e) => {
    setPipelineName(e.target.value)
  }

  /**
   * create empty card for new status
   */
  const handleAddStatus = () => {
    setArrayStatus([...arrayStatus, {}])
  }

  /**
   * storing values when user is editing the status card
   * @param {Number} index index of editing card (object) in arrayStatus
   */
  const handleOnChangeStatus = (index) => (e) => {
    let newArr = [...arrayStatus] //copy arrayStatus

    //get element at index, then update property using e.target.name
    newArr[index][e.target.name] = e.target.value

    setArrayStatus(newArr)
  }

  const handleClickSave = () => {
    const filterArray = arrayStatus.filter(
      (status) => status && Object.keys(status).length !== 0
    )
    setPipelineError('')

    if (pipelineName) {
      createStatus({ id, arrayStatus: filterArray, pipelineName })
        .then(() => {
          message.success('All changes are saved!')
        })
        .finally(() => {
          if (id === 'create') {
            navigate('/manage-pipelines')
          }
        })
        .catch(() => message.error('Error, unable to save changes'))
    } else {
      message.error('Pipeline name cannot be empty!')
      setPipelineError('error')
    }
  }

  const handleClickDelete = (key, name) => {
    setDeletedStatus(key)
    setMessageModal({
      title: `Delete "${name}"`,
      intro: 'Are you sure you want to delete this status?'
    })
    setOpenModal(true)
  }

  /**
   * Modal Function: handle when OK is clicked
   */
  const handleOk = () => {
    deleteStatus(deletedStatus, id)
      .then(() => {
        message.success('Status deleted!')
      })
      .catch(() => message.error('Error, unable to delete status'))

    setOpenModal(false)
  }

  /**
   * Modal Function: handle when Cancel is clicked
   */
  const handleCancel = () => {
    setOpenModal(false)
  }

  return {
    loading,
    id,
    pipelineName,
    pipelineError,
    arrayStatus,
    totalStatus,
    handleOnChangeTitle,
    handleAddStatus,
    handleOnChangeStatus,
    handleClickSave,
    openModal,
    messageModal,
    handleClickDelete,
    handleOk,
    handleCancel
  }
}

export default useCardsLogic
