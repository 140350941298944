import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Checkbox, Spin } from 'antd'
import useFetchDepartment from '../../../../hooks/FetchDepartments'
import { Container } from './styles'

const Element = ({ paramKey = 'category' }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { loading, departmentList } = useFetchDepartment()

  const defaultValue = useMemo(() => {
    if (searchParams.has(paramKey)) {
      const values = searchParams.get(paramKey)
      if (values.split(',')) {
        return values.split(',')
      } else {
        return [values]
      }
    }

    return []
  }, [searchParams, paramKey])

  const onChange = (value) => {
    if (!value || value.length === 0) {
      searchParams.delete(paramKey)
    } else {
      searchParams.set(paramKey, value)
    }

    setSearchParams(searchParams)
  }

  return (
    <div>
      {departmentList && (
        <Checkbox.Group value={defaultValue} onChange={onChange}>
          {departmentList.map((dep, index) => (
            <Checkbox key={index} value={dep.value}>
              {dep.label}
            </Checkbox>
          ))}
        </Checkbox.Group>
      )}
      {loading && (
        <Container>
          <Spin />
        </Container>
      )}
    </div>
  )
}

export default Element
