import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .ant-modal-content {
    border-radius: 1rem;

    h3 {
      color: #272d4e;
      font-weight: 700;
      font-size: 1.25rem;
      text-align: center;
    }

    p {
      color: #272d4e;
      text-align: center;
      font-size: 1rem;
    }
  }

  .ant-modal-footer {
    text-align: center;

    .ant-btn {
      width: 7.5rem;
    }
  }

  h3 {
    color: #272d4e;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: center;
  }

  div {
    color: #272d4e;
    text-align: center;
    font-size: 0.875rem;
    line-height: 0.875rem;

    > span {
      display: block;
      margin-top: 1rem;
      font-weight: 600;
    }
  }
`
export const DivImg = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  img {
    width: 6rem;
  }
`
