import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Section, Header, Manager } from './styles'
import defaultAvatar from '../../../assets/defaultUserAvatar.jpeg'

const ManagerList = ({ isLoading, managersList }) => {
  return (
    <Section>
      <Header>
        <h3 className="title">List of Managers</h3>
        
        <Link to="/managers">
          <span className="details">See Details</span>
        </Link>
      </Header>

      <main>
        {managersList?.map((manager, index) => {
          return (
            <Manager key={index}>
              <img
                src={manager.picture || defaultAvatar}
                alt="manager-profile"
              />

              <div className="manager-info" data-testid="manager-info">
                <div className="full-name">{manager.fullName}</div>
                <div className="department">{manager.title}</div>
              </div>
            </Manager>
          )
        })}
      </main>
    </Section>
  )
}

ManagerList.propTypes = {
  managersList: PropTypes.array,
  isLoading: PropTypes.bool
}

ManagerList.defaultProps = {
  managersList: {},
  isLoading: false
}

export default ManagerList
