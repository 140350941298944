import { createSlice } from '@reduxjs/toolkit'

import { getDataForPipeline } from '@/containers/Dashboard/HiringPipeline/thunk'

const initialState = {
  pipelineData: {},
  totalJobs: 0,
  isPipelineDataLoading: false,
  isPipelineDataError: false
}

const hiringPipelineSlice = createSlice({
  name: 'hiringPipelineSummary',
  initialState,
  extraReducers: {
    [getDataForPipeline.pending]: (state) => {
      state.isPipelineDataLoading = true
      state.isPipelineDataError = false
    },
    [getDataForPipeline.fulfilled]: (state, action) => {
      state.isPipelineDataLoading = false
      state.isPipelineDataError = false
      state.pipelineData = action.payload.data
      state.totalJobs = action.payload.totalJobs
    },
    [getDataForPipeline.rejected]: (state) => {
      state.isPipelineDataLoading = false
      state.isPipelineDataError = true
    }
  }
})

const { reducer } = hiringPipelineSlice

export default reducer
