import styled from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  .ant-checkbox-group {
    display: block;
  }

  .ant-checkbox-wrapper {
    display: flex;
    width: 100%;
    background-color: #F6F8FD;
    border-radius: 0.5rem;
    padding: 0.75rem;
    margin-right: 0;
    margin-bottom: 1rem;
  }
`