import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { getHiringPipelineByID } from '@/apis/hiringPipelineDynamic'
import { Select } from './styles'
import useApplication from '@/hooks/useApplication'
import useApplications from '@/hooks/useApplications'

const DropdownChangeStatus = ({
  selectedRowIds = [],
  samePipeline,
  selectedPipeline,
  setSelectedRowIds
}) => {
  const { onUpdate } = useApplication()
  const { refetch } = useApplications()
  const [statusList, setStatusList] = useState([])
  let disableSelect = selectedRowIds.length === 0 || !samePipeline

  useEffect(() => {
    getHiringPipelineByID(selectedPipeline).then((data) => {
      const { statuses } = data.data.data
      if (statuses) {
        setStatusList(statuses)
      }
    })
  }, [selectedPipeline])

  const onChange = (value) => {
    const formData = { status: value }

    if (selectedRowIds.length > 0) {
      const promises = selectedRowIds.map((id) => {
        return onUpdate(id, formData)
      })
      
      Promise.all(promises).then(() => {
        refetch()
        setSelectedRowIds([])
      })
      
    }
  }

  return (
    <Tooltip
      title={
        disableSelect &&
        'Please select applications of the same status to change'
      }
      placement="left"
    >
      <Select
        value={null}
        disabled={disableSelect}
        placeholder="Change Status"
        onChange={onChange}
      >
        {statusList.map((status) => (
          <Select.Option key={status.name} value={status.name}>
            {status.name}
          </Select.Option>
        ))}
      </Select>
    </Tooltip>
  )
}

export default DropdownChangeStatus
