import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import ApplicationLogs from '@/components/ChangeLogs'
import { getApplicationLogs } from './thunk'

const ApplicationLogsContainer = ({ id }) => {
  const dispatch = useDispatch()

  const { logs, isLogsLoading } = useSelector(
    (state) => state.logsReducer.applicationLogsReducer
  )

  useEffect(() => {
    if (id) {
      dispatch(getApplicationLogs(id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return <ApplicationLogs data={logs} isLoading={isLogsLoading} />
}

ApplicationLogsContainer.propTypes = {
  id: PropTypes.string
}

ApplicationLogsContainer.defaultProps = {
  id: null
}

export default ApplicationLogsContainer
