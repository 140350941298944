import React from 'react'
import Layout from '@/layouts/Default'
import Table from '../../components/HiringPipelines/Table'
import Header from '@/components/HiringPipelines/Header'

const HiringPipelineDynamicPage = () => {
  return (
    <Layout>
      <Header />
      <Table />
    </Layout>
  )
}

export default HiringPipelineDynamicPage
