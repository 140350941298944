import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { createSearchParams } from 'react-router-dom'

import TextCountry from '@/components/Texts/Country'
import {
  CountriesSummaryContainer,
  SummaryHeader,
  CountryBoxContainer,
  CountryBox,
  CustomSelectCountry as SelectCountry
} from './styles'
import { COUNTRIES } from '@/utils/constants'

const formatJobsOpening = (number) => {
  if (!number) return <Spin />
  if (number > 1) return `${number} Jobs Opening`
  return `${number} Job Opening`
}

const CountriesSummary = ({ summaryData, onFilter, isLoading }) => {
  const handleFilterCountries = (value) => {
    onFilter(value)
  }

  return (
    <CountriesSummaryContainer>
      <SummaryHeader>
        <div className="headerLeft">
          <div className="headerText">View Hiring By</div>

          <SelectCountry
            allowClear
            showSearch
            showArrow
            maxTagCount="responsive"
            mode="multiple"
            placeholder="Countries"
            onChange={handleFilterCountries}
          />
        </div>
      </SummaryHeader>

      {!isLoading && (
        <CountryBoxContainer>
          {COUNTRIES.filter((country) =>
            Object.keys(summaryData).includes(country.value)
          ).map((country, index) => {
            const params = createSearchParams({
              jobCountry: country.value
            }).toString()

            const url = `/applicant-database?${params}`

            return (
              <CountryBox href={url} key={index}>
                <TextCountry name={country.label} />

                <div className="secondRow">
                  {formatJobsOpening(summaryData[country.value])}
                </div>
              </CountryBox>
            )
          })}
        </CountryBoxContainer>
      )}
    </CountriesSummaryContainer>
  )
}

CountriesSummary.propTypes = {
  onFilter: PropTypes.func,
  summaryData: PropTypes.object,
  isLoading: PropTypes.bool
}

CountriesSummary.defaultProps = {
  onFilter: () => {},
  summaryData: {},
  isLoading: false
}

export default CountriesSummary
