import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import { Button, message, notification } from 'antd'
import ConfirmModal from './ConfirmModal'

const DownloadBase64Button = ({
  fetchAPI,
  fileName,
  children,
  ...otherProps
}) => {
  const [searchParams] = useSearchParams()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const notiSuccess = (filtered) => {
    let strFilters = ''
    Array.from(searchParams.values()).forEach((item, index) => {
      if (index !== 0) strFilters += ','
      strFilters += item
    })

    notification.success({
      message: 'Data exported',
      description: filtered ? (
        <p>
          All filtered data <strong>({strFilters})</strong> has been
          successfully exported
        </p>
      ) : (
        `All data has been successfully exported`
      )
    })
  }

  const onConfirm = async (filtered) => {
    setLoading(true)
    const parsedSearchParams = Object.fromEntries(searchParams)

    const exportData = filtered
      ? await fetchAPI({ ...parsedSearchParams })
      : await fetchAPI()

    try {
      if (!exportData) {
        throw new Error()
      }

      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${exportData}`
      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.target = '_blank'
      downloadLink.download = fileName
      downloadLink.click()

      setLoading(false)
      notiSuccess(filtered)
    } catch (err) {
      message.error('Unable to export file. Please try again.')
    }

    if (visible) {
      setVisible(false)
    }
  }

  const onClick = () => {
    setVisible(true)
  }

  const onCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <Button {...otherProps} onClick={onClick}>
        {children}
      </Button>

      <ConfirmModal
        isVisible={visible}
        loading={loading}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </>
  )
}

DownloadBase64Button.propTypes = {
  fetchAPI: PropTypes.func,
  fileName: PropTypes.string
}

DownloadBase64Button.defaultProps = {
  fetchAPI: () => {},
  fileName: 'Download File'
}

export default DownloadBase64Button
