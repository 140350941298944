import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import useApplication from '@/hooks/useApplication'

const Element = ({ onOk }) => {
  const { id } = useApplication()

  return (
    <footer>
      <Link to={`/candidate/${id}`} target="_blank">
        <Button key="detail">See details</Button>
      </Link>
      <Button type="primary" key="save" onClick={onOk}>
        Save changes
      </Button>
    </footer>
  )
}

export default Element
