import React from 'react'
import CardsLogic from '../../../hooks/HiringPipelines/Cards'
import { Header } from './styles'
import Metadata from '@/components/Metadata'

const HeaderHPDynamicStatus = () => {
  const { pipelineName, totalStatus, id } = CardsLogic()
  const total = totalStatus >= 0 ? totalStatus : 'loading...'

  return (
    <Header>
      {id === 'create' && (
        <Metadata
          backTo="/manage-pipelines"
          title="Create New Pipeline"
          breadcrumbItems={[
            { link: '/dashboard', title: 'DASHBOARD' },
            { link: '/manage-pipelines', title: 'MANAGE PIPELINES' },
            { link: '#', title: 'CREATE NEW PIPELINE' }
          ]}
        />
      )}

      {id !== 'create' && (
        <Metadata
          backTo="/manage-pipelines"
          title="Manage Statuses"
          breadcrumbItems={[
            { link: '/dashboard', title: 'DASHBOARD' },
            { link: '/manage-pipelines', title: 'MANAGE PIPELINES' },
            { link: '#', title: pipelineName }
          ]}
          infoItems={[`Total Statuses: ${total}`]}
        />
      )}
    </Header>
  )
}

export default HeaderHPDynamicStatus
