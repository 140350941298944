import styled from 'styled-components'

export const Information = styled.div`
  color: ${(props) => props.color};
`

export const Item = styled.span`
  position: relative;
  display: inline-block;
  font-size: 0.875rem;
  margin-right: 1.5rem;

  &:not(:last-child)::after {
    content: '\\2022';
    position: absolute;
    bottom: 0;
    right: -1rem;
  }
`
