import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Content } from '@/components/JobView'
import { getJob } from '@/redux/jobs/thunk'

const ContentContainer = () => {
  const dispatch = useDispatch()
  const { job } = useSelector((state) => state.jobsReducer)

  const { jobId } = useParams()

  useEffect(() => {
    if (jobId) {
      dispatch(getJob(jobId))
    }
  }, [jobId, dispatch])

  return <Content data={job} />
}

export default ContentContainer
