import styled from 'styled-components'
import { Button } from 'antd'

export const Section = styled.section`
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  padding: 1rem;
`
export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  .ant-input-affix-wrapper {
    width: 20rem;
  }
`
export const DivStatus = styled.div`
  width: 10.5rem;
  height: 4rem;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #f0f3f7;
  font-weight: 700;
  font-size: 0.75rem;
  text-align: center;
  z-index: ${(props) => props.zindex};
  margin-left: ${(props) => props.first};

  background-image: url(${(props) => props.picture}); /* The image used */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;

  span {
    width: 7rem;
  }
`
export const DivStatusSection = styled.div`
  max-width: 45vw;
  display: grid;
  grid-auto-flow: column;
  overflow: auto;
`

export const DivJobsSection = styled.div`
  max-height: 20vh;
  display: grid;
  grid-auto-flow: row;
  overflow: auto;
  gap: 0.5rem;
`
export const ButtonJob = styled(Button)`
  background-color: #e6effb;
  font-weight: 500;
  border-radius: 1rem;
  font-size: 0.75rem;
  font-weight: 700;
  img {
    padding-right: 1rem;
  }
`
export const DivEdit = styled.div`
  height: 28vh;

  img {
    width: 2rem;
    cursor: pointer;
  }
`
export const TextDelete = styled.span`
  color: red;
`
