import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { JobSummary } from '@/components/Dashboard'
import { getJobsSummary } from '@/containers/Dashboard/JobSummary/thunk'

const JobSummaryContainer = () => {
  const dispatch = useDispatch()
  const { jobsSummary } = useSelector(
    (state) => state.dashboardReducer.jobSummaryReducer
  )

  useEffect(() => {
    dispatch(getJobsSummary())
  }, [dispatch])

  return <JobSummary summaryDetails={jobsSummary} />
}

export default JobSummaryContainer
