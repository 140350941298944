import styled from 'styled-components'
import { Form as AntForm, Avatar as AntdAva } from 'antd'

export const Form = styled(AntForm)`

  .ant-form-item-label > label {
    font-weight: 700;
  }
`

export const Avatar = styled(AntdAva)`
  display: block;
  margin: auto;
  background: #87d068;
`
