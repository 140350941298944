import styled from 'styled-components'

export const JobCardsSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  max-height: calc(100vh - 21rem);
  overflow-y: auto;
`

export const NoJobText = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #878981;

  .anticon {
    margin-right: 1rem;
  }
`
