import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ManagerList } from '@/components/Dashboard'
import { getManagers } from '@/containers/Dashboard/ManagerList/thunk'

const JobSummaryContainer = () => {
  const dispatch = useDispatch()
  const { managersList, isManagerListLoading } = useSelector(
    (state) => state.dashboardReducer.managerListReducer
  )

  useEffect(() => {
    dispatch(getManagers({ pageIndex: 0, pageSize: 5 }))
  }, [dispatch])

  return (
    <ManagerList managersList={managersList} isLoading={isManagerListLoading} />
  )
}

export default JobSummaryContainer
