import { useState, useEffect } from 'react'
import { getManagers } from '@/apis'

const useFetchManagers = () => {
  const [managers, setManagers] = useState([])

  useEffect(() => {
    getManagers().then((response) => {
      setManagers(response.data)
    })
  }, [])

  return managers
}

export default useFetchManagers
