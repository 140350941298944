import { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { COUNTRIES } from '@/utils/constants'
import TimeClockIcon from '@/assets/timeClock.svg'
import designDepartmentBg from '@/assets/designDepartment.png'

import { NewJobsContainer, JobCard, JobCardsRow, NewJobLabel } from './styles'

const NewJobSection = ({ jobs, numberDisplay, isLoading }) => {
  const jobsToDisplay = useMemo(() => {
    return jobs.slice(0, numberDisplay)
  }, [jobs, numberDisplay])

  return (
    <NewJobsContainer>
      <NewJobLabel>New Job Posted</NewJobLabel>

      <JobCardsRow>
        {jobsToDisplay?.map((job, index) => {
          const { title, country, updatedAt } = job || {}

          const flagIcon = COUNTRIES.find(
            (countryData) => countryData.value === country
          )?.icon

          let formattedTimeDifference
          if (updatedAt) {
            const daysDifference = moment().diff(moment(updatedAt), 'days')

            if (!daysDifference) {
              formattedTimeDifference = 'Updated today'
            } else if (daysDifference === 1) {
              formattedTimeDifference = `Updated 1 day ago`
            } else {
              formattedTimeDifference = `Updated ${daysDifference} days ago`
            }
          }

          return (
            <JobCard bgUrl={designDepartmentBg} key={index}>
              <h3 className="job-title">{title}</h3>

              <div className="job-info">
                <div className="job-info-item">
                  {flagIcon && (
                    <img className="icon" src={flagIcon} alt={country} />
                  )}

                  <span className="text-content">{country}</span>
                </div>

                {updatedAt && (
                  <div className="job-info-item">
                    <img
                      className="icon"
                      src={TimeClockIcon}
                      alt="lastUpdated"
                    />
                    <span className="text-content">
                      {formattedTimeDifference}
                    </span>
                  </div>
                )}
              </div>
            </JobCard>
          )
        })}
      </JobCardsRow>
    </NewJobsContainer>
  )
}

NewJobSection.propTypes = {
  jobs: PropTypes.array,
  numberDisplay: PropTypes.number,
  isLoading: PropTypes.bool
}

NewJobSection.defaultProps = {
  jobs: [],
  numberDisplay: 2,
  isLoading: false
}

export default NewJobSection
