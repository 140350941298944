import { useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'antd'

import PageLayout from '@/layouts/Default'
import {
  JobDetailsContainer,
  HeaderContainer,
  JobContentContainer
} from '@/containers/JobForm'
import { actions } from '@/redux/jobs/slice'
import { ContentWrapper } from './styles'

const JobForm = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  useLayoutEffect(() => {
    return () => {
      dispatch(actions.resetState())
    }
  }, [dispatch])

  return (
    <PageLayout>
      <HeaderContainer form={form} />

      <ContentWrapper>
        <JobContentContainer form={form} />
        <JobDetailsContainer form={form} />
      </ContentWrapper>
    </PageLayout>
  )
}

export default JobForm
