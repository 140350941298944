import axios from 'axios'
import Cookies from 'js-cookie'
import { handleExpiredToken } from './handleErrors'

window.requests = []

export const GET = async (url, params = {}) => {
  const userToken = Cookies.get('userToken')
  const headers = { Authorization: `Bearer ${userToken}` }

  const findOrCreate = (url, params) => {
    const item = window.requests.find((request) => {
      return request.url === url
    })

    if (!item) {
      const request = axios
        .get(url, {
          params,
          headers,
          validateStatus: function (status) {
            return status >= 200 && status < 300
          }
        })
        .finally(() => {
          const newRequests = window.requests.filter((request) => {
            return request.url !== url
          })
          window.requests = newRequests
        })
        .catch((error) => {
          handleExpiredToken(error)
        })

      window.requests.push({ url, request })
      return { url, request }
    }
    return item
  }

  try {
    const item = findOrCreate(url, params)
    return await item.request
  } catch (error) {
    throw new Error(error.message)
  }
}

export const PATCH = async (url, payload = {}) => {
  const userToken = Cookies.get('userToken')
  const headers = { Authorization: `Bearer ${userToken}` }

  const response = await axios.patch(url, payload, {
    headers,
    validateStatus: function (status) {
      return status >= 200 && status < 300
    }
  })

  return response
}

export const POST = async (url, formData) => {
  const userToken = Cookies.get('userToken')

  const headers = {
    Authorization: `Bearer ${userToken}`,
    'Content-Type': 'application/json'
  }

  const response = await axios.post(url, formData, {
    headers
  })

  return response
}

export const DELETE = async (url) => {
  const userToken = Cookies.get('userToken')
  const headers = { Authorization: `Bearer ${userToken}` }

  const response = await axios.delete(url, {
    headers
  })
  return response
}
