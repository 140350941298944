import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  getHiringPipelines,
  deleteHiringPipeline
} from '../../../apis/hiringPipelineDynamic/index'
import { message } from 'antd'

const useTableLogic = () => {
  const [params] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [tableData, setTableData] = useState([])

  const [deletedID, setDeletedID] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [messageModal, setMessageModal] = useState({
    title: '',
    intro: ''
  })

  useEffect(() => {
    setLoading(true)
    getHiringPipelines(params).then((data) => {
      const receivedData = data.data.data
      const mappedData = receivedData.map((pipeline) => {
        return { key: pipeline._id, ...pipeline }
      })
      setTotal(data.data.total)

      setTableData(mappedData)
      setLoading(false)
    })
  }, [params])

  const handleClickDelete = (key, name) => {
    setDeletedID(key)
    setMessageModal({
      title: `Delete "${name}"`,
      intro: 'Are you sure you want to delete this Hiring Pipeline?'
    })
    setOpenModal(true)
  }

  /**
   * Modal Function: handle when OK is clicked
   */
  const handleOk = () => {
    deleteHiringPipeline(deletedID)
      .finally(() => message.success('Hiring Pipeline deleted!'))
      .catch(() => message.error('Error when deleting Hiring Pipeline'))
    setOpenModal(false)
  }

  /**
   * Modal Function: handle when Cancel is clicked
   */
  const handleCancel = () => {
    setOpenModal(false)
  }

  return {
    loading,
    total,
    tableData,
    openModal,
    messageModal,
    handleClickDelete,
    handleOk,
    handleCancel
  }
}

export default useTableLogic
