import { useMemo } from 'react'
import { Link, createSearchParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import Country from '@/components/Texts/Country'
import TextRemoteWork from '@/components/Texts/RemoteWork'
import Metadata from '@/components/Metadata'
import { HeaderContainer } from './styles'
import { JOB_STATUSES } from '@/utils/constants'

const Header = ({ data, totalApplicants }) => {
  const url = useMemo(() => {
    const params = createSearchParams({
      jobTitle: data.title,
      jobCountry: data.country
    })

    return `/applicant-database?${params.toString()}`
  }, [data.title, data.country])

  return (
    <HeaderContainer>
      <Metadata
        backTo="/jobs"
        title={data.title}
        breadcrumbItems={[
          { link: '/dashboard', title: 'DASHBOARD' },
          { link: '/jobs', title: 'JOB POSTS' },
          { link: '#', title: 'VIEW JOB' }
        ]}
        infoItems={[
          <Country name={data.country} showCode />,
          <TextRemoteWork ableToWorkRemotely={data.ableToWorkRemotely} />,
          `Total Applicants: ${totalApplicants}`,
          <Link to={url}>
            <Button type="primary" size="small" shape="round">
              View Hiring Pipeline
            </Button>
          </Link>
        ]}
      />

      {data.status !== JOB_STATUSES.inactive.value && (
        <div className="right-section">
          <Button
            type="default"
            href={`${process.env.REACT_APP_LANDING_URL}/career/${data._id}`}
            target="_blank"
          >
            View in Landing page
          </Button>

          <Link to={`/job/edit/${data._id}`}>
            <Button icon={<EditOutlined />} type="primary">
              Edit Job
            </Button>
          </Link>
        </div>
      )}
    </HeaderContainer>
  )
}

Header.propTypes = {
  data: PropTypes.object,
  totalApplicants: PropTypes.number
}

Header.defaultProps = {
  data: {},
  totalApplicants: 0
}

export default Header
