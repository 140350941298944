import React from 'react'
import { Form } from 'antd'
import FormManager from '../Form'
import { Modal } from './styles'

const Element = ({ visible, title, data, handleClose, handleOk }) => {
  const [form] = Form.useForm()

  const handleSubmit = () => {
    const formData = form.getFieldValue()

    //fullName and email are required
    if (formData.email && formData.fullName) {
      if (data) {
        handleOk(formData, data._id)
      } else {
        handleOk(formData)
      }
      handleClose()
      form.resetFields()
    }

    form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    handleClose()
  }

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      getContainer={false}
    >
      <FormManager form={form} initialValues={data || {}} />
    </Modal>
  )
}

export default Element
