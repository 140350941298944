import React from 'react'
import Select from '@/components/UIKits/Select'
import useFetchDepartment from '@/hooks/FetchDepartments'

const { Option } = Select

const Element = ({ onChange, ...params }) => {
  const { departmentList } = useFetchDepartment()

  return (
    <Select showSearch={true} onChange={onChange} {...params}>
      {departmentList.map((dep, index) => (
        <Option key={index} value={dep.value}>
          {dep.label}
        </Option>
      ))}
    </Select>
  )
}

export default Element
