import { createSlice } from '@reduxjs/toolkit'

const jobCountersSlice = createSlice({
  name: 'jobCounters',
  initialState: {
    item: null,
    hashTable: []
  },
  reducers: {
    setItem: (state, action) => {
      return { ...state, item: action.payload }
    },
    setHashTable: (state, action) => {
      return { ...state, hashTable: action.payload }
    },
  },
})

export const { setItem, setHashTable } = jobCountersSlice.actions

export default jobCountersSlice.reducer
