import React, { useState, useEffect } from 'react'
import { getHiringPipelineByID } from '@/apis/hiringPipelineDynamic'
import PropTypes from 'prop-types'
import { Select } from './styles'

/**
 *
 * @param {String} value
 * @param {Array} hiringPipeline
 * @param {Function} onChange
 * @returns
 */
const Element = ({ value, pipelineID, onChange }) => {
  const [statusList, setStatusList] = useState([])

  useEffect(() => {
    getHiringPipelineByID(pipelineID).then((data) => {
      //map statuses from job.pipeline for display
      const mapStatuses = data.data.data.statuses.map((status) => {
        //return status code or just status name
        const returnCode = status.code ? status.code : status.name
        return { name: status.name, code: returnCode }
      })
      setStatusList(mapStatuses)
    })
  }, [pipelineID])

  return (
    <Select value={value} onChange={onChange}>
      {statusList.map((status) => (
        <Select.Option key={status.name} value={status.name}>
          {status.name}
        </Select.Option>
      ))}
    </Select>
  )
}

Element.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}

Element.defaultProps = {
  value: '',
  onChange: () => {}
}

export default Element
