import { createSlice } from '@reduxjs/toolkit'

import { createEvent, updateEvent, getEvent } from './thunk'

const initialState = {
  isUpdating: false,
  isUpdatingError: false,
  isUpdatingSuccess: false,
  event: {},
  isEventLoading: false,
  isEventError: false
}

const eventForm = createSlice({
  name: 'eventForm',
  initialState,
  extraReducers: {
    [createEvent.pending]: (state) => {
      state.isUpdating = true
      state.isUpdatingError = false
    },
    [createEvent.success]: (state) => {
      state.isUpdating = false
      state.isUpdatingError = false
      state.isUpdatingSuccess = true
    },
    [createEvent.rejected]: (state) => {
      state.isUpdating = false
      state.isUpdatingError = true
      state.isUpdatingSuccess = false
    },
    [updateEvent.pending]: (state) => {
      state.isUpdating = true
      state.isUpdatingError = false
    },
    [updateEvent.success]: (state) => {
      state.isUpdating = false
      state.isUpdatingError = false
      state.isUpdatingSuccess = true
    },
    [updateEvent.rejected]: (state) => {
      state.isUpdating = false
      state.isUpdatingError = true
      state.isUpdatingSuccess = false
    },
    [getEvent.pending]: (state) => {
      state.isEventError = false
      state.isEventLoading = true
    },
    [getEvent.fulfilled]: (state, action) => {
      state.isEventError = false
      state.isEventLoading = false
      state.event = action.payload
    },
    [getEvent.rejected]: (state) => {
      state.isEventError = true
      state.isEventLoading = false
    }
  }
})

const { reducer } = eventForm

export default reducer
