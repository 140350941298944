import { createSlice } from '@reduxjs/toolkit'

import { getEvents } from './thunk'

const initialState = {
  events: [],
  isEventsLoading: false,
  isEventsError: false
}

const eventsList = createSlice({
  name: 'eventsList',
  initialState,
  extraReducers: {
    [getEvents.pending]: (state) => {
      state.isEventsError = false
      state.isEventsLoading = true
    },
    [getEvents.fulfilled]: (state, action) => {
      state.isEventsError = false
      state.isEventsLoading = false
      state.events = action.payload
    },
    [getEvents.rejected]: (state) => {
      state.isEventsError = true
      state.isEventsLoading = false
    }
  }
})

const { reducer } = eventsList

export default reducer
