import styled from "styled-components"

export const Item = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-gap: 1rem;
  border-bottom: 1px solid #f0f2f5;
  border-radius: 0.25rem;
  padding: 1rem;

  &:hover {
    background-color: #fafafa;
  }
`

export const ItemMain = styled.main`
  color: #183b57;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height 2rem;
  border-radius: 50%;
  margin-top: 0.25rem;

  &.red {
    color: #183b57;
    background-color: #ffccc7;
  }

  &.yellow {
    color: #183b57;
    background-color: #ffe58f;
  }

  &.green {
    color: #183b57;
    background-color: #b7eb8f;
  }
`
