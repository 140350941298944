import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

/**
 * This check if user is loggedIn then navigate to dashboard
 * @returns navigate to dashboard page if user is logged in
 */
const PublicRoutes = () => {
  const { usertoken } = useSelector((state) => state.authenUserReducer)

  return !usertoken ? <Outlet /> : <Navigate to="/dashboard" />
}

export default PublicRoutes
