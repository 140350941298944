import React, { useMemo } from 'react'
import { ExpandAltOutlined } from '@ant-design/icons'
import { Button } from '../styles'

const Linkedin = ({ link }) => {
  const correctLink = useMemo(() => {
    if (link.includes('https://')) {
      return link
    }

    return `https://${link}`
  }, [link])

  return (
    <Button
      size="small"
      icon={<ExpandAltOutlined />}
      href={correctLink}
      target="_blank"
    >
      LinkedIn
    </Button>
  )
}

export default Linkedin
