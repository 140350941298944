import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import PropTypes from 'prop-types'

const RichTextEditor = ({ onChange, value, disabled }) => {
  return (
    <Editor
      disabled={disabled}
      value={value}
      apiKey="ohp164egl3ies3dcp318ri57w4tavvcxe7n22rg66e5f3p9i"
      toolbar={[
        'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | fontselect fontsizeselect | ltr rtl| outdent indent numlist bullist| forecolor backcolor removeformat| image media link table | pagebreak | charmap emoticons archor | print '
      ]}
      onEditorChange={onChange}
      style={{ height: '100px' }}
    />
  )
}

RichTextEditor.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool
}

RichTextEditor.defaultProps = {
  onChange: () => {},
  value: '',
  disabled: false
}

export default RichTextEditor
