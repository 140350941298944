import styled from 'styled-components'
import { Tag as AntdTag } from 'antd'

export const LogsContainer = styled.div``

export const Title = styled.h3`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25;
  color: #808080;
  text-align: center;
  margin-bottom: 1.5rem;
`

export const Section = styled.div`
  background: #ffffff;
  border: 1px solid #eceef2;
  border-radius: 8px;
  margin-bottom: 1.5rem;
`

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;

  &:not(:last-child) {
    border-bottom: 1px solid #f5f5f5;
  }
`

export const Tag = styled(AntdTag)`
  border-radius: 4px;
  margin-right: 2rem;
`

export const ActionText = styled.span`
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: capitalize;
  color: ${(props) => props.color};
`

export const SecondaryText = styled.span`
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #757d9b;
  margin-right: 1rem;
`

export const Description = styled.span`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #210d4a;
`

export const OldValue = styled.span`
  padding: 0.25rem 0.5rem;
  background: rgba(255, 65, 65, 0.12);
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-decoration: line-through;
  color: #808080;
  margin: 0 0.5rem;
`

export const NewValue = styled.span`
  padding: 0.25rem 0.5rem;
  background: rgba(77, 193, 133, 0.12);
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #2d3344;
  margin: 0 0.5rem;
`
