import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { JobPostHeader } from '@/components/Jobs'
import { getJobs } from '@/redux/jobs/thunk'

const JobPostHeaderContainer = () => {
  const dispatch = useDispatch()
  const { jobs, isJobsLoading } = useSelector((state) => state.jobsReducer)
  const [params] = useSearchParams()

  useEffect(() => {
    const currentParams = Object.fromEntries(params)
    const payload = {
      ...(currentParams.key && {
        title: currentParams.key
      }),
      ...(currentParams.category && {
        categories: currentParams.category.split(',')
      }),
      ...(currentParams.status && {
        status: currentParams.status.split(',')
      })
    }

    dispatch(getJobs(payload))
  }, [dispatch, params])

  return <JobPostHeader jobPosts={jobs} isLoading={isJobsLoading} />
}

export default JobPostHeaderContainer
