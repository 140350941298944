import styled from 'styled-components'

export const Container = styled.main`
  display: grid;
  grid-template-columns: 15rem 1fr;
`

export const Content = styled.main`
  min-height: calc(100vh - 4.125rem);
  background-color: #f6f8fa;
  padding: 2rem;
`
