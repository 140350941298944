import React from 'react'
import Select from '@/components/UIKits/Select'
import useFetchManagers from '@/hooks/Managers'

const SelectManagers = ({ ...props }) => {
  const managers = useFetchManagers() || []

  const filterOption = (input = '', option) => {
    const lowerCaseSearch = input.toLowerCase()
    const lowerCaseLabel = option.children.toLowerCase()

    return lowerCaseLabel.includes(lowerCaseSearch)
  }

  return (
    <Select filterOption={filterOption} {...props}>
      {managers.map((manager) => (
        <Select.Option key={manager._id} value={manager._id}>
          {manager.fullName || manager.username}
        </Select.Option>
      ))}
    </Select>
  )
}

export default SelectManagers
