import React from "react"
import { Dropdown, Spin } from "antd"
import { HistoryOutlined } from "@ant-design/icons"
import ItemUserLog from "./ItemUserLog"
import useUserLogs from "@/hooks/useUserLogs"
import { Button, DropdownContainer, NoDataFound, Loading } from "./styles"

const DropdownUserLogs = () => {
  const { isLoading, dataSource, refetch } = useUserLogs()

  return (
    <Dropdown
      trigger={["click"]}
      placement="bottomRight"
      overlay={
        <DropdownContainer>
          {isLoading && (
            <Spin>
              <Loading />
            </Spin>
          )}

          {!isLoading && dataSource.length === 0 && (
            <NoDataFound>There are no data</NoDataFound>
          )}

          {!isLoading &&
            dataSource.map((item) => (
              <ItemUserLog key={item._id} data={item} />
            ))}
        </DropdownContainer>
      }
      onVisibleChange={(open) => {
        if (open) {
          refetch()
        }
      }}
    >
      <Button
        type="default"
        icon={<HistoryOutlined />}
        shape="round"
        onClick={(e) => e.preventDefault()}
      >
        Activities
      </Button>
    </Dropdown>
  )
}

export default DropdownUserLogs
