import styled from 'styled-components'
import { Select as AntSelect } from 'antd'

export const Select = styled(AntSelect)`
  width: 100%;
  color: #ffffff;

  &:not(.ant-select-disabled):not(.ant-select-customize-input) {
    .ant-select-selection-placeholder {
      color: #ffffff;
    }

    .ant-select-selector {
      background-color: #377e98;
      border-color: #377e98;
      border-radius: 0.5rem;
    }
  }
`
