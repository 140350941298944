import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.02);
  border-radius: 0.5rem;
  padding: 1.5rem;
  width: 100%;

  strong {
    display: block;
    color: #272d4e;
    font-weight: 700;
    font-size: 1.2rem;
    margin: 1rem 0;
  }
`

export const TextValue = styled.div`
  color: #252525;
  opacity: 0.7;
`

export const EmptyOrLoading = styled.div`
  width: 100%;
  text-align: center;
`

export const ButtonsSection = styled.div`
  display: flex;
  justify-content: flex-end;
`
